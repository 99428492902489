/*
 * @Author: Steven.Ye steven.ye@zoom.us
 * @Date: 2024-10-25 16:04:10
 * @LastEditors: Steven.Ye steven.ye@zoom.us
 * @LastEditTime: 2024-11-19 00:39:39
 * @FilePath: pwa-media/wcl/SDK/src/inside/audio/RTCAudioPlayer.js
 * @Description: WebRTC audio player wrapper
 */
import Zoom_Monitor from '../Monitor';
import globalTracingLogger from '../../common/globalTracingLogger';
const audioBridgeMonitor = (log, e) => Zoom_Monitor.add_monitor('AB' + log);
export default class RTCAudioPlayer {
  constructor(audioId, stream, speakerId, pauseCb) {
    this.audioId = audioId; // 'ab-audio-' + streamId;
    // !! use audio tag as a workaround to prevent playing choppy issue: https://zoomvideo.atlassian.net/browse/ZOOM-558585
    this.audioTag = document.createElement('audio');
    this.audioTag.id = audioId;
    this.audioTag.srcObject = stream;
    this.audioTag.autoplay = true;
    this.audioTag.controls = false;
    this.pauseCB = pauseCb;

    this._listenHandlePlaying = this._listenHandlePlaying.bind(this);
    this._listenHandlePause = this._listenHandlePause.bind(this);
    this._listenHandleCanPlay = this._listenHandleCanPlay.bind(this);
    this._listenHandleError = this._listenHandleError.bind(this);
    this._addListener();
    document.documentElement.appendChild(this.audioTag);
    this.setSpeakerDevicePromise(speakerId).catch((e) => {});
  }

  destroy() {
    this._removeListener();
    this.pause();
    this.setStream(null);
    if (this.audioTag) {
      this.audioTag.remove();
      this.audioTag = null;
    }
  }

  _addListener() {
    if (!this.audioTag) return;

    this.audioTag.addEventListener('playing', this._listenHandlePlaying);
    this.audioTag.addEventListener('pause', this._listenHandlePause);
    this.audioTag.addEventListener('canplay', this._listenHandleCanPlay);
    this.audioTag.addEventListener('error', this._listenHandleError);
  }

  _removeListener() {
    if (!this.audioTag) return;

    this.audioTag.removeEventListener('playing', this._listenHandlePlaying);
    this.audioTag.removeEventListener('pause', this._listenHandlePause);
    this.audioTag.removeEventListener('canplay', this._listenHandleCanPlay);
    this.audioTag.removeEventListener('error', this._listenHandleError);
  }

  _listenHandlePlaying() {
    audioBridgeMonitor('ASP:' + this.audioId);
  }

  _listenHandlePause() {
    audioBridgeMonitor('APP:' + this.audioId);
    if (typeof this.pauseCB == 'function') {
      this.pauseCB.call();
    }
  }

  _listenHandleCanPlay() {
    audioBridgeMonitor('ACP:' + this.audioId);
  }

  _listenHandleError(error) {
    audioBridgeMonitor('APE:' + this.audioId + '-' + error?.code);
    globalTracingLogger.error('RTCAudioPlayer tag error: ', error);
  }

  isPaused() {
    return this.audioTag ? this.audioTag.paused : undefined;
  }

  isMuted() {
    return this.audioTag ? this.audioTag.muted : undefined;
  }

  isSupportSetSpeakerDevice() {
    return this.audioTag ? this.audioTag.setSinkId : undefined;
  }

  setStream(stream) {
    if (this.audioTag && this.audioTag.srcObject != stream)
      this.audioTag.srcObject = stream;
  }

  play() {
    return this.audioTag && this.audioTag.play();
  }

  pause() {
    return this.audioTag && this.audioTag.pause();
  }

  mute() {
    if (this.audioTag) this.audioTag.muted = true;
  }

  unmute() {
    if (this.audioTag) this.audioTag.muted = false;
  }

  setVolume(volume) {
    const level = Math.min(Math.max(0, volume), 100) / 100;
    if (this.audioTag) this.audioTag.volume = level;
  }

  setSpeakerDevicePromise(deviceId) {
    return new Promise((resolve, reject) => {
      // check
      if (!this.audioTag) return resolve();
      if (!this.isSupportSetSpeakerDevice()) return resolve();
      if (!deviceId) return resolve();

      // same id return
      if (this.audioTag.sinkId == deviceId) return resolve();

      this.audioTag
        .setSinkId(deviceId)
        .then(resolve)
        .catch((e) => {
          globalTracingLogger.error(
            'Error when setting sink of audio player',
            e
          );
          reject(e);
        });
    });
  }
}
