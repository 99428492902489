import * as SdkEvent from './sdkEvent';

export function StartWorkerHealthCheck(target) {
  if (!WorkerHealthCheck.instance) {
    WorkerHealthCheck.instance = new WorkerHealthCheck();
  }
  WorkerHealthCheck.instance.start(target);
}

export function StopWorkerHealthCheck() {
  WorkerHealthCheck.instance?.close();
}

export function SetHealthTimeoutCallback(fn) {
  if (!WorkerHealthCheck.instance) {
    return;
  }
  WorkerHealthCheck.instance.setTimeoutCallback(fn);
}

export function HealthCheckRegisterWorker(worker, id) {
  if (!WorkerHealthCheck.instance) {
    return;
  }
  WorkerHealthCheck.instance.registerWorker(id, worker);
}

export function HealthCheckUnRegisterWorker(id) {
  if (!WorkerHealthCheck.instance) {
    return;
  }
  WorkerHealthCheck.instance.unRegisterWorker(id);
}

export class WorkerHealthCheck {
  static INTREVAL_TIME_MS = 3 * 1000;
  static HEART_TIMEOUT_MS = 15 * 1000;
  static MAX_HEART_TIMEOUT_MS = 30 * 1000;
  static instance = null;

  constructor() {
    this._interval = -1;
    // id ==>{worker,lastedtimestamp}
    this.monitorworkers = {};
    this._lasted_timestamp = -1;
    //
    this.timeoutcallbackfn = (id, timeout) => {};
  }
  setTimeoutCallback(fn) {
    this.timeoutcallbackfn = fn;
  }

  registerWorker(id, worker) {
    if (id in this.monitorworkers) {
      let monitor = this.monitorworkers[id];
      monitor.worker.removeEventListener('message', monitor.listener);
      delete this.monitorworkers[id];
    }

    let monitor = { id: id, worker: worker };
    let listener = this._recvheartbeat.bind(this, monitor);
    monitor.listener = listener;
    monitor.lastedtimestamp = Date.now();
    monitor.worker.addEventListener('message', monitor.listener);
    this.monitorworkers[id] = monitor;
  }

  unRegisterWorker(id) {
    if (!(id in this.monitorworkers)) {
      return;
    }
    let monitor = this.monitorworkers[id];
    delete this.monitorworkers[id];
    monitor.worker.removeEventListener('message', monitor.listener);
  }

  _recvheartbeat(monitor, ev) {
    let msg = ev.data;
    if (msg.cmd === SdkEvent.WORKER_HEARTBEAT) {
      monitor.lastedtimestamp = msg.timestamp;
    }
  }
  start(target) {
    const IsWorker =
      typeof DedicatedWorkerGlobalScope != 'undefined' &&
      target &&
      target instanceof DedicatedWorkerGlobalScope;

    if (this._interval != -1) return;

    if (IsWorker) {
      this._interval = setInterval(() => {
        target.postMessage({
          cmd: SdkEvent.WORKER_HEARTBEAT,
          timestamp: Date.now(),
        });
      }, WorkerHealthCheck.INTREVAL_TIME_MS);
      return;
    }

    const MIN_CHECKED_PERIOD = Math.max(
      WorkerHealthCheck.INTREVAL_TIME_MS - 1000,
      500
    );
    this._lasted_timestamp = Date.now();
    this._interval = setInterval(() => {
      let instance = WorkerHealthCheck.instance;
      let workers = Object.keys(instance.monitorworkers);
      let now = Date.now();
      let pre = this._lasted_timestamp;
      if (now < pre + MIN_CHECKED_PERIOD) {
        return;
      }
      this._lasted_timestamp = now;
      // ui thread blocked long time
      if (now > pre + WorkerHealthCheck.HEART_TIMEOUT_MS) {
        instance.timeoutcallbackfn('MAIN', now - pre);
        return;
      }

      workers.forEach((elm) => {
        let m = instance.monitorworkers[elm];
        let next_timeout =
          m.lastedtimestamp +
          (document?.hidden
            ? WorkerHealthCheck.MAX_HEART_TIMEOUT_MS
            : WorkerHealthCheck.HEART_TIMEOUT_MS);

        if (now > next_timeout) {
          instance.timeoutcallbackfn(m.id, now - m.lastedtimestamp);
          ///force update lastedtimestamp  to avoid  trigger frequent
          m.lastedtimestamp = now;
        }
      });
    }, WorkerHealthCheck.INTREVAL_TIME_MS);
  }
  close() {
    try {
      let temp = Object.keys(this.monitorworkers);
      temp.forEach((elm) => {
        let monitor = this.monitorworkers[elm];
        delete this.monitorworkers[elm];
        monitor.worker.removeEventListener('message', monitor.listener);
      });
      if (this._interval) clearInterval(this._interval);
      this._interval = -1;
    } catch (e) {}
  }
}
