/**
 * you can add your custom exception/error in this file.
 */

import globalTracingLogger from './globalTracingLogger';

/**
 * the computer camera is occupied, then no mediaStream obtained
 * @param message
 * @constructor
 */
export function CameraOccupiedError(message) {
  this.name = 'CameraOccupiedError';
  this.message = message;
  this.stack = new Error().stack;
}

CameraOccupiedError.prototype = new Error();

export const CAPTURE_ERROR_TYPE = {
  EXCEPTION: -1,
  PERMISSION_RESET: -2,
  LOST_ACCESS: -3,
};

const DEDUPLICATE_TIME_WINDOW = 5000;

const errMessageCache = new Map();

function shouldSendMessages(event) {
  const timeStamp = performance.now();

  if (
    !errMessageCache.has(event) ||
    timeStamp - errMessageCache.get(event) > DEDUPLICATE_TIME_WINDOW
  ) {
    errMessageCache.set(event, timeStamp);
    return true;
  }

  return false;
}

function replaceComma(str, optional = '|') {
  if (!str) return '';
  return str.toString().replaceAll(/[,，]/g, optional);
}

let globalNotifyUIFn = null;
let globalMonitorFn = null;

export function SetNotifyUIFn(notifyUIFn) {
  globalNotifyUIFn = notifyUIFn;
}

export function SetMonitorFn(monitorFn) {
  globalMonitorFn = monitorFn;
}

/*
 * Note: NotifyUIError should only be called on main thread;
 *       on worker, use postMessage
 */
export function NotifyUIError(event, data) {
  if (!shouldSendMessages(event)) return;
  let dataStr;
  try {
    if (typeof data === 'object') {
      dataStr = replaceComma(JSON.stringify(data));
    } else {
      dataStr = replaceComma(data);
    }
  }
  catch (err) {
    dataStr = replaceComma(data);
  }
  globalMonitorFn?.(`NEM-${event}-${dataStr}`);
  globalTracingLogger.error(`NotifyUIError,event=${event},data=${dataStr}`);
  globalNotifyUIFn?.(event, data);
}
