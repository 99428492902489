export class LocalMessageChannel {
  constructor(subworker) {
    this.subworker = subworker;
    this.onmessage = (ev) => {};
    this._listeners = this._listenersfn.bind(this);
    this.subworker.addEventListener('message', this._listeners);
  }

  postMessage(...args) {
    this.subworker.postMessage(...args);
  }

  _listenersfn(ev) {
    this.onmessage(ev);
  }

  close() {
    this.subworker.removeEventListener('message', this._listeners);
  }
}

export class DumyPort {
  constructor() {
    this.onmessage = () => {};
  }
  addEventListener() {}
  close() {}
}

export class MediaDataChannel {
  static NO_THREAD = 0;
  static SELF_THREAD = 1;
  constructor(mgr) {
    // transportId => {transport}
    this.transportMap = {};
    // network
    this.netthreadworker = null;
    this.type = mgr.type;
    this.mgr = mgr;
    this.transportlistsChnagelinster = [];
  }

  addEventListener(fn) {
    if (this.transportlistsChnagelinster.indexOf(fn) == -1) {
      this.transportlistsChnagelinster.push(fn);
    }
  }
  removeEventListener(fn) {
    let idx = this.transportlistsChnagelinster.indexOf(fn);
    if (idx != -1) {
      this.transportlistsChnagelinster.splice(idx, 1);
    }
  }
  addTransport(transport, channel) {
    if (transport.id in this.transportMap) {
      return;
    }

    this.transportMap[transport.id] = transport;
    if (process.env.NODE_ENV === 'development') {
      let length = Object.keys(this.transportMap).length;
      console.error(
        `mediadatachannel addTransport id=${transport.id},size=${length}`
      );
    }

    this.transportlistsChnagelinster.forEach((func) => {
      func(transport, channel, 1);
    });
  }

  removeTransport(transport) {
    let id = transport.id;
    if (process.env.NODE_ENV === 'development') {
      console.error(`mediadatachannel removeTransport id=${id}`);
    }
    if (!(id in this.transportMap)) {
      return;
    }
    delete this.transportMap[id];
    if (process.env.NODE_ENV === 'development') {
      let length = Object.keys(this.transportMap).length;
      console.error(
        `mediadatachannel removeTransport id=${id} size = ${length}`
      );
    }
    transport.sock?.close();
    this.transportlistsChnagelinster.forEach((func) => {
      func(transport, transport.channel, 0);
    });
  }

  getTransportByType(type) {
    for (let key in this.transportMap) {
      let transport = this.transportMap[key];
      let isused = transport.target_thread == MediaDataChannel.SELF_THREAD;
      if (transport.type == type && isused) {
        return transport;
      }
    }
    return null;
  }
}
