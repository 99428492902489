import globalTracingLogger from './globalTracingLogger';
import { WEBRTC_STG, WEBRTC_STG_ERRNO } from '../common/renderer/RenderConst';

const MediaConfigParser = {
  /**
   * Check whether the target device hits the WebCodec whitelist.
   *
   * @param {*} targetVendor vendor name, like intel, apple, arm, etc
   * @param {*} targetRenderInfo the renderer info, maybe from WebGL render info
   * @param {*} codecType a string value, it should be 'encoder' or 'decoder' or 'all'
   * @param {*} webCodecWhitelist the WebCodec whitelist from op
   * @returns if true, the target device in on the WebCodec whitelist, if false, on the blacklist or not support
   */
  isOnWebCodecWhitelist(
    targetVendor,
    targetRenderInfo,
    codecType,
    webCodecWhitelist
  ) {
    if (
      targetVendor === '' ||
      targetVendor === undefined ||
      targetRenderInfo === undefined ||
      targetRenderInfo === ''
    ) {
      return false;
    }

    // if webCodecBlacklist is not set, we should follow the previous logic
    if (!webCodecWhitelist) {
      const isArm = targetVendor.includes('arm');
      const isIntel = targetRenderInfo.includes('intel');
      const isAMD = targetRenderInfo.includes('amd');
      const isNvidia = targetRenderInfo.includes('nvidia');
      if (codecType === 'encoder') {
        if (isArm) {
          return false;
        } else if (isIntel || isAMD || isNvidia) {
          return true;
        } else {
          return false;
        }
      } else if (codecType === 'decoder') {
        if (isArm) {
          return false;
        } else if (isAMD) {
          return true;
        } else if (isIntel) {
          return !this.isInRangeOfGenerations(
            'intel',
            targetRenderInfo,
            1000,
            4000
          );
        } else if (isNvidia) {
          return !this.isLowerThanMinGeneration(
            'nvidia',
            targetRenderInfo,
            '600'
          );
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    targetRenderInfo = this.replaceSpacesWithUnderscores(targetRenderInfo);

    for (let i = 0; i < webCodecWhitelist.length; i++) {
      const entry = webCodecWhitelist[i];

      const hasVendorField = 'vendor' in entry;
      const hasModelField = 'model' in entry;
      const hasRenderInfoField = 'renderInfo' in entry;
      const hasBlacklistField = 'blacklist' in entry;

      let entryVendor = '';
      if (hasVendorField) {
        if (entry.vendor !== '') {
          entryVendor = entry.vendor.toLowerCase();
        }
      }

      let entryModel = '';
      if (hasModelField) {
        if (entry.model !== '') {
          entryModel = this.replaceSpacesWithUnderscores(
            entry.model.toLowerCase()
          );
        }
      }

      let entryRenderInfo = '';
      if (hasRenderInfoField) {
        if (entry.renderInfo !== '') {
          entryRenderInfo = this.replaceSpacesWithUnderscores(
            entry.renderInfo.toLowerCase()
          );
        }
      }

      let entryBlacklist = null;
      if (hasBlacklistField) {
        if (entry.blacklist.length > 0) {
          entryBlacklist = entry.blacklist;
        }
      }

      if (hasVendorField) {
        if (entryVendor !== '' && targetVendor.includes(entryVendor)) {
          if (entryModel === '' && entryRenderInfo === '' && !entryBlacklist) {
            // all GPUs of this vendor are allowed
            return true;
          }

          if (
            targetRenderInfo === entryRenderInfo ||
            (entryRenderInfo !== '' &&
              targetRenderInfo.includes(entryRenderInfo))
          ) {
            // if entry renderInfo equals to the target renderInfo totally
            // it means a specific GPU is allowed only
            // blacklist will be ignored under this case
            return true;
          }

          if (entryModel !== '' && targetRenderInfo.includes(entryModel)) {
            // if the target renderInfo includes the model, like intel uhd
            // it means a series of GPUs of this vendor are allowed
            // but here we need to check the blacklist
            if (entryBlacklist) {
              const isHitBlacklist = this.isHitBlacklist(
                targetVendor,
                targetRenderInfo,
                codecType,
                entryModel,
                entryBlacklist
              );
              return !isHitBlacklist;
            } else {
              // if no blacklist set and target renderInfo contains the model
              // it means a series of GPUs are allowed
              return true;
            }
          } else {
            // if the target renderInfo doesn't include the model or the model is not set
            // next, we need to check the blacklist
            // here are two cases if renderInfo doesn't include the model
            if (entryModel !== '') {
              // if entryModel is not empty, no need to check the blacklist because parent model must be included in the target renderInfo
              return false;
            } else {
              if (entryBlacklist) {
                const isHitBlacklist = this.isHitBlacklist(
                  targetVendor,
                  targetRenderInfo,
                  codecType,
                  entryModel,
                  entryBlacklist
                );
                return !isHitBlacklist;
              } else {
                return true;
              }
            }
          }
        }
      } else {
        // if no vendor field in entry, it means the entry is invalid
        globalTracingLogger.error(
          `isOnWebCodecWhitelist() no vendor field in the json entry! entry:${entry}`
        );
      }
    }

    return false;
  },

  isGPUProfileOnWebCodecWhitelist(codecType, webCodecConfig, gpuInfo) {
    if (!this.isOffscreenCanvasSupported()) {
      globalTracingLogger.log(
        `isGPUProfileOnWebCodecWhitelist() OffscreenCanvas is not supported.`
      );
      return false;
    }

    try {
      const vendor = gpuInfo.vendor;
      const renderInfo = gpuInfo.renderInfo;

      const isOnWhitelist = this.isOnWebCodecWhitelist(
        vendor,
        renderInfo,
        codecType,
        webCodecConfig
      );

      globalTracingLogger.directReport(
        `isGPUProfileOnWebCodecWhitelist() isOnWhitelist:${isOnWhitelist}, vendor:${vendor}, renderInfo:${renderInfo}, codecType:${codecType}, config:${JSON.stringify(
          webCodecConfig
        )}`
      );
      return isOnWhitelist;
    } catch (e) {
      return false;
    }
  },

  /**
   * Evaluate the strategy of using WebRTC solution or not.
   *
   * @param {*} webrtcBlacklist a blacklist configuration to control to use WebRTC solution
   * @param {*} deviceInfo essential device information to match
   * @param {*} isWebRTCSupportedByBrowser whether WebRTC solution is supported by browser, if supported, true, false otherwise
   * @param {*} webrtcSelection a number indicates how caller wants to select WebRTC solution
   * @returns a strategy of using WebRTC solution or not
   */
  evalWebRTCStrategy(
    webrtcBlacklist,
    deviceInfo,
    isWebRTCSupportedByBrowser,
    webrtcSelection
  ) {
    let _webrtcStrategy = {
      stg: WEBRTC_STG.DISABLED,
      errNo: WEBRTC_STG_ERRNO.UNKNOWN,
      errMsg: '',
    };

    // WebRTC solution is not supported
    if (!isWebRTCSupportedByBrowser) {
      _webrtcStrategy.stg = WEBRTC_STG.DISABLED;
      _webrtcStrategy.errNo = WEBRTC_STG_ERRNO.BROWSER_NOT_SPT;
      _webrtcStrategy.errMsg = 'webrtc is disabled(not supported by browser).';
      return _webrtcStrategy;
    }

    // caller wants to disable WebRTC solution
    if (webrtcSelection === WEBRTC_STG.DISABLED) {
      _webrtcStrategy.stg = WEBRTC_STG.DISABLED;
      _webrtcStrategy.errNo = WEBRTC_STG_ERRNO.SUCCEED;
      return _webrtcStrategy;
    }

    // no blacklist configured, using WebRTC solution or not depends on the caller selection
    // if caller wants to use it, return USE
    // if caller doesn't care about it, return PREFER
    if (!webrtcBlacklist || webrtcBlacklist.length == 0) {
      _webrtcStrategy.stg = webrtcSelection; // caller's selection
      _webrtcStrategy.errNo = WEBRTC_STG_ERRNO.SUCCEED;
      return _webrtcStrategy;
    }

    // all fields in deviceInfo to lower case
    const _deviceInfo = {
      os: deviceInfo.os?.toLowerCase(),
      browserName: deviceInfo.browserName?.toLowerCase(),
      browserVersion: deviceInfo.browserVersion?.toLowerCase(),
      vendor: deviceInfo.vendor?.toLowerCase(),
      renderInfo: deviceInfo.renderInfo?.toLowerCase(),
      isAstcSupported: deviceInfo.isAstcSupported,
    };

    // define bit masks for all checked fields
    const OS_BIT = 0x1000;
    const BROWSER_BIT = 0x0100;
    const VENDOR_BIT = 0x0010;
    const RENDER_INFO_BIT = 0x0001;

    for (const entry of webrtcBlacklist) {
      let bit = 0x0000;

      // 1. check os first
      let isMacOS = false;
      if (entry.os && entry.os !== '') {
        if (_deviceInfo.os.includes(entry.os.toLowerCase())) {
          // macOS has two kinds of chip: intel-based and arm-based
          // so we need to check the os is mac or not for special cases
          if (_deviceInfo.os.includes('mac')) {
            isMacOS = true;
          }
          bit |= OS_BIT;
        } else {
          // if os is set but not matched, skip this round directly
          continue;
        }
      }

      // 2. check the browser
      let isSafari = false;
      if (entry.browser && entry.browser.name && entry.browser.name !== '') {
        // if browser name matched, browser version is compared next
        if (
          _deviceInfo.browserName.includes(entry.browser.name.toLowerCase())
        ) {
          if (_deviceInfo.browserName.includes('safari')) {
            isSafari = true;
          }

          if (entry.browser.versions && entry.browser.versions.length > 0) {
            // if browser version is set, start to compare version with current one
            const isBrowserVersionHitBlacklist = entry.browser.versions.some(
              (blacklistVersion) =>
                this.isBrowserVersionHitBlacklist(
                  deviceInfo.browserVersion,
                  blacklistVersion
                )
            );
            if (isBrowserVersionHitBlacklist) {
              bit |= BROWSER_BIT;
            } else {
              // if a list of browser version is set but none of them is matched,
              // the target browser will be allowed, so skip this blacklist entry
              continue;
            }
          } else {
            // if field is not set or set with an empty string, but browser name is matched,
            // mark it as matched the blacklist
            bit |= BROWSER_BIT;
          }
        } else {
          // if browser is set but not matched, skip this round directly
          continue;
        }
      }

      // 3. check GPU vendor
      if (entry.vendor && entry.vendor !== '') {
        if (_deviceInfo.vendor.includes(entry.vendor.toLowerCase())) {
          bit |= VENDOR_BIT;
        } else {
          if (
            isMacOS &&
            isSafari &&
            entry.vendor.toLowerCase() === 'intel' &&
            !_deviceInfo.isAstcSupported
          ) {
            bit |= VENDOR_BIT;
          } else {
            continue;
          }
        }
      }

      // 4. check renderInfo
      // here are 3 results:
      // 1. if renderInfo is set but not matched, skip this round and regard as a failure
      // 2. if renderInfo is set and matched, hit the blacklist and set the bit
      // 3. if renderInfo is not set, ignore it and check other fields
      if (entry.renderInfo && entry.renderInfo !== '') {
        if (_deviceInfo.renderInfo === entry.renderInfo.toLowerCase()) {
          bit |= RENDER_INFO_BIT;
        } else {
          continue;
        }
      }

      // if any fields are matched, it means the device is on the blacklist
      // so here should return block
      // if not, start next round
      if (bit > 0) {
        _webrtcStrategy.stg = WEBRTC_STG.DISABLED;
        _webrtcStrategy.errNo = WEBRTC_STG_ERRNO.DEVICE_ON_BLACKLIST;
        _webrtcStrategy.errMsg = 'webrtc is disabled(hit blacklist).';
        globalTracingLogger.log(
          `evalWebRTCStrategy() stg:${JSON.stringify(_webrtcStrategy)}`
        );
        return _webrtcStrategy;
      }
    }

    // if the device info can't hit any item in the blacklist
    // return PREFER to make a further decision: prefer to use WebRTC or Wasm
    _webrtcStrategy.stg = webrtcSelection; // caller's selection
    _webrtcStrategy.errNo = WEBRTC_STG_ERRNO.SUCCEED;
    return _webrtcStrategy;
  },

  /**
   * Checks if the given device is on the WebRTC whitelist.
   *
   * The function takes a `deviceInfo` object, normalizes its `os` and `browserName` fields to lowercase,
   * and checks if the combination of browser and operating system exists in a predefined whitelist.
   * The whitelist contains specific browser names and allowed operating systems.
   * It performs a partial match on the OS, allowing flexible matching (e.g., 'windows' can match 'win').
   *
   * @param {Object} deviceInfo - The device information containing the OS and browser name.
   * @param {string} deviceInfo.os - The operating system of the device (e.g., 'Windows', 'Mac').
   * @param {string} deviceInfo.browserName - The browser name (e.g., 'Chrome', 'Safari').
   *
   * @returns {boolean} - Returns `true` if the device's browser and OS are on the whitelist, otherwise `false`.
   */
  isOnWebRTCWhitelist(deviceInfo) {
    if (!deviceInfo) {
      return false;
    }

    globalTracingLogger.directReport(
      `isOnWebRTCWhitelist() deviceInfo=${JSON.stringify(deviceInfo)}`
    );

    // all fields in deviceInfo to lower case
    const _deviceInfo = {
      os: deviceInfo.os?.toLowerCase(),
      browserName: deviceInfo.browserName?.toLowerCase(),
    };

    const WHITELIST = [
      {
        browserName: 'chrome',
        os: ['win', 'mac', 'ios', 'android', 'chromium os'],
      },
      {
        browserName: 'edge',
        os: ['win'],
      },
      {
        browserName: 'safari',
        os: ['mac', 'ios'],
      },
      {
        browserName: 'mobile safari',
        os: ['ios'],
      },
    ];

    return WHITELIST.some(
      (item) =>
        item.browserName === _deviceInfo.browserName &&
        item.os.some((os) => _deviceInfo.os.includes(os))
    );
  },

  isLowerThanMinGeneration(vendor, renderInfo, minGeneration) {
    try {
      const _vendor = vendor.toLowerCase();
      const _renderInfo = renderInfo.toLowerCase();
      const _minGeneration = parseInt(minGeneration);

      if (_vendor === 'nvidia') {
        if (_renderInfo.includes('geforce')) {
          const index = _renderInfo.indexOf('geforce gt ');
          const gen = parseInt(_renderInfo.slice(index + 11));
          if (gen < _minGeneration) {
            return true;
          }
        }
      } else if (_vendor === 'intel') {
        if (_renderInfo.includes('hd graphics')) {
          const index = _renderInfo.indexOf('hd graphics ');
          const gen = parseInt(_renderInfo.slice(index + 12));
          if (gen < _minGeneration) {
            return true;
          }
        }
      }

      return false;
    } catch (e) {
      globalTracingLogger.error(e);
      return false;
    }
  },

  isInRangeOfGenerations(vendor, renderInfo, leftGeneration, rightGeneration) {
    try {
      const _vendor = vendor.toLowerCase();
      const _renderInfo = renderInfo.toLowerCase();
      if (_vendor === 'intel') {
        if (_renderInfo.includes('hd graphics')) {
          const index = _renderInfo.indexOf('hd graphics ');
          const gen = parseInt(_renderInfo.slice(index + 12));
          if (gen > leftGeneration && gen < rightGeneration) {
            return true;
          }
        }
      }
      return false;
    } catch (e) {
      globalTracingLogger.error(e);
      return false;
    }
  },

  isOffscreenCanvasSupported() {
    return typeof OffscreenCanvas === 'function';
  },

  isHitBlacklist(
    targetVendor,
    targetRenderInfo,
    codecType,
    parentModel,
    blacklist
  ) {
    if (!targetRenderInfo || targetRenderInfo === '') {
      globalTracingLogger.error(`isHitBlacklist() targetRenderInfo is invalid`);
      return true;
    }

    if (!blacklist) {
      globalTracingLogger.error(
        `isHitBlacklist() an invalid blacklist configuration object`
      );
      return false;
    }

    if (
      codecType !== 'encoder' &&
      codecType !== 'decoder' &&
      codecType !== 'all'
    ) {
      globalTracingLogger.error(
        `isHitBlacklist() an invalid codecType(${codecType}).`
      );
      return true;
    }

    const _parentModel = parentModel.toLowerCase();
    let hitBlacklist = false;
    for (const entry of blacklist) {
      const hasModel = 'model' in entry;
      const hasCodecType = 'codecType' in entry;
      const hasRenderInfo = 'renderInfo' in entry;
      const hasMinGeneration = 'minGeneration' in entry;
      const hasOS = 'os' in entry;

      // optional field
      let _model = null;
      if (hasModel) {
        if (entry.model !== '') {
          _model = this.replaceSpacesWithUnderscores(entry.model.toLowerCase());
        }
      }

      // must field
      let _codecType = null;
      if (hasCodecType) {
        _codecType = entry.codecType;
        if (
          _codecType !== 'all' &&
          _codecType !== 'encoder' &&
          _codecType !== 'decoder'
        ) {
          globalTracingLogger.error(
            `isHitBlacklist() codecType(${_codecType}) should be all/(empty)/encoder/decoder.`
          );
          continue;
        }
      } else {
        globalTracingLogger.warn(
          `isHitBlacklist() miss codecType field in the configuration.`
        );
        continue;
      }

      // optional field
      let _renderInfo = null;
      if (hasRenderInfo) {
        if (entry.renderInfo !== '') {
          _renderInfo = this.replaceSpacesWithUnderscores(
            entry.renderInfo.toLowerCase()
          );
        }
      }

      // optional field
      let _minGeneration = null;
      if (hasMinGeneration) {
        _minGeneration = entry.minGeneration;
      }

      // optional field
      let _os = null;
      if (hasOS) {
        if (entry.os !== '') {
          _os = entry.os.toLowerCase();
        }
      }

      if (!hasModel && !hasRenderInfo) {
        // if os field set, check it whether a platform should be disallowed
        if (hasOS) {
          if (this.isOnOSBlacklist(_os)) {
            hitBlacklist = true;
            break;
          }
        } else {
          // if no OS set and don't have neither model nor renderInfo, regard the entry as an invalid blacklist entry
          globalTracingLogger.warn(
            `isHitBlacklist() invalid blacklist entry. entry:${entry}`
          );
          continue;
        }
      }

      // 1. check renderInfo
      if (hasRenderInfo && _renderInfo !== '') {
        if (
          targetRenderInfo === _renderInfo ||
          targetRenderInfo.includes(_renderInfo)
        ) {
          if (_codecType === 'all' || _codecType === codecType) {
            if (hasOS) {
              if (this.isOnOSBlacklist(_os)) {
                hitBlacklist = true;
                break;
              }
            } else {
              hitBlacklist = true;
              break;
            }
          }
        }
      }

      // 2. check model
      // if set model in the blacklist entry
      // must a series of GPUs are disallowed
      if (_model) {
        if (_parentModel !== '') {
          if (_model === _parentModel) {
            if (_codecType === 'all' || _codecType === codecType) {
              if (_minGeneration && _minGeneration !== '') {
                const isLower = this.isLowerThanMinGeneration(
                  targetVendor,
                  targetRenderInfo,
                  _minGeneration
                );
                if (isLower) {
                  if (hasOS) {
                    if (this.isOnOSBlacklist(_os)) {
                      hitBlacklist = true;
                      break;
                    }
                  } else {
                    hitBlacklist = true;
                    break;
                  }
                }
              } else {
                if (hasOS) {
                  if (this.isOnOSBlacklist(_os)) {
                    hitBlacklist = true;
                    break;
                  }
                } else {
                  hitBlacklist = true;
                  break;
                }
              }
            }
          } else {
            // if model in blacklist is set, and model in parent whitelist is set
            // they should be the same value
            globalTracingLogger.warn(
              `isHitBlacklist() model(${_model}) in blacklist entry and model(${_parentModel}) in whitelist should be same!`
            );
            continue;
          }
        } else {
          // if parent model is not set, it means all GPUs of this vendor are allowed
          // if we need to put some of them to the blacklist, search the target renderInfo
          if (targetRenderInfo.includes(_model)) {
            if (_codecType === 'all' || _codecType === codecType) {
              if (_minGeneration && _minGeneration !== '') {
                const isLower = this.isLowerThanMinGeneration(
                  targetVendor,
                  targetRenderInfo,
                  _minGeneration
                );
                if (isLower) {
                  if (hasOS) {
                    if (this.isOnOSBlacklist(_os)) {
                      hitBlacklist = true;
                      break;
                    }
                  } else {
                    hitBlacklist = true;
                    break;
                  }
                }
              } else {
                if (hasOS) {
                  if (this.isOnOSBlacklist(_os)) {
                    hitBlacklist = true;
                    break;
                  }
                } else {
                  hitBlacklist = true;
                  break;
                }
              }
            }
          }
        }
      }
    }

    return hitBlacklist;
  },

  isOnOSBlacklist(os) {
    const _os = os.toLowerCase();
    if (_os === 'windows' && this.isWindows()) {
      return true;
    }

    if (_os === 'mac' && this.isMac()) {
      return true;
    }

    if (_os === 'chromeos' && this.isChromeOS()) {
      return true;
    }

    if (_os === 'android' && this.isAndroid()) {
      return true;
    }

    if (_os === 'linux' && this.isLinux()) {
      return true;
    }

    if (_os === 'ios' && this.is_iOS()) {
      return true;
    }

    return false;
  },

  isWindows() {
    return navigator.platform.indexOf('Win') > -1;
  },

  isMac() {
    return navigator.platform.indexOf('Mac') > -1;
  },

  isChromeOS() {
    try {
      if (/\bCrOS\b/.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  },

  isAndroid() {
    try {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  },

  isLinux() {
    return navigator.platform.indexOf('Linux') > -1 && !this.isChromeOS();
  },

  is_iOS() {
    try {
      if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  },

  replaceSpacesWithUnderscores(str) {
    if (!str || str === '') {
      return '';
    }

    const trimmed = str.trim();
    if (trimmed === '') {
      return '';
    }

    return `_${trimmed.replace(/ /g, '_')}_`;
  },

  isBrowserVersionHitBlacklist(browserVersion, blacklistVersion) {
    if (!browserVersion || !blacklistVersion) {
      globalTracingLogger.error(
        `compareBrowserVersion() invalid parameters! browserVersion:${browserVersion}, blacklistVersion:${blacklistVersion}`
      );
      return false;
    }

    // split the `blacklistVersion` to two parts
    // first char is always the operator
    // the remaining chars means the blacklist browser version
    const operator = blacklistVersion[0];
    let operatorType = 0; // 0 means equal to
    if (operator == '<') {
      operatorType = -1; // -1 means less than(included)
    } else if (operator == '=') {
      operatorType = 0; // 0 means equal to
    } else if (operator == '>') {
      operatorType = 1; // 1 means greater than(included)
    } else {
      globalTracingLogger.error(
        `isBrowserVersionHitBlacklist() invalid operator! operator:${operator}`
      );
      operatorType = 0;
    }

    const _blacklistVersion = blacklistVersion.slice(1);
    const browserVersionArray = browserVersion.toString().split('.');
    const blacklistVersionArray = _blacklistVersion.toString().split('.');

    const minLength = Math.min(
      browserVersionArray.length,
      blacklistVersionArray.length
    );

    // Compare each part of the version
    for (let i = 0; i < minLength; i++) {
      const _browser = parseInt(browserVersionArray[i], 10);
      const _blacklist = parseInt(blacklistVersionArray[i], 10);

      // Ensure both parts are valid numbers
      if (isNaN(_browser) || isNaN(_blacklist)) {
        globalTracingLogger.error(`Invalid version number detected!`);
        return false;
      }

      if (operatorType == 0) {
        // means equal to, not equal actually
        if (_browser !== _blacklist) {
          return false; // If any part doesn't match, it's not a blacklist hit
        }
      } else if (operatorType == -1) {
        // means less than, like < 102, browsers hit blacklist if version is less than 102
        if (_browser > _blacklist) {
          return false;
        }
      } else if (operatorType == 1) {
        // means greater than, like > 102, browsers hit blacklist if version is greater than 102
        if (_browser < _blacklist) {
          return false;
        }
      }
    }

    // If all checked parts match, it's a blacklist hit
    return true;
  },
};

export default MediaConfigParser;
