export enum VB_EVENT_TYPE {
  VB_INIT_SUCCESS = 'VB_INIT_SUCCESS',
  VB_INIT_FAILED = 'VB_INIT_FAILED',
  VB_GENERATED_FRAME = 'VB_GENERATED_FRAME',
  VB_SEND_FRAME = 'VB_SEND_FRAME',
  VB_UPDATE_BG = 'VB_UPDATE_BG',
  VB_PREDICT_DONE = 'VB_PREDICT_DONE',
  VB_MODEL_READY = 'VB_MODEL_READY',
  VB_FREE_MEMORY = 'VB_FREE_MEMORY',
  VB_VIDEO_FORMAT_UNSUPPORTED = 'VB_VIDEO_FORMAT_UNSUPPORTED',
  VB_WORKER_INIT = 'VB_WORKER_INIT',
  VB_WORKER_ERROR = 'VB_WORKER_ERROR',
  VB_RENDER_CANVAS = 'VB_RENDER_CANVAS',
  VB_RENDER_FRAME = 'VB_RENDER_FRAME',
  VB_START = 'VB_START',
  VB_REQUEST_FRAME = 'VB_REQUEST_FRAME',
  VB_TOGGLE_VB = 'VB_TOGGLE_VB',
  VB_STOP = 'VB_STOP',
  VB_MIRROR = 'VB_MIRROR',
  VB_GENERATE_STREAM = 'VB_GENERATE_STREAM',
  VB_STOP_STREAM = 'VB_STOP_STREAM',
  VB_CHANGE_STREAM_CANVAS_SIZE = 'VB_CHANGE_STREAM_CANVAS_SIZE',
  VB_GENERATE_FIRST_FRAME = 'VB_GENERATE_FIRST_FRAME',
  VB_UPDATE_STATS = 'VB_UPDATE_STATS',
  VB_UPDATE_NEED_FRAME = 'VB_UPDATE_NEED_FRAME',
  VB_UPDATE_FRAME_RATE = 'VB_UPDATE_FRAME_RATE',
  VB_RESOLUTION_CHANGE = 'VB_RESOLUTION_CHANGE',
}

export interface IVBFrame {
  data: Uint8Array;
  format_width: number;
  format_height: number;
  valid_x: number;
  valid_y: number;
  valid_width: number;
  valid_height: number;
  len_of_data: number;
  data_ptr: number | null;
  yuv_limited: number;
}

export interface IReceiveVideoFrame {
  data?: VideoFrame;
  displayWidth: number;
  displayHeight: number;
}

export interface IFreeMemory {
  pointer: number;
}

export interface IFormatUnsuppport {
  format: string;
}

export interface IVBInitParam {
  cdnPath?: string;
  tfJsUrl?: string;
  model?: string | ArrayBuffer;
  wasmGlueCodeUrl?: string;
  wasmUrl?: string;
  vbWorkerUrl?: string;
  enableWasm?: boolean;
  needFrame?: boolean;
}

export interface IVBStartParam {
  videoStream?: ReadableStream;
  frameRate?: number;
}

export interface VB_START_MSG {
  cmd: VB_EVENT_TYPE.VB_START;
  payload?: IVBStartParam;
}

export interface VB_BOOLEAN_MSG {
  cmd:
    | VB_EVENT_TYPE.VB_TOGGLE_VB
    | VB_EVENT_TYPE.VB_STOP
    | VB_EVENT_TYPE.VB_UPDATE_NEED_FRAME
    | VB_EVENT_TYPE.VB_MIRROR;
  payload: boolean;
}

export interface VB_WORKER_INIT_MSG {
  cmd: VB_EVENT_TYPE.VB_WORKER_INIT;
  payload: IVBInitParam;
}

export interface VB_RENDER_CANVAS_MSG {
  cmd: VB_EVENT_TYPE.VB_RENDER_CANVAS;
  payload: OffscreenCanvas;
}

export interface VB_SEND_FRAME_MSG {
  cmd: VB_EVENT_TYPE.VB_SEND_FRAME;
  payload: VideoFrame | ImageData;
}

export interface VB_UPDATE_BG_MSG {
  cmd: VB_EVENT_TYPE.VB_UPDATE_BG;
  payload: 'blur' | ImageData;
}

export interface VB_FREE_MEMORY_MSG {
  cmd: VB_EVENT_TYPE.VB_FREE_MEMORY;
  payload: number;
}

export interface VB_RENDER_FRAME_MSG {
  cmd: VB_EVENT_TYPE.VB_RENDER_FRAME;
  payload: VideoFrame | ImageData;
}

export interface VB_INIT_FAILED_MSG {
  cmd: VB_EVENT_TYPE.VB_INIT_FAILED;
  payload: any;
}

export interface VB_NO_PAYLOAD_MSG {
  cmd:
    | VB_EVENT_TYPE.VB_MODEL_READY
    | VB_EVENT_TYPE.VB_REQUEST_FRAME
    | VB_EVENT_TYPE.VB_STOP_STREAM
    | VB_EVENT_TYPE.VB_INIT_SUCCESS
    | VB_EVENT_TYPE.VB_GENERATE_FIRST_FRAME;
}

export interface VB_WORKER_ERROR {
  cmd: VB_EVENT_TYPE.VB_WORKER_ERROR;
  payload: ErrorEvent | PromiseRejectionEvent;
}

export interface VB_GENERATED_FRAME_MSG {
  cmd: VB_EVENT_TYPE.VB_GENERATED_FRAME;
  payload: IVBFrame;
}

export interface VB_VIDEO_FORMAT_UNSUPPORTED_MSG {
  cmd: VB_EVENT_TYPE.VB_VIDEO_FORMAT_UNSUPPORTED;
  payload: string;
}

export interface VB_GENERATE_STREAM_MSG {
  cmd: VB_EVENT_TYPE.VB_GENERATE_STREAM;
  payload?: WritableStream<VideoFrame> | OffscreenCanvas;
}

export interface VB_CHANGE_STREAM_CANVAS_SIZE_MSG {
  cmd: VB_EVENT_TYPE.VB_CHANGE_STREAM_CANVAS_SIZE;
  payload: {
    width: number;
    height: number;
  };
}

export interface VB_STATS {
  width: number;
  height: number;
  fps: number;
  receivingFps: number;
  generatedFrames: number;
  receivedFrames: number;
}

export interface VB_UPDATE_STATS_MSG {
  cmd: VB_EVENT_TYPE.VB_UPDATE_STATS;
  payload: VB_STATS;
}

export interface VB_PREDICT_DONE_MSG {
  cmd: VB_EVENT_TYPE.VB_PREDICT_DONE;
  payload: string; // tf backend
}

export interface VB_UPDATE_FRAME_RATE_MSG {
  cmd: VB_EVENT_TYPE.VB_UPDATE_FRAME_RATE;
  payload: number; // frame rate
}

export interface VB_RESOLUTION {
  width: number;
  height: number;
}

export interface VB_RESOLUTION_CHANGE_MSG {
  cmd: VB_EVENT_TYPE.VB_RESOLUTION_CHANGE;
  payload: VB_RESOLUTION;
}

export type VB_EVENT =
  | VB_WORKER_INIT_MSG
  | VB_UPDATE_BG_MSG
  | VB_FREE_MEMORY_MSG
  | VB_RENDER_CANVAS_MSG
  | VB_RENDER_FRAME_MSG
  | VB_SEND_FRAME_MSG
  | VB_START_MSG
  | VB_INIT_FAILED_MSG
  | VB_GENERATED_FRAME_MSG
  | VB_BOOLEAN_MSG
  | VB_NO_PAYLOAD_MSG
  | VB_VIDEO_FORMAT_UNSUPPORTED_MSG
  | VB_GENERATE_STREAM_MSG
  | VB_CHANGE_STREAM_CANVAS_SIZE_MSG
  | VB_UPDATE_STATS_MSG
  | VB_PREDICT_DONE_MSG
  | VB_UPDATE_FRAME_RATE_MSG
  | VB_RESOLUTION_CHANGE_MSG
  | VB_WORKER_ERROR;

export const VB_VIDEOFRAME_COPYTO_ERROR = 'VB_VIDEOFRAME_COPYTO_ERROR';
