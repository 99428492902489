/*
 * @Author: Steven.Ye steven.ye@zoom.us
 * @Date: 2024-10-24 17:16:16
 * @LastEditors: Steven.Ye steven.ye@zoom.us
 * @LastEditTime: 2024-11-03 21:19:16
 * @FilePath: pwa-media/wcl/SDK/src/inside/audio/RTCAudioLogger.js
 * @Description: WebRTC Audio Logger
 */
import globalTracingLogger from '../../common/globalTracingLogger';
import {
  REPORT_KEY_NORMAL,
  REPORT_KEY_SHARE,
  WEBRTC_SHARE_AUDIO_MODE,
} from '../../worker/common/consts';

// remove 0 at tailed with fixed. eg:0.00 =>0, 0.40 =>0.4, 0.42546=>0.42
function numberRemoveTail0WithFixed(numb, fixed) {
  if (typeof numb !== 'number') return 0;
  return parseFloat(numb.toFixed(fixed)).toString();
}
export default class RTCAudioLogger {
  static getReportKeyFromSSRC(ssrc, stat) {
    if (!ssrc) return;
    return stat.ssrc.toString() === ssrc ? REPORT_KEY_NORMAL : REPORT_KEY_SHARE;
  }

  static isAudioSharing(mode) {
    return mode & WEBRTC_SHARE_AUDIO_MODE;
  }

  /**
   * @description Parse the remote-inbound-rtp statistic from RTCPeerconnection getStats()
   * @param {string} normalAudioSSRC current meetin audio ssrc
   * @param {RTCStatsReport} stat remote-inbound-rtp report
   * @param {number} audioMode bytes indicator whether has audio sharing etc.
   * @param {object} statInfoCache cache report info
   * @param {object} publisherInfoCache cache report info for calculating
   * @return {*}
   */
  static remoteInboundRTPStatisticParse(
    normalAudioSSRC,
    stat,
    audioMode,
    statInfoCache,
    publisherInfoCache
  ) {
    let key = this.getReportKeyFromSSRC(normalAudioSSRC, stat);
    if (!key) return;
    if (key == REPORT_KEY_SHARE && !this.isAudioSharing(audioMode)) return;
    if (stat.roundTripTime === undefined) return;
    if (!statInfoCache[key]) {
      statInfoCache[key] = {};
    }
    statInfoCache[key]['rtt'] = Math.floor(stat.roundTripTime * 1000);
  }

  /**
   * @description Parse the outbound-rtp statistic from RTCPeerconnection getStats()
   * @param {string} normalAudioSSRC current meetin audio ssrc
   * @param {RTCStatsReport} stat outbound-rtp report
   * @param {number} audioMode bytes indicator whether has audio sharing etc.
   * @param {object} statInfoCache cache report info
   * @param {object} publisherInfoCache cache report info for calculating
   * @return {*}
   */
  static outboundRTPStatisticsParse(
    normalAudioSSRC,
    stat,
    audioMode,
    statInfoCache,
    publisherInfoCache
  ) {
    let key = this.getReportKeyFromSSRC(normalAudioSSRC, stat);
    if (!key) return;
    if (key == REPORT_KEY_SHARE && !this.isAudioSharing(audioMode)) return;
    if (!statInfoCache[key]) {
      statInfoCache[key] = {};
    }
    const {
      timestamp,
      bytesSent,
      packetsSent,
      nackCount,
      retransmittedBytesSent,
      retransmittedPacketsSent,
      targetBitrate,
      totalPacketSendDelay,
    } = stat;
    if (!publisherInfoCache[key]) {
      publisherInfoCache[key] = {
        lastBytesSent: bytesSent,
        lastTimeStamp: timestamp,
      };
    }
    const { lastTimeStamp, lastBytesSent } = publisherInfoCache[key];

    statInfoCache[key].bytesSentPerSecond = Math.floor(
      timestamp - lastTimeStamp
        ? (1000 * (bytesSent - lastBytesSent)) / (timestamp - lastTimeStamp)
        : 0
    );
    statInfoCache[key].packetsSent = packetsSent;
    statInfoCache[key].nackCount = nackCount;
    statInfoCache[key].retransmittedBytesSent = retransmittedBytesSent;
    statInfoCache[key].retransmittedPacketsSent = retransmittedPacketsSent;
    statInfoCache[key].targetBitrate = targetBitrate / 1000 + 'k';
    Object.assign(publisherInfoCache[key], {
      lastBytesSent: bytesSent,
      lastTimeStamp: timestamp,
    });
  }

  static outboundBitrateQosParse(stats, cacheInfo) {
    let totalBitrate = 0;

    stats.forEach((stat) => {
      if (stat.type === 'outbound-rtp') {
        const { bytesSent, timestamp } = stat;
        const { lastBytesSent, lastTimestamp } = cacheInfo[stat.ssrc] || {};
        if (bytesSent && timestamp && lastBytesSent && lastTimestamp) {
          totalBitrate += this.calculateBitrates(
            lastBytesSent,
            lastTimestamp,
            bytesSent,
            timestamp
          );
        }
        cacheInfo[stat.ssrc] = {
          lastBytesSent: bytesSent,
          lastTimestamp: timestamp,
        };
      }
    });
    cacheInfo.bitrate = totalBitrate.toFixed(1);
  }

  static inboundBitrateQosParse(stats, cacheInfo) {
    let totalBitrate = 0;

    stats.forEach((stat) => {
      if (stat.type === 'inbound-rtp') {
        const { bytesReceived, timestamp } = stat;
        const { lastBytesReceived, lastTimestamp } = cacheInfo[stat.ssrc] || {};
        if (bytesReceived && timestamp && lastBytesReceived && lastTimestamp) {
          totalBitrate += this.calculateBitrates(
            lastBytesReceived,
            lastTimestamp,
            bytesReceived,
            timestamp
          );
        }
        cacheInfo[stat.ssrc] = {
          lastBytesReceived: bytesReceived,
          lastTimestamp: timestamp,
        };
      }
    });
    cacheInfo.bitrate = totalBitrate.toFixed(1);
  }

  static calculateBitrates(
    preBytes = 0,
    preTimestamp = 0,
    curBytes = 0,
    curTimestamp = 0
  ) {
    if (
      preBytes === curBytes ||
      parseInt(preTimestamp) === parseInt(curTimestamp)
    )
      return 0;
    return (8 * 1000 * (curBytes - preBytes)) / (curTimestamp - preTimestamp);
  }

  /**
   * @description Parse the media-source statistic from RTCPeerconnection getStats()
   * @param {string} normalAudioSSRC current meetin audio ssrc
   * @param {RTCStatsReport} stat media-source report
   * @param {number} audioMode bytes indicator whether has audio sharing etc.
   * @param {object} statInfoCache cache report info
   * @param {object} publisherInfoCache cache report info for calculating
   * @return {*}
   */
  static mediaSourceStatisticsParse(
    audioStream,
    stat,
    audioMode,
    statInfoCache,
    publisherInfoCache
  ) {
    let key =
      stat.trackIdentifier == audioStream?.getAudioTracks()[0]?.id
        ? 'NORMAL'
        : 'SHARE';
    if (!key) return;
    if (key == REPORT_KEY_SHARE && !this.isAudioSharing(audioMode)) return;
    if (!statInfoCache[key]) {
      statInfoCache[key] = {};
    }
    const {
      audioLevel,
      echoReturnLoss,
      echoReturnLossEnhancement,
      totalAudioEnergy,
      totalSamplesDuration,
    } = stat;
    statInfoCache[key].audioLevel = numberRemoveTail0WithFixed(audioLevel, 2);
    statInfoCache[key].echoReturnLoss = echoReturnLoss;
    statInfoCache[key].echoReturnLossEnhancement = numberRemoveTail0WithFixed(
      echoReturnLossEnhancement,
      2
    );
    statInfoCache[key].totalAudioEnergy = numberRemoveTail0WithFixed(
      totalAudioEnergy,
      2
    );
    statInfoCache[key].totalSamplesDuration = numberRemoveTail0WithFixed(
      totalSamplesDuration,
      1
    );
  }

  /**
   * @description Parse the network related statistic from RTCPeerconnection getStats()
   * @param {RTCStatsReports} stats all reports from RTCPeerconnection getStats()
   * @param {RTCStatsReport} stat media-source report
   * @param {number} audioMode bytes indicator whether has audio sharing etc.
   * @param {object} statInfoCache cache report info
   * @param {object} publisherInfoCache cache report info for calculating
   * @return {*}
   */
  static networkInfoStatisticsParse(
    stats,
    stat,
    audioMode,
    publisherInfoCache,
    direction
  ) {
    let port = 0;
    let protocol = null;
    let candidateType = null;
    if (stat.type === 'candidate-pair') {
      const remoteCandidate = stats.get(stat.remoteCandidateId);
      //for Firefox, which port and protocol is used
      if (stat.selected && remoteCandidate) {
        port = remoteCandidate.port;
        protocol = remoteCandidate.protocol;
      }
      const localCandidate = stats.get(stat.localCandidateId);
      if (stat.selected && localCandidate) {
        candidateType = localCandidate.candidateType;
      }
    } else if (stat.type === 'transport' && stat.selectedCandidatePairId) {
      //Chrome and Safari, which port and protocol is used
      let candidatePair = stats.get(stat.selectedCandidatePairId);
      const remoteCandidate = stats.get(candidatePair.remoteCandidateId);
      if (remoteCandidate) {
        port = remoteCandidate.port;
        protocol = remoteCandidate.protocol;
      }
      const localCandidate = stats.get(candidatePair.localCandidateId);
      if (localCandidate) {
        candidateType = localCandidate.candidateType;
      }
    }

    if (
      port &&
      protocol &&
      (port !== publisherInfoCache.port ||
        protocol != publisherInfoCache.protocol ||
        candidateType != publisherInfoCache.candidateType)
    ) {
      publisherInfoCache.port = port;
      publisherInfoCache.protocol = protocol;
      publisherInfoCache.candidateType = candidateType;
      globalTracingLogger.directReport(
        `${direction} port:${port}, ${direction} protocol:${protocol}, ${direction} localcandidateType:${candidateType}`
      );
    }
  }
}
