export const BIT_MAPPING = {
  // bit.1 audio bridge
  // 1: iOS mobile enabled 2: tesla 4: android mobile 8: MTR-A
  AUDIO_BRIDGE_1: {
    index: 1,
    default: 15,
  },

  // bit.2 audio bridge
  // 1: pad 2: low performance
  AUDIO_BRIDGE_2: {
    index: 2,
    default: 1,
  },

  // 1: enabled 0: disabled
  HW_ENCODER_FOR_360P: {
    index: 3,
    default: 0,
  },

  // 1: enabled 0: disabled
  WEBGL_CONTEXT_LOST_OPT: {
    index: 4,
    default: 0,
  },

  // 1: enabled 0: disabled
  RECEIVE_720P_ON_SAFARI: {
    index: 5,
    default: 0,
  },

  // 1: multi-view 0: single-view
  MULTI_VIEW_ON_MOBILE: {
    index: 8,
    default: 0,
  },

  // 1: enabled(default) 0: disabled
  AUDIO_DENOISE: {
    index: 11,
    default: 1,
  },

  // 1: enabled 0: disabled
  VB_ON_FIREFOX: {
    index: 12,
    default: 0,
  },

  // 1: enabled 0: disabled
  ENABLE_DECODE_720P_ON_IOS: {
    index: 15,
    default: 1,
  },

  // 1: enabled 0: disabled
  UNIFIED_RENDER: {
    index: 20,
    default: 0,
  },

  // 1: enabled 0: disabled
  WEBGPU_RENDERER: {
    index: 21,
    default: 0,
  },

  // 1: enabled 0: disabled
  ORIGINAL_SOUND: {
    index: 22,
    default: 0,
  },

  // 1: enabled 0: disabled
  SEND_1080P_VIDEO: {
    index: 24,
    default: 0,
  },

  // 1: enabled 0: disabled
  SEND_1080P_VIDEO_SHARE: {
    index: 25,
    default: 0,
  },

  // 1: enabled 0: disabled
  VB_ON_SAFARI_17: {
    index: 27,
    default: 0,
  },

  // 1: enabled 0: disabled
  WEBGL2_RENDERER: {
    index: 29,
    default: 1,
  },

  // 1: enabled 0: disabled
  AUDIO_ECHO_DETECT: {
    index: 31,
    default: 0,
  },

  // 1: enabled 0: disabled
  UNIFIED_RENDER_ON_MOBILE: {
    index: 34,
    default: 0,
  },

  // 1: no-preference 0: prefer-hardware(default)
  WEBCODEC_DECODE_OPTION: {
    index: 36,
    default: 0,
  },

  // 1: enabled 0: disabled(default)
  HW_WEBCODEC_ON_SAFARI: {
    index: 37,
    default: 1,
  },

  // 1: enabled 0: disabled(default)
  HW_DECODE_FOR_360P: {
    index: 38,
    default: 0,
  },

  // 1: enabled 0: disabled(default)
  WEBCODEC_ON_ANDROID_CHROME: {
    index: 39,
    default: 0,
  },

  // 1: enabled 0: disabled
  SUPPORT_ANNOTATION: {
    index: 40,
    default: 0,
  },

  // 1: enabled 0: disabled(default)
  WEBCODEC_ENCODE_OPT_1ON1: {
    index: 41,
    default: 0,
  },

  // 0: open encoder+decoder 1: close encoder 2: close decoder 3: close encoder+decoder
  CAP_WEBCODEC_SUPPORT: {
    index: 42,
    default: 0,
  },

  // 0: disabled 1: enabled 2: auto
  WEBRTC_STG: {
    index: 43,
    default: 0,
  },

  // 0: disabled 1: enabled(default)
  WEBGL_CANVAS_OPTION_OPT: {
    index: 44,
    default: 1,
  },

  // 1: WebGL 2: WebGPU 3: WebGL2
  DEFAULT_RENDERER: {
    index: 45,
    default: 3,
  },

  // 0: disabled 1: enabled
  WEB_TRANSPORT_CONTROL: {
    index: 46,
    default: 0,
  },

  // 0: disabled(default) 1: enabled
  ENABLE_TP_RLB_WEBSOCKET: {
    index: 47,
    default: 0,
  },

  // 0: disabled(default) 1: enabled
  ENABLE_TRANSFERABLE_RTC_DATACHANNEL: {
    index: 48,
    default: 1,
  },

  // 0: disabled(default) 1: enabled
  ENABLE_WEBRTC_FEATURE: {
    index: 49,
    default: 0,
  },

  // a hex number
  // 0b0001 - android
  // 0b0010 - iOS
  // 0b0100 - desktop
  // 0b1000 - unknown
  WEBRTC_AUTO_CONFIG: {
    index: 50,
    default: 0,
    candidates: {
      NO_CONFIG: 0b0,
      MOB_ANDROID: 0b1,
      MOB_IOS: 0b10,
      DESKTOP: 0b100,
      OTHERS: 0b1000,
    },
  },

  FORCE_TO_USE_WEBRTC: {
    index: 51,
    default: 0,
    candidates: {
      NO_CONFIG: 0b0,
      AUDIO_ON_BROWSER_32BIT: 0b1, // force to use WebRTC for audio on 32bit browser
      VIDEO_ON_BROWSER_32BIT: 0b10, // force to use WebRTC for video on 32bit browser
    },
  },

  ENABLE_WEBRTC_TURN_SERVERS: {
    index: 52,
    default: 0,
  },

  EXTRA_DEVICE_INTERVAL_ENUMERATE: {
    index: 53,
    default: 0,
    candidates: {
      DISABLE: 0,
      ENABLE: 1,
    },
  },
};
