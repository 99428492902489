import * as jsMediaEngine from '../../lib/JsMediaEngine';
import { IsSupportTransferableDataChannel } from '../util';
import jsMediaEngineVariables from '../../inside/JsMediaEngine_Variables';
import { DataTransport } from './transport';
import globalTracingLogger from '../globalTracingLogger';

export function initNetWorker(path) {
  let enabled =
    IsSupportTransferableDataChannel() &&
    jsMediaEngineVariables.enableTransferDataChannel;

  if (!enabled || !path) return null;
  const IsWorker = typeof DedicatedWorkerGlobalScope != 'undefined';
  if (IsWorker) {
    return null;
  }
  let mgr = DataTransport.dataTransportMgr;
  if (mgr.mediadatachannel.netthreadworker == null) {
    mgr.mediadatachannel.netthreadworker = jsMediaEngine.CreateThreadWorker(
      path,
      'net'
    );
    globalTracingLogger.directReport('transferdatachannel supported');
  }
  return mgr.mediadatachannel.netthreadworker;
}
export function unInitNeWorker(path) {
  let mgr = DataTransport.dataTransportMgr;
  if (!mgr.mediadatachannel.netthreadworker) {
    return;
  }
  mgr.mediadatachannel.netthreadworker.destroy();
  mgr.mediadatachannel.netthreadworker = null;
}
