import * as SdkEvent from '../sdkEvent';
import { DumyPort, MediaDataChannel } from './mediadatachannel';
import { DataTransport, MsgQueueSocket } from './transport';
import { DataChannelWrapper } from './datachannelwraper';
/**
 *
 * @param {*} transport
 * @param {*} sendersab  { useCopy interval,sab,offset,length,useOneElement}
 * @param {*} reciversab { useCopy interval,sab,offset,length,useOneElement}
 */

export function bindSabBufferForTransport(transport, sendersab, reciversab) {
  if (!transport) {
    return;
  }
  let mgr = DataTransport.dataTransportMgr;

  if (mgr.type === DataTransportManager.THREAD_MAIN) {
    mgr.setSabBuffer(transport, sendersab, reciversab);

    transport.remote.postMessage({
      cmd: SdkEvent.TRANSPORT_SET_SABBUFF,
      transportId: transport.id,
      sender: reciversab,
      reciver: sendersab,
    });
  } else {
    transport.setSabBuffer(sendersab, reciversab);
    mgr.mainThread.postMessage({
      cmd: SdkEvent.TRANSPORT_SET_SABBUFF,
      transportId: transport.id,
      sender: reciversab,
      reciver: sendersab,
    });
  }
}

export class DataTransportManager {
  static THREAD_MAIN = 1 << 0;
  static THREAD_SUB = 1 << 1;
  constructor(prams) {
    let args = prams || {};
    this.type = args.type || DataTransportManager.THREAD_MAIN;
    this.refs = {};
    this.transportlists = [];
    this.mainThread = args.remote;
    this.subthreadlistner = null;
    this.channellists = [];
    this.mediadatachannel = new MediaDataChannel(this);
  }

  _onrecvmainthreadlistener(ev) {
    let { cmd, transportId, data } = ev.data;
    let transport = this.transportlists.find((elm) => {
      return elm.id === transportId;
    });
    if (!transport && cmd != SdkEvent.CREATE_MSGSOCK_TRANSPORT) {
      return;
    }

    switch (cmd) {
      case SdkEvent.CREATE_MSGSOCK_TRANSPORT:
        this.addRemoteTransport(ev.data, null);
        break;
      case SdkEvent.TRANSPORT_SET_MSGPORT:
        transport.setMsgPort(data || new DumyPort());
        break;
      case SdkEvent.TRANSPORT_SET_SABBUFF:
        transport.setSabBuffer(ev.data.sender, ev.data.reciver);
        break;
      case SdkEvent.CLOSE_TRANSPORT:
        transport.remote = null;
        this.removeTransport(transport);
        break;
    }
  }

  _onrecvsubthreadlistener(remote, ev) {
    let { cmd, transportId: id, transportType: type } = ev.data;
    let transport = this.transportlists.find((elm) => {
      return elm.id === id;
    });

    switch (cmd) {
      case SdkEvent.CREATE_MSGSOCK_TRANSPORT: {
        this.addRemoteTransport(ev.data, remote);
        break;
      }
      case SdkEvent.TRANSPORT_SET_SABBUFF:
        {
          this.setSabBufferInfo(transport, ev.data.sender, ev.data.reciver);
        }
        break;
      case SdkEvent.CLOSE_TRANSPORT:
        {
          transport.remote = null;
          this.removeTransport(transport);
        }
        break;
    }
  }

  createRemoteTransport(src, remoteThread) {
    if (process.env.NODE_ENV === 'development') {
      console.error(
        `createRemoteTransport id=${src.id}, type=${
          src.type
        } port=${!!src.portInfo} `
      );
    }
    let message = {
      cmd: SdkEvent.CREATE_MSGSOCK_TRANSPORT,
      transportType: src.type,
      transportId: src.id,
    };
    if (src.portInfo) {
      (message.port = src.portInfo),
        remoteThread.postMessage(message, [src.portInfo]);
    } else {
      remoteThread.postMessage(message);
    }
  }

  closeRemoteTransport(src, remoteThread) {
    remoteThread.postMessage({
      cmd: SdkEvent.CLOSE_TRANSPORT,
      transportType: src.type,
      transportId: src.id,
    });
  }

  setRemoteTransportSABBUffer(src, remoteThread) {
    if (src.sabInfo?.sender || src.sabInfo?.reciver) {
      remoteThread.postMessage({
        cmd: SdkEvent.TRANSPORT_SET_SABBUFF,
        transportId: src.id,
        sender: src.sabInfo?.sender,
        reciver: src.sabInfo?.reciver,
      });
    }
  }

  addRemoteTransport(transportInfo, remote) {
    let { transportId: id, port, transportType: type } = transportInfo;
    if (process.env.NODE_ENV === 'development') {
      console.error(
        `addRemoteTransport  iswindows=${
          typeof window != 'undefined'
        } id=${id}, type=${type}`
      );
    }
    let transport = this.createMsgSocketTransport(type);
    transport.id = id;
    transport.remote = remote;
    transport.portInfo = port;
    if (!port) {
      this.bindMessageChannel(transport);
    } else {
      transport.setMsgPort(transport.portInfo);
    }
    this.addTransport(transport);
  }

  addTransport(transport) {
    let channel = this.getChannelByTransportType(transport.type);
    if (!channel) {
      return;
    }
    let targetThread = channel.target_thread || MediaDataChannel.SELF_THREAD;
    transport.target_thread = targetThread;
    this.bindTransPortForChannel(transport, channel);
  }

  removeTransport(transport) {
    let idx = this.transportlists.indexOf(transport);
    if (process.env.NODE_ENV === 'development') {
      console.error(
        `removeTransport start ransport id=${transport.id} idx=${idx} size=${this.transportlists.length}`
      );
    }

    if (idx == -1) {
      return;
    }

    this.transportlists.splice(idx, 1);

    if (transport.remote) {
      this.closeRemoteTransport(transport, transport.remote);
    }
    if (transport.target_thread == MediaDataChannel.NO_THREAD) {
      return;
    }
    /// disconnect with datachannel
    this.unbindTransPortForChannel(transport);
  }

  createMsgSocketTransport(transportType) {
    let transport = null;
    transport = new DataTransport({
      mgr: this,
      sock: new MsgQueueSocket(),
      type: transportType,
      local: true,
    });
    return transport;
  }

  bindMessageChannel(transport) {
    if (this.type != DataTransportManager.THREAD_MAIN) {
      console.error('error this call only in main thread');
      return;
    }
    let msg = new MessageChannel();
    transport.portInfo = msg.port1;
    transport.remote.postMessage(
      {
        cmd: SdkEvent.TRANSPORT_SET_MSGPORT,
        transportId: transport.id,
        data: msg.port2,
      },
      [msg.port2]
    );
  }

  setSabBufferInfo(transport, sender, reciver) {
    if (this.type != DataTransportManager.THREAD_MAIN) {
      console.error('<<<<< setSabBufferInfo in sub thread');
      return;
    }
    if (!transport.sabInfo) {
      transport.sabInfo = {};
    }
    // net only used
    if (reciver) {
      reciver.useCopy = true;
    }
    if (sender) {
      sender.useCopy = true;
    }
    transport.sabInfo = { sender, reciver };

    if (transport.target_thread == MediaDataChannel.NO_THREAD) {
      return;
    }

    if (transport.target_thread == MediaDataChannel.SELF_THREAD) {
      transport.setSabBuffer(sender, reciver);
      return;
    }

    this.setRemoteTransportSABBUffer(transport, transport.target_thread);
  }

  addDataChannel(channel) {
    if (!(channel instanceof DataChannelWrapper)) {
      console.error('channel must be a DataChannelWrapper');
      return;
    }
    try {
      this.checkTransport(channel);
    } catch (e) {
      console.error('addDataChannel error', e);
    }

    this.channellists.push(channel);
  }
  removeDataChannel(channel) {
    if (!(channel instanceof DataChannelWrapper)) {
      console.error('channel must be a DataChannelWrapper');
      return;
    }

    let idx = this.channellists.indexOf(channel);
    if (idx !== -1) {
      this.channellists.splice(idx, 1);
    }
  }

  removeTransportByRemote(remote) {
    let temp = [];
    for (let i = 0; i < this.channellists.length; i++) {
      let transport = this.channellists[i];
      if (transport.remote === remote) {
        temp.push(transport);
      }
    }

    for (let i = 0; i < temp.length; i++) {
      this.removeTransport(temp[i]);
    }
  }

  reinit() {
    let temp = [...this.transportlists];
    for (let i = 0; i < temp.length; i++) {
      this.removeTransport(temp[i]);
    }
  }

  getTransportByType(type) {
    return this.mediadatachannel.getTransportByType(type);
  }

  addTransportListChangeListener(fn) {
    this.mediadatachannel.addEventListener(fn);
  }
  remoteTransportListChangeListener(fn) {
    this.mediadatachannel.removeEventListener(fn);
  }

  checkTransport(channel) {
    this.transportlists.forEach((transport) => {
      if (!channel.transportlists.includes(transport.type)) {
        return;
      }
      let targetThread = channel.target_thread || MediaDataChannel.SELF_THREAD;

      let isband = targetThread == transport.target_thread;
      if (isband) {
        return;
      }

      if (
        this.type == DataTransportManager.THREAD_MAIN &&
        transport.target_thread != MediaDataChannel.NO_THREAD &&
        transport.target_thread != targetThread
      ) {
        /// target thread had changed
        this.unbindTransPortForChannel(transport);
        this.bindMessageChannel(transport);
      }

      transport.target_thread = targetThread;
      this.bindTransPortForChannel(transport, channel);
    });
  }

  bindTransPortForChannel(transport, channel) {
    /// using
    if (process.env.NODE_ENV === 'development') {
      console.error(
        `<<< bindTransPortForChannel transport id=${transport.id}, type=${transport.type}`
      );
    }
    transport.channel = channel;
    let targetThread = transport.target_thread;
    if (targetThread != MediaDataChannel.SELF_THREAD) {
      this.createRemoteTransport(transport, targetThread);
      this.setRemoteTransportSABBUffer(transport, targetThread);
    } else {
      if (transport.portInfo) {
        transport.setMsgPort(transport.portInfo);
      }
      if (transport.sabInfo?.sender || transport.sabInfo?.reciver) {
        transport.setSabBuffer(
          transport.sabInfo?.sender,
          transport.sabInfo?.reciver
        );
      }
      this.mediadatachannel.addTransport(transport, channel);
    }
  }

  unbindTransPortForChannel(transport) {
    /// using
    if (transport.target_thread != MediaDataChannel.SELF_THREAD) {
      if (this.type == DataTransportManager.THREAD_MAIN) {
        this.closeRemoteTransport(transport, transport.target_thread);
      }
    } else {
      this.mediadatachannel.removeTransport(transport);
    }
  }

  getChannelByTransportType(type) {
    for (let idx = 0; idx < this.channellists.length; idx++) {
      let channel = this.channellists[idx];
      if (!channel.isReady()) {
        continue;
      }
      let lists = channel.transportlists;
      if (lists.includes(type)) {
        return channel;
      }
    }
    return null;
  }
}
