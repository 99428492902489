//Notify_SDK
export const START_MEDIA = 0;
export const ADD_RENDER_VIDEO = 1;
export const STOP_RENDER_VIDEO = 2;
export const START_CAPTURE_VIDEO = 3;
export const STOP_CAPTURE_VIDEO = 4;
export const ADD_RENDER_AUDIO = 5;
export const STOP_RENDER_AUDIO = 6;
export const UNMUTE_AUDIO = 7;
export const MUTE_AUDIO = 8;
export const CHANGE_FRAME_RATE = 9;
export const CHANGE_VIDEO_RESOLUTION = 10;
export const CHANGE_AUDIO_SPEAKER = 11;
export const CHANGE_VIDEO_CAPTURE_DEVICE = 12;
export const CHANGE_CURRENT_ACTIVE_SSRC = 13;
export const REMOVE_AUDIO_CAPTURE = 14;
export const LEAVE_MEETING = 15;
export const MEETING_FAIL_OVER = 16;
export const END_MEDIA = 17;
export const CHANGE_AUDIO_MIC = 18;
export const WEBRTC_RESTART = 19;
export const REMOVE_RENDER_AUDIO = 20;
export const LEAVE_COMPUTER_AUDIO = 21;
export const JOIN_COMPUTER_AUDIO = 22;
export const START_SHARING = 23;
export const STOP_SHARING = 24;

// Video capture can be passed into multiple canvas, and the developer may need to output video source to any of them
// Therefore, it is necessary to support developers to switch video source output to the specified canvas
export const SWITCH_CANVAS_FOR_VIDEO_CAPTURE = 25;
// remote control when other people is sharing
export const START_REMOTE_CONTROL = 26;
export const UPDATE_REMOTE_CONTROL_PROPERTIES = 27;
export const CANCEL_REMOTE_CONTROL = 28;
export const UPDATE_SUBSCRIBE_VIDEO = 29;
export const START_DESKTOP_SHARING = 30;
export const START_SHARING_WHITEBOARD = 30.1;
export const STOP_DESKTOP_SHARING = 31;
export const STOP_SHARING_WHITEBOARD = 31.1;
export const PAUSE_DESKTOP_SHARING = 32;
export const RESUME_DESKTOP_SHARING = 33;
export const CHECK_CHROME_SHARING_EXTENSION = 34;
export const SWITCH_CANVAS_FOR_SHARING_CAPTURE = 35;
export const CHANGE_CURRENT_SHARING_ACTIVE_SSRC = 36;
export const COMMAND_SOCKET_MESSAGE_NOTIFY = 40;
export const RESEND_REMOTE_CONTROL_POSITION_PDU = 41;
export const AES_GCM_IV_VALUE = 42;
export const USER_NODE_LIST = 43;
export const UPDATE_SHARING_DECODE_PARAM = 44;
export const PAUSE_OR_RESUME_AUDIO_DECODE = 45;
export const UPDATE_CANVAS_SIZE = 46;
export const CLEAR_CANVAS = 47;
export const ZOOM_RENDER = 48;
export const CHANGE_SHARING_2ND_VIDEO_CAPTUREVIDEO_DEVICE = 49;
export const SET_OTHER_AUDIO_VOLUME_LEVEL = 50;
export const USER_NODE_AUDIO_STATUS_LIST = 51;
export const UPDATE_MASK_CANVAS_SIZE = 52;
export const MOVE_PTZ_CAMERA = 53;
export const START_STOP_REMOTE_CONTROL_CHECK = 54;
export const SEND_REMOTE_CONTROL_QR_CODE = 55;

export const AUDIO_CC_SELECT_LANGUAGE = 57;
export const AUIOD_INTERPRETATION_MUTE = 58;
export const AUDIO_INTERPRETATION_SELECT_LANGUAGE = 59;
export const AUDIO_INTERPRETATION_LIST_INFO = 60;
export const AUDIO_INTERPRETATION_ENABLE = 61;
export const VIDEO_MASK_SETTING = 62;
export const UPDATE_BG_IMAGE = 63;
export const UPDATE_MASK_INFO = 64;
export const FINISH_MASK_SETTING = 65;
export const START_VIDEO_STREAM_IN_MASK_SETTING_SUCCESS = 66;

export const VIDEO_ENABLE_DECODE_HW = 70;
export const VIDEO_ENABLE_ENCODE_HW = 71;
export const JOIN_DESKTOP_AUDIO = 72;
export const LEAVE_DESKTOP_AUDIO = 73;
export const SET_DESKTOP_VOLUME = 74;
export const MIRROR_MY_VIDEO = 75;
export const REMOVE_EXPIRED_CANVAS = 76;
export const WEBGL_LOST_REPLACE_CANVAS = 77;

export const UPDATE_VIDEO_QUALITY = 78;
export const SEND_RENDER_LOG = 79;

/**
 * Share Bo Param
 * */
export const USER_NODE_LIST_IN_MAIN_SESSION = 80;
export const UPDATE_MEDIA_PARAMS = 81;
export const SHARING_ADD_REV_CHANNEL_TYPE = 82;
export const SHARING_REMOVE_REV_CHANNEL_TYPE = 83;
export const BUILD_MS_CHANNEL_IN_BO = 84;
export const BUILD_MA_CHANNEL_IN_BO = 85;
export const ENABLE_SHARE_TO_BO = 86;
export const ENABLE_BROADCAST_TO_BO = 87;

/**
 * watermark
 */
export const SWITCH_WATER_MARK_FLAG = 90;

export const START_VIDEO_VB_SETTING = 91;
export const UPDATE_VIDEO_VB_BG_IMAGE = 92;
export const STOP_VIDEO_VB_SETTING = 93;
export const START_VIDEO_STREAM_IN_VB_SETTING_SUCCESS = 94;
export const SWITCH_MASK_AND_VB = 95;
export const VB_MODEL_PRELOADING_3S = 96;
export const VB_MODEL_PRELOADING_10S = 97;
export const VB_MODEL_PRELOADING_OK = 98;
export const ENABLE_VIDEO_OBSERVER = 99;
export const SWITCH_SHARING_TYPE = 101;

export const CHANGE_HID_ENABLE = 100;

export const ADD_VIDEO_VB_SETTING_DOM = 102;
export const REMOVE_VIDEO_VB_SETTING_DOM = 103;

export const NEW_ACTIVE_SPEAKER_SSRC = 110;
export const NEW_ACTIVE_SPEAKER_FIRST_FRAME_CALLBACK = 111;
export const CANCEL_NEW_ACTIVE_SPEAKER_BEFORE_CALL_BACK = 112;
/** This is for notifying media sdk 4098 rwg signal received, media sdk can send message to rwg now */
export const NOTIFY_SDK_JOIN_RWG_SUCCESS = 113;
// audio bridge init flow
export const AUDIO_BRIDGE_FIRST_RECV_DATA = 114;
export const AUDIO_BRIDGE_CAN_SEND_DATA = 115;
export const FIRST_VIDEO_FRAME = 116;
export const PREVIEW_INIT_VIDEO_DECODE_SUCCESS = 117;
export const PREVIEW_INIT_AUDIO_DECODE_SUCCESS = 118;
export const WHITEBOARD_JOIN_MESSAGE = 120;

//audio denoise
export const AUDIO_DENOISE_SWITCH = 121;
export const SET_CODEC_MODE = 122;
export const STOP_AUDIO_INCOMING = 123;

//mobile rotate
export const MOBILE_ROTATE = 124;

// save local log
export const SAVE_LOCAL_LOG = 125;

export const CHANGE_AUDIO_PROFILE = 126;
export const AUDIO_JOIN_SUCCESS = 127;
export const RWG_COMMAND_BYPASS_TO_WCL = 128;
export const SHARE_2ND_AUDIO_CAPTURE_DEVICE = 129;

export const SUBSCRIBE_VIDEO = 130;
export const UNSUBSCRIBE_VIDEO = 131;

export const UI_SUBSCRIBE_VIDEO = 132; // user side subscribe video
export const UI_UNSUBSCRIBE_VIDEO = 133;

// export const UI_UPDATE_ACTIVE_USER_VIDEO = 134;

export const MOBILE_CAPTURE_DEVICE_CHANGE = 135;

// enter/leave waiting room true:enter room, false leave room
export const ON_HOLD = 136;

export const SET_ALL_SPEECH_VOLUME = 137;
export const ENABLE_FILE_AUDIO_PLAYBACK_LOCALLY = 138;

export const START_ANNOTATION_A = 150; // attendee
// export const START_ANNOTATION_P = 151; // presenter
export const ANNOTATION_ACTIONS = 152; // annotation tool bar actions

export const STOP_ANNOTATION_A = 156;
// export const STOP_ANNOTATION_P = 157;
export const START_ANNOTATION_SUCCESS = 158;

export const DEVICE_CHANGE_EVENT = 159;
export const RECAPTURE_AUDIO = 160;
export const REQUEST_PERMISSION = 161;
export const REQUEST_PERMISSION_RESULT = 162;
export const REQUEST_PERMISSIOM_POP_REMINDER = 163;

//NOTIFY_APP

//success
export const INIT_SUCCESS = 1;
export const INIT_SUCCESS_VIDEO = 1.1;
export const INIT_SUCCESS_AUDIO = 1.2;
export const INIT_SUCCESS_SHARING = 1.3;
export const USER_GRANT_CAPTURE_AUDIO = 2;
export const CURRENT_VIDEO_RESOLUTION = 3;
export const SHARING_DEC_THREAD_OK = 4;
export const SHARING_DATA = 5;
export const SHARING_PARA = 6;
/**
 * 2021.01.05 added for IE sdk, it contain some meeting sharing information.
 * For example, one message is that if the current webclient enabled E2E for sharing media data
 */
export const SHARING_MORE_INFO = 6.1;
export const VIDEO_DECODE_MAX_SIZE = 7;
/**
 * Returns the width and height of the video stream that's from camera (videoWidth, videoHeight)
 * @type {number}
 */
export const CURRENT_CAPTURE_VIDEO_WIDTH_HEIGHT = 8;
export const START_VIDEO_CAPTURE_SUCCESS = 9;
export const STOP_VIDEO_CAPTURE_SUCCESS = 10;
export const START_REMOTE_CONTROL_SUCCESS = 11;
export const CANCEL_REMOTE_CONTROL_SUCCESS = 12;
/**
 * return copied text from websocket/internet/the other side computer
 * @type {number}
 */
export const REMOTE_CONTROL_COPIED_TEXT_NOTIFY = 13;

export const MONITOR_LOG = 14;

export const CURRENT_DESKTOP_SHARING_WIDTH_HEIGHT = 15;
export const DESKTOP_SHARING_CAPTURE_SUCCESS = 16;
export const CHECK_CHROME_SHARING_EXTENSION_RESPONSE = 17;
export const SHARING_DECODE_MAX_SIZE = 18;
export const REQUEST_AUDIO_BRIDGE_TOKEN = 19;
export const SEND_MESSAGE_TO_RWG = 20;
export const AES_GCM_IV_RESPONSE = 21;
export const SHARING_DESKTOP_STREAM_HAVE_AUDIO = 23;

//failed
export const JOIN_COMPUTER_AUDIO_COMPLETE = 24;
export const JOIN_DESKTOP_AUDIO_COMPLETE = 25;
export const LEAVE_COMPUTER_AUDIO_COMPLETE = 26;
export const LEAVE_DESKTOP_AUDIO_COMPLETE = 27;

export const HID_STATUS_MUTE = 28;
export const HID_STATUS_OFF_HOOK = 29;
export const WB_MESSAGE = 30;

//stream fail
export const AUDIO_STREAM_FAILED = 31;
export const VIDEO_STREAM_FAILED = 32;

export const AUDIO_SPEAKER_SET_SUCCESS = 33;
export const FIRST_IOS_FRAME = 34;

export const AUDIOBRIDGE_EBABLE_SHARE_TO_BO_SUCCESS = 35;
export const AUDIOBRIDGE_SET_CC_LANG_SUCCESS = 36;
export const AUDIOBRIDGE_ENABLE_BROADCAST_TO_BO_SUCCESS = 37;

export const AUDIO_LEVEL_INDICATOR = 38;
export const SYNC_RENDERER_TYPE_RESPONSE = 39;

//failed
export const INIT_FAILED = -1;
export const INIT_FAILED_VIDEO = -1.1;
export const INIT_FAILED_AUDIO = -1.2;
export const INIT_FAILED_SHARING = -1.3;
export const AUDIO_CAPTURE_FAILED = -2;
export const AUDIO_WEBSOCKET_BROKEN = -3;
export const VIDEO_WEBSOCKET_BROKEN = -4;
export const SHARING_DEC_THREAD_FAILED = -5;
export const AUDIO_ZERO_DATA = -6;
export const AUDIO_CTX_SAMPLERATE = -7;
export const USER_FORBIDDED_CAPTURE_VIDEO = -8;
export const USER_CAMERA_IS_TAKEN_BY_OTHER_PROGRAMS = -9;
export const STOP_VIDEO_CAPTURE_FAILED = -10;
export const START_REMOTE_CONTROL_FAILED = -11;
export const CANCEL_REMOTE_CONTROL_FAILED = -12;
export const REMOTE_CONTROL_PASTE_TEXT_LENGTH_OVERFLOW = -14;
export const USER_STOP_DESKTOP_SHARING = -15;
export const USER_CANCEL_PERMISSION_REQUEST = -16;
export const DESKTOP_SHARING_CHROME_EXTENSION_UNINSTALLED = -17;
export const DESKTOP_SHARING_PERMISSION_DENIED = -18;
export const DESKTOP_SHARING_TIME_OUT = -19;
export const DESKTOP_SHARING_ERROR = -20;
export const AUDIO_SPEAKER_SET_ERROR = -21;
export const DESKTOP_SHARING_SYSTEM_ERROR = -22;
export const AUDIO_CLIPPING = -23;
export const AUDIO_AUTO_PLAY_FAILED = -24;
export const WCL_SIP_WEBSOCKET_CONNECT_ERROR = -28;
export const SHARING_DESKTOP_STREAM_HAVE_NO_AUDIO = -27;
export const WCL_AUDIO_BRIDGE_RECONNECT_START = -29;
export const WCL_AUDIO_BRIDGE_RECONNECT_END = -31;
export const WEBGL_LOST_IN_MULTI_VIEW = -32;
export const MASK_SETTING_PARA_ERROR = -33;
export const VIDEO_VB_SETTING_PARA_ERROR = -34;
export const NOTIFY_UI_FAILOVER = -35;

export const AUDIO_CONNECT_HID_JOIN_FAILED = -36;
export const JOIN_COMPUTER_AUDIO_FAILURE = -37;

export const AUDIOBRIDGE_EBABLE_BROADCAST_TO_BO_FAILURE = -38;
export const AUDIOBRIDGE_SET_CC_LANG_FAILURE = -39;
export const AUDIOBRIDGE_EBABLE_SHARE_TO_BO_FAILURE = -40;

export const AUDIO_MIC_SET_ERROR = -41;

export const NOTIFY_UI_WMSC_FAILOVER = -42; // webrtc video negotiation fail, switch to wmsm mode after auto failover once time, if refresh manually, it still webrtc.
export const NOTIFY_UI_WMSC_WSS_DISCONNECTED = -43; // webrtc connection error, it is still webrtc after failover.
export const RECOVER_WEBRTC_AUDIO = -44;
export const MEDIA_RECONNECT = -45;

export const WEBGL_CONTEXT_INVALID = -51;
export const WASM_MEMORY_FAIL = -52;

export const LOST_CAMERA_ACCESS = -60;

export const WORKLET_PROCESS_EXCEPTIONS = -53;

export const VB_PROCESS_IMAGE_FAIL = -70;

export const MEDIA_HEALTH_CHECK_FAILED = -129;
export const START_ANNOTATION_FAILED = -130;

export const AUDIO_SENT_BYTES_ZERO = -136;

//Audio Status
//Audio Status
export const AUDIO_STOP = 0;
export const AUDIO_START = 1;
export const AUDIO_REMOVE = 2;
export const AUDIO_ILLEGAL = -1;
// sdk inside emitted event  subscribe/publish
export const SHARING_PARAM_INFO_FROM_SOCKET = 'SHARING_PARAM_INFO_FROM_SOCKET';

// RWG/Command Socket  message evt enums
export const ZOOM_CONNECTION_VIDEO_OFFER_EVT = 1 | 0x5000 | 0x0f00; // 24321
export const ZOOM_CONNECTION_VIDEO_OFFER_RESPONSE_EVT = 2 | 0x5000 | 0x0f00; // 24322
export const ZOOM_CONNECTION_AUDIO_OFFER_RESPONSE_EVT = 2 | 0x5000 | 0x0f00; // 24322
export const ZOOM_CONNECTION_REMOVE_UDP_EVT = 3 | 0x5000 | 0x0f00; // 24323
export const EVT_TYPE_WS_VIDEO_DATACHANNEL_ANSWER = 24322;
export const WS_CONF_AB_TOKEN_REQ = 204 | 0x1000; // 4300
export const WS_CONF_AB_TOKEN_RES = 203 | 0x1000; // 4299
export const WS_CONF_END_INDICATION = 3 | 0x1000 | 0x0f00; //7939

export const WS_VIDEO_MULTI_SUBSCRIBE_REQ = 12303; // subscribe video send to rwg from dn2
export const WS_VIDEO_MULTI_UNSUBSCRIBE_REQ = 12305; // unsubscribe video send rwg from dn2

export const RWG_MONITOR_LOG_EVENT = 4167; // Send monitor log to rwg

export const WS_CONF_WCL_SET_FULL_HD_REQ = 4355; // video full HD(1080P), only webrtc video used now.

// publish/subscribe event name
export const PUBSUB_EVT = {
  ZOOM_CONNECTION_VIDEO_OFFER_RESPONSE_EVT:
    'ZOOM_CONNECTION_VIDEO_OFFER_RESPONSE_EVT',
  ZOOM_CONNECTION_AUDIO_OFFER_RESPONSE_EVT:
    'ZOOM_CONNECTION_AUDIO_OFFER_RESPONSE_EVT',
  END_MEDIA: 'END_MEDIA',
  DESTROY: 'DESTROY',
  DC_COMING_MESSAGE: 'DC_COMING_MESSAGE',
  AUDIO_BRIDGE_WS_TOKEN: 'AUDIO_BRIDGE_WS_TOKEN',
};

export const SHARING_FIRST_DECODE_FRAME_RECEIVED_SSRC = 70;
export const MEDIA_CONNECTED = 170;

//video render Mode

//video Mode;
export const HAVE_NO_WATERMARK = false;
export const HAVE_WATERMARK = true;

export const SPEAKING_WHEN_MUTE = 121;

export const AUDIO_QOS_DATA = 'AUDIO_QOS_DATA';
export const VIDEO_QOS_DATA = 'VIDEO_QOS_DATA';
export const VIDEOSHARE_QOS_DATA = 'VIDEOSHARE_QOS_DATA';
export const NETWORK_QUALITY_CHANGE = 'NETWORK_QUALITY_CHANGE';
export const NETWORK_QUALITY_CHANGE_AUDIO = 'NETWORK_QUALITY_CHANGE_AUDIO';

//SIP Event type

//Code Type
export const sdkIvTypeKeyEnum = {
  VIDEO_ENCODE: '0',
  VIDEO_DECODE: '1',
  AUDIO_ENCODE: '2',
  AUDIO_DECODE: '3',
  SHARING_ENCODE: '4',
  SHARING_DECODE: '5',
};

export const CURRENT_DECODE_VIDEO_QUALITY = 66.5;
export const CURRENT_DECODE_VIDEO_FPS = 66.6;

export const ENABLE_REUSE_STREAM = 'ENABLE_REUSE_STREAM';
export const PRESET_MEDIA_CONSTRAINTS = 'PRESET_MEDIA_CONSTRAINTS';
export const DESTORY_REUSE_STREAM = 'DESTORY_REUSE_STREAM';

export const VB_SETTING_PARA_ERROR_TYPE = {
  FAIL: 4,
};

export const AUDIO_STREAM_MUTED = 'AUDIO_STREAM_MUTED';
export const AUDIO_STREAM_UNMUTED = 'AUDIO_STREAM_UNMUTED';
// export const AUDIO_STREAM_ABNORMAL = 'AUDIO_STREAM_ABNORMAL';

export const VIDEO_STREAM_MUTED = 'VIDEO_STREAM_MUTED';
export const VIDEO_STREAM_UNMUTED = 'VIDEO_STREAM_UNMUTED';

export const EXPOSE_VB_FRAME = 'EXPOSE_VB_FRAME';

// Unified VB event
export const UNIFIED_VB_FRAME = 'UNIFIED_VB_FRAME';
export const UNIFIED_VB_STOP = 'UNIFIED_VB_STOP';
export const UNIFIED_VB_PAUSE = 'UNIFIED_VB_PAUSE';
export const UNIFIED_VB_ACK = 'UNIFIED_VB_ACK';

// Annotation related events
export const ANNO_UNDO_STATUS = 'ANNO_UNDO_STATUS';
export const ANNO_REDO_STATUS = 'ANNO_REDO_STATUS';

export const CAPTURE_FAILED_REASON = {
  USER_DENIED: 1,
  SYSTEM_DENIED: 2,
  DEVICE_IN_USE: 3,
  NO_DEVICE: 4,
  UNKNOWN_REASON: 5,
  OVERCONSTRAINED: 6,
  USER_DISMISS: 7,
};

export const REQUEST_PERMISSION_STATUS = {
  GRANTED_AUDIO_VIDEO: 1,
  GRANTED_AUDIO: 2,
  DENIED: 3,
  EXCEPTION_FAILS: 4,
  DISMISS: 5,
};
