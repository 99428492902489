import globalTracingLogger from './globalTracingLogger';

class ABOptionsReader {
  /**
   * Read value of a bit in ABoptions.
   *
   * @param {*} options the entire ABoptions configured from devops
   * @param {number} bitIndex which bit will be read (from right to left and start from 1)
   * @param {number} [readCount=1] number of bits being read, default value is 1
   * @param {number} [defaultVal=0] default value if not this bit or get other exceptions, default is 0
   * @returns value of the ABoption bit
   */
  static read(options, bitIndex, readCount = 1, defaultVal = 0) {
    if (!options) {
      globalTracingLogger.error(
        `[ABOptionsReader] error in read(): options(${options}) is invalid`
      );
      return defaultVal;
    }

    const optionLength = options.length;

    // Validate bitIndex and readCount
    if (
      bitIndex < 0 ||
      readCount <= 0 ||
      bitIndex > optionLength ||
      bitIndex + readCount - 1 > optionLength
    ) {
      globalTracingLogger.error(
        `[ABOptionsReader] error in read(): invalid parameters! opLen=${optionLength}, bitIndex=${bitIndex}, readCount=${readCount}`
      );
      return defaultVal;
    }

    // Calculate slice positions
    const start = optionLength - bitIndex - readCount + 1;
    const end = optionLength - bitIndex + 1;

    try {
      // Extract and convert the substring
      const optionsVal = options.slice(start, end);
      const bitsVal = parseInt(optionsVal, 16);

      // Return parsed value or defaultVal if NaN
      return isNaN(bitsVal) ? defaultVal : bitsVal;
    } catch (e) {
      globalTracingLogger.error('[ABOptionsReader] error in read()', e);
    }

    return defaultVal;
  }

  /**
   * Static method to read multiple bit segments from an options string.
   *
   * This method processes a bundle of bit read requests and returns a Map
   * where each key is the bitIndex, and the corresponding value is the result of
   * reading a specified number of bits starting from that bitIndex.
   *
   * @param {string} options - A string of hexadecimal characters representing the options.
   * @param {Array} bitBundle - An array of objects where each object specifies:
   *                            - `bitIndex` (Number): The starting bit index to read from.
   *                            - `readCount` (Number): The number of bits to read (default is 1).
   *                            - `defaultVal` (Number): The default value to return if read fails (default is 0).
   * @returns {Map} A Map where the keys are `bitIndex` values and the corresponding values are
   *                the results of reading `readCount` bits starting from each `bitIndex`.
   *                If reading fails for any item, the corresponding `defaultVal` is returned.
   */
  static batchRead(options, bitBundle) {
    const mapping = new Map();

    for (let i = 0; i < bitBundle.length; i++) {
      const { bitIndex, readCount = 1, defaultVal = 0 } = bitBundle[i];
      const result = this.read(options, bitIndex, readCount, defaultVal);
      mapping.set(bitIndex, result);
    }

    return mapping;
  }
}

export default ABOptionsReader;
