import IsHeadset from '../common/audio-headset';
import * as jsEvent from '../common/jsEvent';
import util from '../common/util';
import jsMediaEngineVariables from './JsMediaEngine_Variables';
import * as jsMediaEngine from '../lib/JsMediaEngine';
import { GlobalTracingLogger } from '../common/globalTracingLogger';

/*
ostype:typedef enum{
	A_UNKNOWN_CLI = 0,
    A_WIN_CLI,
    A_MAC_CLI,
	  A_PAD_CLI,
    A_MOBILE_CLI,
    A_CALL_IN_CLI,
    A_LINUX_CLI,
    A_WEB_CLI,

    // dylan.ye : vdi client flag
    A_VDI_RESERVE_BG = VDI_CLT_BASE,
    A_VDI_RESERVE_ED = VDI_CLT_BASE_END-1,
}CmmClientOSType;
*/
const A_WEB_CLI = 7;
//number of mic in capture device
const NUM_OF_DEVICE = 1;

// webclient can't get sessionState/colorFormat/FECState
const SESSIONSTATE = -1;
const COLORFORMAT = -1;
const FECCSTATE = -1;

const regex = /\(\w+:\w+\)|,/gi;

function sortFunc(a, b) {
  // sort before comparing
  let rv =
    a.kind === b.kind
      ? a.deviceId === b.deviceId
        ? 0
        : a.deviceId > b.deviceId
        ? 1
        : -1
      : a.kind > b.kind
      ? 1
      : -1;
  return rv;
}
function DeviceManager() {
  this._deviceList = null;
  this._deviceDetectTimer = null;
  this.addChangeListener();
  this.enumDevices();
}

DeviceManager.prototype.init = function () {
  this.micId = null;
  this.micLabel = null;
  this.speakerId = null;
  this.speakerLabel = null;
  this.cameraId = null;
  this.cameraLabel = null;
  this.startTime = Date.now();
  this.userId = 0;
  this.videoWidth = 0;
  this.videoHeight = 0;
  this.audioBridge = false;
  this.isUpdatingDevice = false;
  this.denoiseSwitch = false;
};

DeviceManager.prototype.isSupportDeviceChange = function () {
  // android not support device change https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/devicechange_event#browser_compatibility
  return util.isAndroidBrowser() && !util.browser.isFirefox ? false : true;
};

DeviceManager.prototype.addChangeListener = function () {
  if (this.isSupportDeviceChange()) {
    navigator.mediaDevices?.removeEventListener?.(
      'devicechange',
      this.enumDevices.bind(this)
    );
    navigator.mediaDevices?.addEventListener?.(
      'devicechange',
      this.enumDevices.bind(this)
    );
  } else {
    this.enableSelfDeviceDetect();
  }

  if (this.checkPlatformNeedExtraSelfDetection()) this.enableSelfDeviceDetect();
};

DeviceManager.prototype.checkPlatformNeedExtraSelfDetection = function () {
  // iPadOS plug-out 3.5mm cannot get onchange event while using bluetooth mic capturing, mannually enumerate as a workaround ZOOM-843141
  // iPhone airpods disconenct cannot get onchange event until enumerate interface being called
  // Linux bluetooth cannot get onchange event ZOOM-850360 (Microphone need system selection to detect)
  return util.isIphoneOrIpadSafari() || util.isLinux();
};

DeviceManager.prototype.enableSelfDeviceDetect = function () {
  // detect by timer
  this.disableSelfDeviceDetect();
  const CHECK_TIMER_INTERVAL = 3000; // 3 secs
  this._deviceDetectTimer = setInterval(
    this.enumDevices.bind(this),
    CHECK_TIMER_INTERVAL
  );
};

DeviceManager.prototype.disableSelfDeviceDetect = function () {
  this._deviceDetectTimer && clearInterval(this._deviceDetectTimer);
};

// AB option flag for disable extra self detection on production
DeviceManager.prototype.receiveABOptionForExtraDeviceDetect = function (
  enable
) {
  if (enable == false) {
    if (!this.isSupportDeviceChange()) return; // always enable if not support onchange at all

    if (this.checkPlatformNeedExtraSelfDetection())
      this.disableSelfDeviceDetect();
  }
};

DeviceManager.prototype.isDeviceChanged = function (newlist) {
  // mannually compare device change.
  let oldlist = this._deviceList;
  if (oldlist === null) return true;
  if (newlist.length != oldlist.length) return true;

  oldlist.sort(sortFunc);
  newlist.sort(sortFunc);
  let i = 0,
    j = 0;
  while (true) {
    if (i >= newlist.length || j >= oldlist.length) {
      break;
    }
    if (oldlist[j].deviceId == newlist[i].deviceId) {
      i++;
      j++;
      continue;
    } else {
      return true;
    }
  }
  return false;
};

DeviceManager.prototype.enumDevices = function (changeEvent = null) {
  return navigator?.mediaDevices
    ?.enumerateDevices?.()
    .then((devices) => {
      if (this.isSupportDeviceChange() && changeEvent) {
        // 1. ondevicechange called by browser
        jsMediaEngineVariables.Notify_APPUI_SAFE(
          jsEvent.DEVICE_CHANGE_EVENT,
          devices
        );
        this.reportAudioAllMonitorLogs(devices);
      } else if (this._deviceList == null) {
        // 2. first time enumerate
        this.reportAudioAllMonitorLogs(devices);
      } else if (this.isDeviceChanged(devices)) {
        // 3. device change detect by enumerate
        jsMediaEngineVariables.Notify_APPUI_SAFE(
          jsEvent.DEVICE_CHANGE_EVENT,
          devices
        );
        this.reportAudioAllMonitorLogs(devices);
      }

      this._deviceList = devices; // 4. put assignment at final
    })
    .catch((err) => {
      GlobalTracingLogger.error(
        'MediaSDK catched error while enumerateDevice: ',
        err
      );
    });
};

DeviceManager.prototype.reportAudioAllMonitorLogs = function (devices) {
  if (!devices) return;
  devices.forEach((device) => {
    if (device.kind == 'audioinput') {
      this.sendDeviceInfo(
        -1,
        1,
        this.getIndex(device.label),
        device.deviceId,
        device.label?.replace(regex, '')?.trim(),
        true
      );
    } else if (device.kind == 'audiooutput') {
      this.sendDeviceInfo(
        -1,
        0,
        this.getIndex(device.label),
        device.deviceId,
        device.label?.replace(regex, '')?.trim(),
        true
      );
    }
  });
};

DeviceManager.prototype.notifyDenoiseSetting = function () {
  if (!util.isSupportAudioDenoise(!!this.audioBridge) || !this.micId) return;
  if (this.audioBridge) {
    this.audioBridge.changeDenoiseSwitch(
      this.denoiseSwitch,
      deviceManager.isHeadSet()
    );
  } else {
    let data = {
      command: 'audio_denoise_switch',
      switch: this.denoiseSwitch,
      isHeadSet: deviceManager.isHeadSet(),
    };
    jsMediaEngine.Notify_Audio_Encode_Thread(data);
  }
};

DeviceManager.prototype.hasOutputDevice = async function (playDevice) {
  if (playDevice === '') return true;
  let deviceLists = await navigator.mediaDevices.enumerateDevices();
  let outputLists = deviceLists.filter(
    (device) => device.kind === 'audiooutput'
  );

  if (outputLists.length === 0) return false;
  let outputDeviceExists = outputLists.filter(
    (device) => device.deviceId === playDevice
  );
  //if use default device, device list doesn't exist ''
  return outputDeviceExists.length;
};

DeviceManager.prototype.changeDenoiseSwitch = function (flag) {
  this.denoiseSwitch = flag;
  this.notifyDenoiseSetting();
};

DeviceManager.prototype.setAudioBridge = function (audioBridge) {
  this.audioBridge = audioBridge;
};

DeviceManager.prototype.hasPermission = async function (permission) {
  try {
    return (
      (await navigator.permissions.query({ name: permission })).state ===
      'granted'
    );
  } catch (e) {
    let kind = null;
    if (permission === 'microphone') {
      kind = 'audioinput';
    } else if (permission === 'camera') {
      kind = 'videoinput';
    } else {
      return false;
    }
    let devices = await navigator.mediaDevices.enumerateDevices();
    devices.forEach((device) => {
      if (device.kind === kind && device.label === '') return false;
    });
    return true;
  }
};

DeviceManager.prototype.setUserId = function (id) {
  if (typeof id !== 'number' || Number.isNaN(id)) return;

  const isSameNodeId = (this.userId >> 10) << 10 == (id >> 10) << 10;
  this.userId = id;
  // monitor logs required user id
  if (!isSameNodeId) this.reportAudioAllMonitorLogs(this._deviceList);
};

DeviceManager.prototype.updateSelectedMicDevices = function (
  deviceId,
  deviceLabel,
  elapsed_time,
  success
) {
  deviceLabel = deviceLabel?.replace(regex, '')?.trim();

  if (success) {
    this.micId = deviceId;
    this.micLabel = deviceLabel;
  }

  this.notifyDenoiseSetting();
  this.sendDeviceInfo(
    elapsed_time,
    1,
    this.getIndex(this.micLabel),
    deviceId,
    deviceLabel,
    success,
    this.isHeadSet()
  );
};

DeviceManager.prototype.updateShareAudioDevices = function (
  deviceId,
  deviceLabel
) {
  this.sendDeviceInfo(0, 2, 0, deviceId, deviceLabel, true, false);
};

DeviceManager.prototype.updateSelectedSpeakerDevices = async function (
  deviceId,
  elapsed_time,
  success
) {
  deviceId = deviceId || 'default';
  let deviceLabel = 'default';
  let deviceList = await navigator.mediaDevices.enumerateDevices();
  deviceList.forEach((device) => {
    if (device.kind === 'audiooutput' && device.deviceId === deviceId) {
      deviceLabel = device.label?.replace(regex, '')?.trim();
    }
  });

  if (success) {
    this.speakerId = deviceId;
    this.speakerLabel = deviceLabel;
  }

  this.sendDeviceInfo(
    elapsed_time,
    0,
    this.getIndex(this.speakerLabel),
    deviceId,
    deviceLabel,
    success,
    this.isHeadSet(false)
  );
};

DeviceManager.prototype.updateSelectedCameraDevices = function (
  deviceId,
  deviceLabel,
  width,
  height,
  frameRate,
  elapsed_time,
  success
) {
  this.cameraId = deviceId;
  this.cameraLabel = deviceLabel?.replace(regex, '')?.trim();
  this.videoWidth = width;
  this.videoHeight = height;
  this.videoFrameRate = frameRate;
  this.sendCameraInfo(elapsed_time, success, frameRate);
};

DeviceManager.prototype.isHeadSet = function (isMic = true) {
  let deviceLabel = null;
  if (isMic) {
    deviceLabel = this.micLabel;
  } else {
    deviceLabel = this.speakerLabel;
  }
  return IsHeadset(deviceLabel);
};

// 0: selected device
// -1 as index (<=> Default Communication Device)
// -2 Default endpoint device will be used

DeviceManager.prototype.getIndex = function (deviceLabel) {
  if (deviceLabel == 'default') return -1;
  else if (deviceLabel == 'communications') {
    if (util.isMac()) return 0;
    return -2;
  } else return 0;
};

//type: 0/1 is speaker/mic
DeviceManager.prototype.sendDeviceInfo = function (
  elapsed_time,
  type,
  index,
  deviceId,
  deviceLabel,
  success,
  isHeadSet
) {
  if (!this.userId) return;
  let log = null;
  let threadRunTime = Date.now() - this.startTime;
  if (elapsed_time == -1) {
    log =
      'WCL_AUDIOD-ALL,' +
      this.userId +
      ',' +
      type +
      ',' +
      index +
      ',' +
      deviceLabel +
      ',' +
      deviceId +
      ',' +
      success +
      ',' +
      elapsed_time +
      ',' +
      A_WEB_CLI +
      ',' +
      threadRunTime +
      ',' +
      NUM_OF_DEVICE;
  } else {
    log =
      'WCL_AUDIOD,' +
      this.userId +
      ',' +
      type +
      ',' +
      index +
      ',' +
      deviceLabel +
      ',' +
      deviceId +
      ',' +
      success +
      ',' +
      elapsed_time +
      ',' +
      A_WEB_CLI +
      ',' +
      threadRunTime +
      ',' +
      NUM_OF_DEVICE +
      ',' +
      (success ? 1 : 0) +
      ',' +
      isHeadSet;
  }
  jsMediaEngineVariables.sendMessageToRwg(jsEvent.MONITOR_LOG, {
    evt: jsEvent.RWG_MONITOR_LOG_EVENT,
    seq: 1,
    body: {
      data: log,
    },
  });
};

DeviceManager.prototype.sendCameraInfo = function (elapsed_time, success, fps) {
  if (!this.userId || this.isUpdatingDevice) return;
  let log = null;
  log =
    'WCL_CAMERA,' +
    this.userId +
    ',0,' +
    this.cameraLabel +
    ',' +
    success +
    ',' +
    elapsed_time +
    ',' +
    SESSIONSTATE +
    ',' +
    this.videoWidth +
    ',' +
    this.videoHeight +
    ',' +
    COLORFORMAT +
    ',' +
    fps +
    ',' +
    FECCSTATE;
  jsMediaEngineVariables.sendMessageToRwg(jsEvent.MONITOR_LOG, {
    evt: jsEvent.RWG_MONITOR_LOG_EVENT,
    seq: 1,
    body: {
      data: log,
    },
  });
};

DeviceManager.prototype.getMicId = function () {
  return this.micId;
};

DeviceManager.prototype.getMicLabel = function () {
  return this.micLabel;
};

let deviceManager = new DeviceManager();

export default deviceManager;

//
// if (elapsed_time == -1)
//    {
//        device_stream << "AUDIOD-ALL,"<< m_self_id << "," << type<<","<<index<<","
//                         << des.p_name<<","<<des.p_unique_id << "," << success << ","
//                         << elapsed_time << "," << m_machine_info.os_type<<","<<tick_fix_t::now()<<","<<des.num_of_devices;
//    }
//    else
//    {
//        bool isHeadset = false;
//        if (index != -3) get_device_property(type,-3,SSB_MC_AUDIO_DEVICE_PROPERTY_HEADSET,&isHeadset,sizeof(bool));
//        device_stream << "AUDIOD," << m_self_id << "," << type<<","<<index<<","<< des.p_name<<","<<des.p_unique_id << ","
//                         << success << "," << elapsed_time << "," << m_machine_info.os_type<<","<<tick_fix_t::now()<<","
//                         <<des.num_of_devices << "," << result << "," << isHeadset;
//    }
