import * as RenderConst from './RenderConst';
import {
  VIDEO_INVALID,
  VIDEO_BGRA,
  VIDEO_RGBA,
  VIDEO_I420,
  VIDEO_NV12,
} from '../../worker/common/consts';
import TextureLayer from './TextureLayer';
import { add_monitor, globaltracing_error } from '../../worker/common/common';
import {
  isDimensionsOverMaxDimension2DSize,
  calculateUVCoord,
  calculateUVCoordsForMultiView,
  calculateRenderRect,
  evalCroppingRect,
} from './GPURenderUtils';

/**
 * Each area that is able to be rendered is defined as a render display.
 * A render display is made of one or several texture layers. A render display
 * is the biggest unit to be managed on a canvas.
 */
class WebGPURenderDisplay {
  #index = 0;
  #textureIndex = 0;
  #croppingParams = {};
  #textureWidth = 0;
  #textureHeight = 0;
  #canvasWidth = 0;
  #canvasHeight = 0;
  #picRotation = -1;
  #rotation = RenderConst.ROTATION_CLOCK_0;
  #videoMode = VIDEO_INVALID;
  #fillMode = 0;
  #fillModeForResolution = 0;
  #initMask = false;
  #renderer = null;
  #canvas = null;
  #isMultiView = false;
  #hasWatermark = 0;
  #watermarkRepeated = false;
  #watermarkOpacity = 0;
  #watermarkPosition = 0;
  #watermarkWidth = 0;
  #watermarkHeight = 0;
  #mRenderingState = RenderConst.RENDERING_STATE.IDLE;
  #hasWholeFrame = 0;
  #cursorWidth = 0;
  #cursorHeight = 0;
  #hasCursor = false;
  #mSsrc = 0;
  #mResMgr = null;

  /* uniforms */
  #onlyRGBA = 0;
  #yuvMode = VIDEO_INVALID;
  #colorRange = -1;
  #watermarkFlag = 0;
  #bgraModeFlag = 0;
  #cursorFlag = 0;
  #maskFlag = 0;
  #cursorInfo = null;
  #texLayerUniformsMap = new Map();

  // webgpu rendering data
  #textureLayersMap = new Map();
  #uvCoordinatesHolder = { top: 0, left: 0, bottom: 0, right: 0 };
  reuse = false;

  constructor(index, resMgr) {
    this.#index = index;
    this.#mResMgr = resMgr;
    this.#textureIndex = index ? index : 0;
    this.#croppingParams.top = 0;
    this.#croppingParams.left = 0;
    this.#croppingParams.width = 0;
    this.#croppingParams.height = 0;
    this.#textureWidth = 0;
    this.#textureHeight = 0;
    this.#canvasWidth = 0;
    this.#canvasHeight = 0;
    this.#videoMode = VIDEO_INVALID;
    this.#fillMode = 0;
    this.#fillModeForResolution = 0;
    this.markRenderingStateIdle();
  }

  addRenderer(webgpuRenderer) {
    this.#renderer = webgpuRenderer;
  }

  removeRenderer() {
    this.#renderer = null;
  }

  setGPUResMgr(resMgr) {
    this.#mResMgr = resMgr;
  }

  removeGPUResMgr() {
    this.#mResMgr = null;
  }

  attachCanvas(canvas) {
    this.#canvas = canvas;
  }

  getAttachedCanvas() {
    return this.#canvas;
  }

  detachCanvas() {
    this.#canvas = null;
  }

  bindSsrc(ssrc) {
    this.#mSsrc = ssrc;
  }

  unbindSsrc() {
    this.#mSsrc = 0;
  }

  /**
   * Set rendering parameters of drawing self video before renderer rendering.
   * Like viewport, uniforms, vertex, uv coordinates, etc.
   *
   * @param {*} viewportParams basic viewport parameters, rendering at which area
   * @param {*} isClearSelf if true, the rotation of vertex is 0 degree, default is false
   * @param {*} isMirror if true, mirroring the video, otherwise false
   */
  drawSelfVideo(viewportParams, isClearSelf = false, isMirror = false) {
    if (!this.#isValidViewportParameter(viewportParams)) {
      return;
    }

    this.#checkRendererAttached();
    this.#setUniformsFlag(1, this.#hasCursor, this.#videoMode);

    let renderingRect = null;
    if (!isClearSelf) {
      renderingRect = this.#evalRenderingRect(
        viewportParams.width,
        viewportParams.height,
        this.#croppingParams.width,
        this.#croppingParams.height,
        this.#rotation
      );
    } else {
      renderingRect = this.#evalRenderingRect(
        viewportParams.width,
        viewportParams.height,
        viewportParams.width,
        viewportParams.height,
        RenderConst.ROTATION_CLOCK_0
      );
    }

    this.#drawVideoFrameBaseTextureLayer(
      viewportParams,
      renderingRect,
      isMirror
    );
  }

  /**
   * Set rendering parameters of drawing remote video before renderer rendering.
   *
   * @param {*} viewportParams basic viewport parameters, rendering at which area
   * @param {*} isMirror if true, mirroring the video, otherwise false
   */
  drawRemoteVideo(viewportParams, isMirror = false) {
    if (!this.#canvas) {
      return;
    }

    if (!this.#isValidViewportParameter(viewportParams)) {
      return;
    }

    this.#checkRendererAttached();
    const rgbaMode = this.isRgbaMode(this.#videoMode) ? 1 : 0;
    this.#setUniformsFlag(rgbaMode, this.#hasCursor, this.#videoMode);
    const renderingRect = this.#evalRenderingRect(
      viewportParams.width,
      viewportParams.height,
      this.#croppingParams.width,
      this.#croppingParams.height,
      this.#rotation
    );
    this.#drawYuvBaseTextureLayer(viewportParams, renderingRect, isMirror);
  }

  /**
   * Set rendering parameters of drawing cursor above the sharing texture layer.
   *
   * @param {*} withCursor a bool indicates that whether has a cursor to draw
   * @param {*} cursorX x coord of the cursor position
   * @param {*} cursorY y coord of the cursor position
   * @param {*} cursorLogicWidth width of the cursor
   * @param {*} cursorLogicHeight height of the cursor
   */
  drawCursor(
    withCursor,
    cursorX,
    cursorY,
    cursorLogicWidth,
    cursorLogicHeight
  ) {
    if (
      !this.#hasWholeFrame ||
      (withCursor && (cursorLogicWidth < 0 || cursorLogicHeight < 0))
    ) {
      return;
    }

    const zIndex = RenderConst.TEX_LAYER_Z_IDX.CURSOR;
    const texLayer = this.#getZIndexTexLayer(zIndex);

    // the cursor tex layer relies on its base tex layer, basically, a sharing texture layer.
    // we need to use the uv coordinates from its base texture layer as its own uv coordinates to sample
    const baseTexLayer = this.#getZIndexTexLayer(
      RenderConst.TEX_LAYER_Z_IDX.VS_BASE
    );
    texLayer.setUVCoords(baseTexLayer.getUVCoords());

    // update viewport if needed
    texLayer.evalViewport(
      cursorX,
      cursorY,
      cursorLogicWidth,
      cursorLogicHeight,
      this.#canvas.height
    );

    if (withCursor && this.#hasCursor) {
      const cx = cursorX / this.#croppingParams.width;
      const cy = cursorY / this.#croppingParams.height;
      const cw = cursorLogicWidth / this.#croppingParams.width;
      const ch = cursorLogicHeight / this.#croppingParams.height;

      const cursorInfo = {
        x: cx,
        y: cy,
        w: cw,
        h: ch,
      };

      this.#cursorInfo = cursorInfo;
    } else {
      const cursorInfo = {
        x: 0,
        y: 0,
        w: 0,
        h: 0,
      };

      this.#cursorInfo = cursorInfo;
    }

    // update uniforms
    const uniforms = this.#updateCursorTexLayerUniforms();
    if (uniforms && uniforms.buffer) {
      texLayer.setUniformBuffer(uniforms.buffer);
    }
  }

  setMultiView(isMultiView) {
    this.#isMultiView = isMultiView;
  }

  setFillMode(fillMode = 0, fillModeForResolution = 0) {
    this.#fillMode = fillMode;
    this.#fillModeForResolution = fillModeForResolution;
  }

  getFillMode() {
    return this.#fillMode;
  }

  setColorRange(colorRange) {
    this.#colorRange = colorRange;
  }

  getFillModeForResolution() {
    return this.#fillModeForResolution;
  }

  getTextureIndex() {
    return this.#textureIndex;
  }

  isUseFillMode({ w, h, rotation }) {
    if (!this.#fillMode) return false;
    if (!this.#fillModeForResolution) return true;
    if (!w || !h) return false;

    const curResolution =
      rotation === RenderConst.ROTATION_CLOCK_90 ||
      rotation === RenderConst.ROTATION_CLOCK_90
        ? h / w
        : w / h;

    return (
      Array.isArray(this.#fillModeForResolution)
        ? this.#fillModeForResolution
        : [this.#fillModeForResolution]
    ).some((resolution) => Math.abs(curResolution - resolution) < 0.01);
  }

  setVideoMode(mode) {
    this.#videoMode = mode;
  }

  getVideoMode() {
    return this.#videoMode;
  }

  setWatermarkFlag(flag) {
    this.#hasWatermark = flag;
    if (!flag) {
      this.setWatermarkRepeated(false);
      this.setWatermarkOpacity();
      this.setWatermarkPosition(16);
    }
  }

  setWatermarkRepeated(watermarkRepeated) {
    this.#watermarkRepeated = watermarkRepeated;
  }

  isWatermarkRepeated() {
    return !!this.#watermarkRepeated;
  }

  setWatermarkOpacity(watermarkOpacity) {
    this.#watermarkOpacity = watermarkOpacity || 0.15;
  }

  getWatermarkOpacity() {
    return this.#watermarkOpacity;
  }

  setWatermarkPosition(watermarkPosition) {
    this.#watermarkPosition = watermarkPosition || 16;
  }

  getWatermarkPosition() {
    return this.#watermarkPosition;
  }

  isSetWatermark() {
    return this.#hasWatermark;
  }

  isRgbaMode(mode) {
    return [VIDEO_RGBA, VIDEO_BGRA].indexOf(mode) !== -1;
  }

  getTextureWidth() {
    return this.#textureWidth;
  }

  getTextureHeight() {
    return this.#textureHeight;
  }

  getCroppingParams() {
    return this.#croppingParams;
  }

  recoverTextures() {}

  /**
   * Update the video frame data or rgba data to the base texture layer.
   *
   * @param {*} width the width of the frame
   * @param {*} height the height of the frame
   * @param {*} data a VideoFrame data or rgba raw data
   * @param {boolean} [isRgbaMode=false] whether current mode is RGBA mode
   */
  #updateVideoFrameBaseTextureLayer(width, height, data, isRgbaMode = false) {
    this.#checkRendererAttached();

    // if GPUDevice is not ready, if data is a VideoFrame, it should
    // be closed and dropped because next round of rendering can't be executed
    // due to the uninitialized GPUDevice.
    if (
      !this.#renderer ||
      !this.#canvas ||
      !this.#renderer.isGPUDeviceReady()
    ) {
      if (data instanceof VideoFrame) {
        data.close();
      }
      return;
    }

    // get video texture layer as the base layer first.
    // Next, update data fields to it
    const zIndex = RenderConst.TEX_LAYER_Z_IDX.VS_BASE;
    const texLayer = this.#getZIndexTexLayer(zIndex);
    const isLocked = texLayer.isLocked();
    if (!isLocked) {
      if (data instanceof VideoFrame) {
        // a video frame instance
        const currentVideoFrame = texLayer.getPendingVideoFrame();
        if (currentVideoFrame != data) {
          texLayer.setPendingVideoFrame(data);
        }
      } else {
        // maybe an typed array of rgba data
        // if texture layer is not locked, means previous round of rendering is finished
        // the new video frame is able to be cached again
        // so close the previous cached video frame if any,
        // then cache the new video frame to texture layer.
        if (isRgbaMode) {
          this.#updateRgbaBaseTexLayer(texLayer, width, height, data);
        }

        globaltracing_error(
          `updateVideoFrameBaseTextureLayer() an unexpected case!`
        );
        console.error(`updateVideoFrameBaseTextureLayer() an unexpected case!`);
      }

      texLayer.setTextureLayerType(RenderConst.TEX_LAYER_TYPE.BASE_LAYER);
      texLayer.setTextureType(RenderConst.TEX_TYPE.EXTERNAL_TEX);
      texLayer.lock();
    } else {
      // if current texture layer is locked now, but next data size is changed,
      // the pending video frame should be closed and save it again.
      const pendingVideoFrame = texLayer.getPendingVideoFrame();
      if (
        pendingVideoFrame &&
        (pendingVideoFrame.codedWidth != width ||
          pendingVideoFrame.codedHeight != height)
      ) {
        if (data instanceof VideoFrame) {
          // a video frame instance
          texLayer.setPendingVideoFrame(data);
        } else {
          // maybe an typed array of rgba data
          // if texture layer is not locked, means previous round of rendering is finished
          // the new video frame is able to cached again
          // so close the previous cached video frame if have,
          // then cache the new video frame to texture layer.
          if (isRgbaMode) {
            this.#updateRgbaBaseTexLayer(texLayer, width, height, data);
          }

          globaltracing_error(
            `updateVideoFrameBaseTextureLayer() an unexpected case!`
          );
          console.error(
            `updateVideoFrameBaseTextureLayer() an unexpected case!`
          );
        }
      } else {
        if (data instanceof VideoFrame) {
          data.close();
        }
      }
    }

    this.markRenderingStateReady();
  }

  /**
   * Write the raw YUV data as the remote video or sharing content to the GPUBuffers
   * and cache the GPUBuffers to the texture layer for the next round of rendering.
   *
   * @param {*} index the index of which render display
   * @param {*} width width of the rendering data
   * @param {*} height height of the rendering data
   * @param {*} data raw data
   * @param {*} texBufferGroup null or a cached buffer group
   * @param {*} videoMode the format of the data
   */
  #updateYuvBaseTexLayerBufferGroup(
    index,
    width,
    height,
    data,
    texBufferGroup,
    videoMode
  ) {
    this.#checkRendererAttached();

    const zIndex = RenderConst.TEX_LAYER_Z_IDX.VS_BASE;
    const texLayer = this.#getZIndexTexLayer(zIndex);
    texLayer.setTextureLayerType(RenderConst.TEX_LAYER_TYPE.BASE_LAYER);
    texLayer.setTextureType(RenderConst.TEX_TYPE.GPU_TEX_YUV);

    // if the based texture layer was used to draw a video frame, but it is used to draw
    // a yuv texture, the last cached pending video frame is not closed.
    // it might lead to a capturing issue, the capturing will get stuck
    texLayer.clearPendingVideoFrame();

    /*
      if the texture layer is not locked, means the texture layer is just created right now
      or the previous round of rendering is just finished.
      
      The state of texture layer lock means whether the pending raw data is to save or drop,
      
      - if locked, the pending raw data will be dropped but this action needs the unchanged width or height.
      the changed width or height should notify the texture layer to destroy the candidate GPUBuffers 
      and then create them for the next round of rendering.

      - if unlocked, create a new group of GPUBuffers and save it to the texture layer directly for the next round
      of rendering, lock the texture layer finally.
     */
    if (!texLayer.isLocked()) {
      texLayer.setWidth(width);
      texLayer.setHeight(height);
      texLayer.setIsNew(true);
      texLayer.lock();
    } else {
      const _w = texLayer.getWidth();
      const _h = texLayer.getHeight();
      if (_w != width || _h != height) {
        texLayer.setWidth(width);
        texLayer.setHeight(height);
        texLayer.setIsNew(true);
      }
    }

    const yuvData = this.#getYuvTexBuffers(data, width, height, videoMode);
    const _texBufferGroup = this.#renderer.writeToYuvTexturesBufferGroup(
      yuvData,
      texBufferGroup
    );
    texLayer.setTextureBufferGroup(_texBufferGroup);
  }

  #updateRgbaBaseTexLayer(texLayer, width, height, data) {
    const bufferConfig = this.#evalRgbaTexBufferConfig(width, height);
    bufferConfig.label = `RgbaTexBuffer(${texLayer.getIndex()})-${
      bufferConfig.size
    }`;

    // use the cached texture buffer or request a new buffer
    let texBufferGroup = texLayer.getTextureBufferGroup();
    texBufferGroup = this.#updateTextureBufferGroup(
      texLayer,
      texBufferGroup,
      bufferConfig
    );

    if (!texBufferGroup || !texBufferGroup.buffer) {
      console.warn(
        `[updateRgbaBaseTexLayer()] texLayer(${texLayer.getIndex()}) cannot apply a GPU buffer!`
      );
      this.markRenderingStatePending();
      return;
    }

    this.#updateRgbaTexLayerBuffer(
      this.#index,
      width,
      height,
      data,
      texBufferGroup
    );

    this.markRenderingStateReady();
  }

  /**
   * Set the rendering parameters of drawing the watermark and update the watermark data to the texture layer.
   *
   * @param {*} width width of the watermark rendering data
   * @param {*} height height of the watermark rendering data
   * @param {*} data watermark raw data, type of rgba
   */
  updateWatermark(width, height, data) {
    // watermark texture is a blend texture layer and it will be rendered on the top of other blend layers
    const zIndex = RenderConst.TEX_LAYER_Z_IDX.WATERMARK;
    const texLayer = this.#getZIndexTexLayer(zIndex);

    if (!this.#canvas || !this.#renderer) {
      this.#clearTexLayerResources();
      this.markRenderingStatePending();
      return;
    }

    if (
      width <= 0 ||
      height <= 0 ||
      !data ||
      data.length != width * height * 4
    ) {
      this.#mResMgr.recycleTextureBufferGroup(texLayer);
      this.markRenderingStatePending();
      return;
    }

    if (isDimensionsOverMaxDimension2DSize(this.#mResMgr, width, height)) {
      this.#mResMgr.recycleTextureBufferGroup(texLayer);
      this.markRenderingStatePending();
      return;
    }

    this.#watermarkWidth = width;
    this.#watermarkHeight = height;
    this.#hasWatermark = 1;
    this.#watermarkFlag = 1;

    // as other blend texture layers, watermark texture layer still depends on its base texture layer
    // sometimes, however, updateWatermark is called before its base texture layer created. We need to
    // save the watermark raw data to the field `rawData` of the texture layer.
    // this api will be called again to check whether its base layer is created every time calling render() before,
    // once its base texture layer is ready, it's time to write the raw watermark data to the GPUBuffer.
    if (!this.#hasZIndexTexLayer(RenderConst.TEX_LAYER_Z_IDX.VS_BASE)) {
      console.log(
        `[updateWatermark] base layer is not ready, set data to the texture layer for creating texture later.`
      );

      this.#mResMgr.recycleTextureBufferGroup(texLayer);
      this.markRenderingStatePending();

      const rawData = {
        index: this.#index,
        width: width,
        height: height,
        data: data,
      };

      texLayer.setRawData(rawData);
      return;
    }

    const baseTexLayer = this.#getZIndexTexLayer(
      RenderConst.TEX_LAYER_Z_IDX.VS_BASE
    );

    const baseLayerViewport = baseTexLayer.getViewport();
    if (!baseLayerViewport) {
      console.log(
        `[updateWatermark] base layer's viewport is not ready, set data to the texture layer for creating texture later.`
      );

      this.#mResMgr.recycleTextureBufferGroup(texLayer);
      this.markRenderingStatePending();

      const rawData = {
        index: this.#index,
        width: width,
        height: height,
        data: data,
      };

      texLayer.setRawData(rawData);
      return;
    }

    try {
      // calculate the buffer size that will be used later first
      const bufferConfig = this.#evalRgbaTexBufferConfig(width, height);
      bufferConfig.label = `WatermarkTexBuffer(${texLayer.getIndex()})-${
        bufferConfig.size
      }`;

      // use the cached texture buffer or request a new buffer
      let texBufferGroup = texLayer.getTextureBufferGroup();
      texBufferGroup = this.#updateTextureBufferGroup(
        texLayer,
        texBufferGroup,
        bufferConfig
      );

      if (!texBufferGroup || !texBufferGroup.buffer) {
        console.warn(
          `[updateWatermark()] texLayer(${texLayer.getIndex()}) cannot apply a GPU buffer!`
        );
        this.markRenderingStatePending();
        return;
      }

      // set label to buffer
      // texBufferGroup.buffer.label = `WatermarkTexBuffer(${texLayer.getIndex()})-${
      //   bufferConfig.size
      // }`;

      this.#updateWatermarkTexLayerBuffer(
        this.#index,
        width,
        height,
        data,
        texBufferGroup
      );

      // 2. set viewport for watermark texture layer
      if (baseLayerViewport) {
        texLayer.setViewport(baseLayerViewport);
      }

      // 3. update uv coordinates for watermark texture layer
      // it is same as the base texture layer currently
      let uvCoordsArray = texLayer.getUVCoords();
      let uvCoords = this.#evalWatermarkUVCoords();
      if (!uvCoordsArray) {
        uvCoordsArray = new Float32Array(12);
      }
      uvCoordsArray.set(uvCoords, 0);
      texLayer.setUVCoords(uvCoordsArray);

      // clear the raw data
      texLayer.setRawData(null);
      this.markRenderingStateReady();
    } catch (error) {
      console.error(
        `[WebGPURenderDisplay] updateWatermark() error:${error.message}`
      );

      add_monitor(
        `WGPU WebGPURenderDisplay_updateWatermark() error:${error.message}`
      );

      this.#mResMgr.recycleTextureBufferGroup(texLayer);
      this.markRenderingStatePending();
    }
  }

  updateCursor(width, height, data) {
    const zIndex = RenderConst.TEX_LAYER_Z_IDX.CURSOR;
    const texLayer = this.#getZIndexTexLayer(zIndex);

    if (!this.#canvas || !this.#renderer) {
      this.#clearTexLayerResources();
      this.markRenderingStatePending();
      return;
    }

    if (
      width <= 0 ||
      height <= 0 ||
      !data ||
      data.length != width * height * 4
    ) {
      this.#mResMgr.recycleTextureBufferGroup(texLayer);
      this.markRenderingStatePending();
      return;
    }

    this.#cursorWidth = width;
    this.#cursorHeight = height;
    this.#hasCursor = 1;

    try {
      // calculate the buffer size that will be used later first
      const bufferConfig = this.#evalRgbaTexBufferConfig(width, height);
      bufferConfig.label = `CursorTexBuffer(${texLayer.getIndex()})-${
        bufferConfig.size
      }`;

      // use the cached texture buffer or request a new buffer
      let texBufferGroup = texLayer.getTextureBufferGroup();
      texBufferGroup = this.#updateTextureBufferGroup(
        texLayer,
        texBufferGroup,
        bufferConfig
      );

      if (!texBufferGroup || !texBufferGroup.buffer) {
        console.warn(
          `[updateCursor()] texLayer(${texLayer.getIndex()}) cannot apply a GPU buffer!`
        );
        return;
      }

      if (texBufferGroup.buffer.mapState != 'mapped') {
        console.error(`updateCursor() why buffer state is not mapped!`);
        return;
      }

      // cursor texture is a blend texture layer and it will be rendered at the middle of other blend layers
      // texBufferGroup.buffer.label = `CursorTexBuffer(${texLayer.getIndex()})-${
      //   bufferConfig.size
      // }`;

      this.#updateCursorTexLayerBuffer(
        this.#index,
        width,
        height,
        data,
        texBufferGroup
      );

      this.markRenderingStateReady();
    } catch (error) {
      console.error(
        `[WebGPURenderDisplay] updateCursor() error:${error.message}`
      );

      add_monitor(
        `WGPU WebGPURenderDisplay_updateCursor() error:${error.message}`
      );

      this.#mResMgr.recycleTextureBufferGroup(texLayer);
      this.markRenderingStatePending();
    }
  }

  #updateCursorTexLayerBuffer(index, width, height, data, texBufferGroup) {
    const zIndex = RenderConst.TEX_LAYER_Z_IDX.CURSOR;
    const texLayer = this.#getZIndexTexLayer(zIndex);
    texLayer.setTextureLayerType(RenderConst.TEX_LAYER_TYPE.BLEND_LAYER);
    texLayer.setTextureType(RenderConst.TEX_TYPE.GPU_TEX_RGBA);

    if (!texLayer.isLocked()) {
      texLayer.setWidth(width);
      texLayer.setHeight(height);
      texLayer.setIsNew(true);
      texLayer.lock();
    } else {
      const _w = texLayer.getWidth();
      const _h = texLayer.getHeight();
      if (_w != width || _h != height) {
        texLayer.setWidth(width);
        texLayer.setHeight(height);
        texLayer.setIsNew(true);
      }
    }

    const texBuffer = this.#renderer.writeToRgbaTextureBuffer(
      index,
      width,
      height,
      data,
      texBufferGroup
    );

    texLayer.setTextureBufferGroup(texBuffer);
  }

  #updateWatermarkTexLayerBuffer(index, width, height, data, texBufferGroup) {
    const zIndex = RenderConst.TEX_LAYER_Z_IDX.WATERMARK;
    const texLayer = this.#getZIndexTexLayer(zIndex);
    texLayer.setTextureLayerType(RenderConst.TEX_LAYER_TYPE.BLEND_LAYER);
    texLayer.setTextureType(RenderConst.TEX_TYPE.GPU_TEX_RGBA);

    if (!texLayer.isLocked()) {
      texLayer.setWidth(width);
      texLayer.setHeight(height);
      texLayer.setIsNew(true);
      texLayer.lock();
    } else {
      const _w = texLayer.getWidth();
      const _h = texLayer.getHeight();
      if (_w != width || _h != height) {
        texLayer.setWidth(width);
        texLayer.setHeight(height);
        texLayer.setIsNew(true);
      }
    }

    const texBuffer = this.#renderer.writeToRgbaTextureBuffer(
      index,
      width,
      height,
      data,
      texBufferGroup
    );

    texLayer.setTextureBufferGroup(texBuffer);
  }

  #updateRgbaTexLayerBuffer(index, width, height, data, texBufferGroup) {
    const zIndex = RenderConst.TEX_LAYER_Z_IDX.VS_BASE;
    const texLayer = this.#getZIndexTexLayer(zIndex);
    texLayer.setTextureLayerType(RenderConst.TEX_LAYER_TYPE.BASE_LAYER);
    texLayer.setTextureType(RenderConst.TEX_TYPE.GPU_TEX_RGBA);

    if (!texLayer.isLocked()) {
      texLayer.setWidth(width);
      texLayer.setHeight(height);
      texLayer.setIsNew(true);
      texLayer.lock();
    } else {
      const _w = texLayer.getWidth();
      const _h = texLayer.getHeight();
      if (_w != width || _h != height) {
        texLayer.setWidth(width);
        texLayer.setHeight(height);
        texLayer.setIsNew(true);
      }
    }

    const texBuffer = this.#renderer.writeToRgbaTextureBuffer(
      index,
      width,
      height,
      data,
      texBufferGroup
    );

    texLayer.setTextureBufferGroup(texBuffer);
  }

  #drawVideoFrameBaseTextureLayer(viewportParams, renderingRect, isMirror) {
    if (!this.#isValidViewportParameter(viewportParams)) {
      return;
    }

    if (!this.#canvas) {
      console.log(
        `drawVideoFrameBaseTextureLayer() canvas is invalid? canvas=${
          this.#canvas
        }`
      );
      return;
    }

    // get video texture layer as the base layer first.
    // Next, update data fields to it
    const zIndex = RenderConst.TEX_LAYER_Z_IDX.VS_BASE;
    const texLayer = this.#getZIndexTexLayer(zIndex);

    // 1. update UV coordinates first if needed
    let uvCoordsArray = texLayer.getUVCoords();
    let uvCoords = this.#evalUVCoordsForMultiView(
      this.#textureWidth,
      this.#textureHeight,
      this.#croppingParams,
      this.#rotation,
      isMirror,
      viewportParams.width,
      viewportParams.height
    );
    if (!uvCoordsArray) {
      uvCoordsArray = new Float32Array(12);
    }
    uvCoordsArray.set(uvCoords, 0);
    texLayer.setUVCoords(uvCoordsArray);

    // 2. update viewport if needed
    const isContentLandscape =
      this.#croppingParams.width > this.#croppingParams.height;

    // if canvas's width/height is larger than the viewport's width/height, use the width/height of viewport
    // if canvas's width/height is smaller than the viewport's width/height, use the width/height of canvas
    // meanwhile, if the canvas's width/height is larger than the viewport's width/height, it means the size of
    // canvas is bigger than the size of viewport. Under this case, we should make the content in the middle of
    // the canvas, that is, we need te recalculate the x/y of the viewport.
    const isWidthCanvasOverViewport = this.#canvas.width > viewportParams.width;
    const isHeightCanvasOverViewport =
      this.#canvas.height > viewportParams.height;

    let usedWidth = isWidthCanvasOverViewport
      ? viewportParams.width
      : this.#canvas.width;
    let usedHeight = isHeightCanvasOverViewport
      ? viewportParams.height
      : this.#canvas.height;

    if (isContentLandscape) {
      const _w = Math.abs(renderingRect.left) * usedWidth;
      const _h = Math.abs(renderingRect.top) * usedHeight;
      const _x = viewportParams.x + (viewportParams.width - _w) / 2;
      let _y = 0;
      if (viewportParams.y >= 0) {
        _y = viewportParams.y + (viewportParams.height - _h) / 2;
      } else {
        _y = 0;
      }
      texLayer.evalViewport(_x, _y, _w, _h, this.#canvas.height);
    } else {
      let _width =
        (viewportParams.height * this.#croppingParams.width) /
        this.#croppingParams.height;

      if (_width > viewportParams.width) {
        _width = viewportParams.width;
      }

      let _height =
        (this.#croppingParams.height / this.#croppingParams.width) * _width;

      const _x = viewportParams.x + viewportParams.width / 2 - _width / 2;

      let _y = 0;
      if (viewportParams.y > 0) {
        _y = viewportParams.y + (viewportParams.height - _height) / 2;
      } else if (viewportParams.y === 0) {
        const isHeightViewportLargerThanContent =
          viewportParams.height > _height;
        if (isHeightViewportLargerThanContent) {
          _y = (viewportParams.height - _height) / 2;
        } else {
          _y = 0;
        }
      } else {
        _y = 0;
      }

      texLayer.evalViewport(_x, _y, _width, _height, this.#canvas.height);
    }

    // 3. update uniforms
    const uniforms = this.#updateVideoFrameTexLayerUniforms();
    if (uniforms && uniforms.buffer) {
      texLayer.setUniformBuffer(uniforms.buffer);
    }
  }

  #drawYuvBaseTextureLayer(viewportParams, renderingRect, isMirror) {
    // get video texture layer as the base layer first.
    // Next, update data fields to it
    const zIndex = RenderConst.TEX_LAYER_Z_IDX.VS_BASE;
    const texLayer = this.#getZIndexTexLayer(zIndex);

    // 1. update UV coordinates first if needed
    let uvCoordsArray = texLayer.getUVCoords();
    let uvCoords = this.#evalUVCoordsForMultiView(
      this.#textureWidth,
      this.#textureHeight,
      this.#croppingParams,
      this.#rotation,
      isMirror,
      viewportParams.width,
      viewportParams.height
    );

    if (!uvCoordsArray) {
      uvCoordsArray = new Float32Array(12);
    }
    uvCoordsArray.set(uvCoords, 0);
    texLayer.setUVCoords(uvCoordsArray);

    // 2. update viewport
    const isContentLandscape =
      this.#croppingParams.width > this.#croppingParams.height;

    // if canvas's width/height is larger than the viewport's width/height, use the width/height of viewport
    // if canvas's width/height is smaller than the viewport's width/height, use the width/height of canvas
    // meanwhile, if the canvas's width/height is larger than the viewport's width/height, it means the size of
    // canvas is bigger than the size of viewport. Under this case, we should make the content in the middle of
    // the canvas, that is, we need te recalculate the x/y of the viewport.
    const isWidthCanvasOverViewport = this.#canvas.width > viewportParams.width;
    const isHeightCanvasOverViewport =
      this.#canvas.height > viewportParams.height;

    let usedWidth = isWidthCanvasOverViewport
      ? viewportParams.width
      : this.#canvas.width;
    let usedHeight = isHeightCanvasOverViewport
      ? viewportParams.height
      : this.#canvas.height;

    if (isContentLandscape) {
      const _w = Math.abs(renderingRect.left) * usedWidth;
      const _h = Math.abs(renderingRect.top) * usedHeight;
      const _x = viewportParams.x + (viewportParams.width - _w) / 2;
      let _y = 0;
      if (viewportParams.y >= 0) {
        _y = viewportParams.y + (viewportParams.height - _h) / 2;
      } else {
        _y = 0;
      }
      texLayer.evalViewport(_x, _y, _w, _h, this.#canvas.height);
    } else {
      let _width =
        (viewportParams.height * this.#croppingParams.width) /
        this.#croppingParams.height;

      if (_width > viewportParams.width) {
        _width = viewportParams.width;
      }

      let _height =
        (this.#croppingParams.height / this.#croppingParams.width) * _width;

      const _x = viewportParams.x + viewportParams.width / 2 - _width / 2;

      let _y = 0;
      if (viewportParams.y > 0) {
        _y = viewportParams.y + (viewportParams.height - _height) / 2;
      } else if (viewportParams.y === 0) {
        const isHeightViewportLargerThanContent =
          viewportParams.height > _height;
        if (isHeightViewportLargerThanContent) {
          _y = (viewportParams.height - _height) / 2;
        } else {
          _y = 0;
        }
      } else {
        _y = 0;
      }

      texLayer.evalViewport(_x, _y, _width, _height, this.#canvas.height);
    }

    // 3. update uniforms
    let uniforms = null;
    if (texLayer.getTextureType() == RenderConst.TEX_TYPE.EXTERNAL_TEX) {
      uniforms = this.#updateVideoFrameTexLayerUniforms();
    } else {
      uniforms = this.#updateYuvTexLayerUniforms(this.#rotation);
    }

    if (uniforms && uniforms.buffer) {
      texLayer.setUniformBuffer(uniforms.buffer);
    }
  }

  updateSelfVideoTextures(
    width,
    height,
    data,
    croppingParams,
    updateFlag = true,
    rotation = 0
  ) {
    this.#checkRendererAttached();
    const texLayer = this.#getZIndexTexLayer(
      RenderConst.TEX_LAYER_Z_IDX.VS_BASE
    );

    if (!this.#canvas || !this.#renderer) {
      if (data && data instanceof VideoFrame) {
        data.close();
      }

      this.#clearTexLayerResources();
      this.markRenderingStatePending();
      return;
    }

    if (width <= 0 || height <= 0 || !data || data.length % 4 != 0) {
      if (data && data instanceof VideoFrame) {
        data.close();
      }
      this.markRenderingStatePending();
      return;
    }

    // if width and height are 1, that means we need to clear the render display with the clear color
    // for WebGPU, we need to prepare a uniform buffer and put it to the clear color shader directly
    if (width == 1 && height == 1) {
      texLayer.setPendingVideoFrame(null);
      this.#clearTexLayerResources();

      if (data && data instanceof VideoFrame) {
        data.close();
      }

      const clearColorUniformBufArray =
        this.#updateClearColorUniformsBufferArray(data);

      if (clearColorUniformBufArray) {
        if (this.#renderer) {
          let uniformBuffer = texLayer.getClearColorUniformBuffer();
          uniformBuffer = this.#renderer.writeUniformBuffer(
            'ClearColorUniformBuffer',
            clearColorUniformBufArray,
            uniformBuffer
          );
          texLayer.setClearColorUniformBuffer(uniformBuffer);
          texLayer.setTextureType(RenderConst.TEX_TYPE.CLEAR_COLOR);
        } else {
          console.warn(`updateSelfVideoTextures() renderer is not attached!`);
        }
      } else {
        globaltracing_error(
          `updateSelfVideoTextures() cannot create the uniform buffer array.`
        );
      }

      this.markRenderingStateReady();
      return;
    }

    this.#textureWidth = width;
    this.#textureHeight = height;
    this.#rotation = rotation;
    Object.assign(this.#croppingParams, croppingParams);

    if (!updateFlag) {
      if (data && data instanceof VideoFrame) {
        data.close();
      }
      this.markRenderingStatePending();
      return;
    }

    try {
      this.#updateVideoFrameBaseTextureLayer(width, height, data);
      this.markRenderingStateReady();
    } catch (error) {
      console.log(
        `[WebGPURenderDisplay] updateSelfVideoTextures() error:${error.message}`
      );

      add_monitor(
        `WGPU WebGPURenderDisplay_updateSelfVideoTextures() error:${error.message}`
      );

      // to reset rendering state to pending for next round of updating data
      this.markRenderingStatePending();

      // to close video frame that prevents from stalling
      if (data instanceof VideoFrame) {
        data.close();
      }

      // to release the video frame, won't cache it if any exception here
      const texLayer = this.#getZIndexTexLayer(
        RenderConst.TEX_LAYER_Z_IDX.VS_BASE
      );
      texLayer.setPendingVideoFrame(null);
    }
  }

  updateRemoteVideoTexturesImageBitmap(
    width,
    height,
    data,
    croppingParams,
    rotation,
    updateFlag = true
  ) {
    const texLayer = this.#getZIndexTexLayer(
      RenderConst.TEX_LAYER_Z_IDX.VS_BASE
    );

    if (!this.#renderer || !this.#canvas) {
      if (data && data instanceof VideoFrame) {
        data.close();
      }

      this.#clearTexLayerResources();
      this.markRenderingStatePending();
      return;
    }

    if (width <= 0 || height <= 0 || !data) {
      if (data && data instanceof VideoFrame) {
        data.close();
      }
      this.markRenderingStatePending();
      return;
    }

    this.#textureWidth = width;
    this.#textureHeight = height;
    if (!Number.isNaN(rotation)) {
      this.#rotation = rotation;
    }

    Object.assign(this.#croppingParams, croppingParams);

    if (!updateFlag) {
      if (data && data instanceof VideoFrame) {
        data.close();
      }
      this.markRenderingStatePending();
      return;
    }

    try {
      this.#updateVideoFrameBaseTextureLayer(width, height, data);
      this.markRenderingStateReady();
    } catch (error) {
      console.log(
        `[WebGPURenderDisplay] updateRemoteVideoTexturesImageBitmap() error:${error.message}`
      );

      add_monitor(
        `WGPU WebGPURenderDisplay_updateRemoteVideoTexturesImageBitmap() error:${error.message}`
      );

      // to reset rendering state to pending for next round of updating data
      this.markRenderingStatePending();

      // to close video frame that prevents from stalling
      if (data instanceof VideoFrame) {
        data.close();
      }

      // to release the video frame, won't cache it if any exception here
      texLayer.setPendingVideoFrame(null);
    }
  }

  updateRemoteVideoTextures(
    width,
    height,
    croppingParams,
    data,
    rotation,
    limitedColorRange = true,
    viewportParams,
    isOldData
  ) {
    const zIndex = RenderConst.TEX_LAYER_Z_IDX.VS_BASE;
    const texLayer = this.#getZIndexTexLayer(zIndex);

    if (!this.#canvas || !this.#renderer) {
      this.#clearTexLayerResources();
      this.markRenderingStatePending();
      return;
    }

    if (!this.#isValidViewportParameter(viewportParams)) {
      this.#mResMgr.recycleTextureBufferGroup(texLayer);
      this.markRenderingStatePending();
      return;
    }

    this.#checkRendererAttached();
    const isRgbaMode = this.isRgbaMode(this.#videoMode);
    if (
      width <= 0 ||
      height <= 0 ||
      !data ||
      !data.length ||
      (data.length != (width * height * 3) / 2 && !isRgbaMode) ||
      (croppingParams &&
        (croppingParams.top < 0 ||
          croppingParams.left < 0 ||
          croppingParams.left + croppingParams.width > width ||
          croppingParams.top + croppingParams.height > height))
    ) {
      this.#mResMgr.recycleTextureBufferGroup(texLayer);
      this.markRenderingStatePending();
      return;
    }

    if (isRgbaMode) {
      // if is rgba mode, that means the data is a video frame
      // so directly notify the WebGPU render to draw this frame
      try {
        this.#updateVideoFrameBaseTextureLayer(width, height, data, true);
        let colorRange = limitedColorRange ? 0 : 1;
        this.#colorRange = colorRange;
        this.#rotation = rotation;
        Object.assign(this.#croppingParams, croppingParams);
        this.#textureWidth = width;
        this.#textureHeight = height;
        this.#canvasWidth = this.#canvas.width;
        this.#canvasHeight = this.#canvas.height;
      } catch (error) {
        console.error(
          `[WebGPURenderDisplay] updateRemoteVideoTextures() error:${error.message}`
        );

        add_monitor(
          `WGPU WebGPURenderDisplay_updateRemoteVideoTextures() error:${error.message}`
        );

        texLayer.setPendingVideoFrame(null);
        this.markRenderingStatePending();
      } finally {
        this.#mResMgr.recycleTextureBufferGroup(texLayer);
      }
      return;
    }

    try {
      // calculate the buffer size that will be used later first
      const bufferConfig = this.#evalYuvTexBufferConfig(
        width,
        height,
        this.#videoMode
      );

      bufferConfig.label = `YuvVideoTexBuffer(${texLayer.getIndex()})-${
        bufferConfig.size
      }`;

      texLayer.setColorFormat(bufferConfig.colorFormat);

      // use the cached texture buffer or request a new buffer
      let texBufferGroup = texLayer.getTextureBufferGroup();
      texBufferGroup = this.#updateTextureBufferGroup(
        texLayer,
        texBufferGroup,
        bufferConfig
      );

      if (!texBufferGroup || !texBufferGroup.buffer) {
        console.warn(
          `[updateRemoteVideoTextures()] texLayer(${texLayer.getIndex()}) cannot apply a GPUBuffer!`
        );
        this.markRenderingStatePending();
        return;
      }

      // set label to buffer
      // texBufferGroup.buffer.label = `YuvVideoTexBuffer(${texLayer.getIndex()})-${
      //   bufferConfig.size
      // }`;

      let colorRange = limitedColorRange ? 0 : 1;
      this.#colorRange = colorRange;
      this.#rotation = rotation;
      Object.assign(this.#croppingParams, croppingParams);
      this.#textureWidth = width;
      this.#textureHeight = height;
      this.#canvasWidth = this.#canvas.width;
      this.#canvasHeight = this.#canvas.height;

      // start to write raw data to buffer
      this.#updateYuvBaseTexLayerBufferGroup(
        this.#index,
        width,
        height,
        data,
        texBufferGroup,
        this.#videoMode
      );

      this.markRenderingStateReady();
    } catch (error) {
      console.error(
        `[WebGPURenderDisplay] updateRemoteVideoTextures() error:${error.message} cs:${error.stack}`
      );

      add_monitor(
        `WGPU WebGPURenderDisplay_updateRemoteVideoTextures() error:${error.message}`
      );

      this.#mResMgr.recycleTextureBufferGroup(texLayer);
      this.markRenderingStatePending();
    }
  }

  drawNextOutputPictureFrame(
    width,
    height,
    croppingParams,
    data,
    rotation,
    limitedColorRange = true,
    windowOnCanvasVariable = null,
    bupdateTexture = true
  ) {
    const zIndex = RenderConst.TEX_LAYER_Z_IDX.VS_BASE;
    const texLayer = this.#getZIndexTexLayer(zIndex);

    if (!this.#canvas || !this.#renderer) {
      this.#clearTexLayerResources();
      this.markRenderingStatePending();
      return;
    }

    if (isDimensionsOverMaxDimension2DSize(this.#mResMgr, width, height)) {
      this.#mResMgr.recycleTextureBufferGroup(texLayer);
      this.markRenderingStatePending();
      return;
    }

    rotation = rotation ? rotation : RenderConst.ROTATION_CLOCK_0;
    croppingParams = croppingParams
      ? croppingParams
      : { top: 0, left: 0, width: width, height: height };

    let picSizeChange =
      croppingParams.width != this.#croppingParams.width ||
      croppingParams.height != this.#croppingParams.height;
    let picPosChange =
      croppingParams.top != this.#croppingParams.top ||
      croppingParams.left != this.#croppingParams.left;
    let canvasSizeChange =
      this.#canvas.width != this.#canvasWidth ||
      this.#canvas.height != this.#canvasHeight;
    let texSizeChange =
      width != this.#textureWidth || height != this.#textureHeight;
    let rotationChange = rotation != this.#picRotation;

    if (picSizeChange || canvasSizeChange || rotationChange) {
      this.#updateVertexCoords(
        this.#canvas,
        croppingParams.width,
        croppingParams.height,
        rotation,
        windowOnCanvasVariable
      );
    }

    if (windowOnCanvasVariable) {
      const croppingRect = evalCroppingRect(croppingParams, rotation);
      const viewport = calculateRenderRect(
        windowOnCanvasVariable,
        croppingRect
      );
      texLayer.evalViewport(
        viewport.x,
        viewport.y,
        viewport.width,
        viewport.height,
        this.#canvas.height
      );
    } else {
      texLayer.evalViewport(
        0,
        0,
        this.#canvas.width,
        this.#canvas.height,
        this.#canvas.height
      );
    }

    if (
      picSizeChange ||
      picPosChange ||
      texSizeChange ||
      rotationChange ||
      !texLayer.getUVCoords()
    ) {
      let uvCoords = calculateUVCoord(
        { width, height },
        croppingParams,
        this.#canvas,
        rotation
      );

      let uvCoordsArray = texLayer.getUVCoords();
      if (!uvCoordsArray) {
        uvCoordsArray = new Float32Array(12);
      }

      uvCoordsArray.set(uvCoords);
      texLayer.setUVCoords(uvCoordsArray);
    }

    let colorRange = limitedColorRange ? 0 : 1;
    if (colorRange != this.#colorRange) {
      this.#colorRange = colorRange;
    }

    this.#onlyRGBA = 0;
    this.#yuvMode = VIDEO_I420;
    Object.assign(this.#croppingParams, croppingParams);
    this.#textureWidth = width;
    this.#textureHeight = height;
    this.#picRotation = rotation;
    this.#canvasWidth = this.#canvas.width;
    this.#canvasHeight = this.#canvas.height;
    texLayer.setColorFormat('i420');

    try {
      // calculate the buffer size that will be used later first
      const bufferConfig = this.#evalYuvTexBufferConfig(
        width,
        height,
        VIDEO_I420
      );
      bufferConfig.label = `YuvShareTexBuffer(${texLayer.getIndex()})-${
        bufferConfig.size
      }`;
      if (bupdateTexture) {
        // use the cached texture buffer or request a new buffer
        let texBufferGroup = texLayer.getTextureBufferGroup();
        texBufferGroup = this.#updateTextureBufferGroup(
          texLayer,
          texBufferGroup,
          bufferConfig
        );

        if (!texBufferGroup || !texBufferGroup.buffer) {
          console.warn(
            `[drawNextOutputPictureFrame()] texLayer(${texLayer.getIndex()}) cannot apply a GPU buffer!`
          );
          this.markRenderingStatePending();
          return;
        }

        // set label to buffer
        // texBufferGroup.buffer.label = `YuvShareTexBuffer(${texLayer.getIndex()})-${bufferConfig}`;

        this.#updateYuvBaseTexLayerBufferGroup(
          this.#index,
          width,
          height,
          data,
          texBufferGroup,
          VIDEO_I420
        );

        // update uniforms
        const uniforms = this.#updateYuvTexLayerUniforms(this.#picRotation);
        if (uniforms && uniforms.buffer) {
          texLayer.setUniformBuffer(uniforms.buffer);
        }
      }

      if (!this.#hasCursor) {
        this.#cursorFlag = 0;
      } else {
        this.#cursorFlag = 1;
      }

      this.#hasWholeFrame = 1;
      this.markRenderingStateReady();
    } catch (error) {
      console.error(
        `[WebGPURenderDisplay] drawNextOutputPictureFrame() error:${error.message}`
      );

      add_monitor(
        `WGPU WebGPURenderDisplay_drawNextOutputPictureFrame() error:${error.message}`
      );

      this.#mResMgr.recycleTextureBufferGroup(texLayer);
      this.markRenderingStatePending();
    }
  }

  clearCanvas(color) {
    if (this.#renderer) {
      this.#renderer.clearAttachedCanvas();
    }
  }

  updateSelfMaskImage(width, height, data) {
    const zIndex = RenderConst.TEX_LAYER_Z_IDX.MASK;
    const texLayer = this.#getZIndexTexLayer(zIndex);

    if (!this.#canvas) {
      this.#mResMgr.recycleTextureBufferGroup(texLayer);
      this.markRenderingStatePending();
      return;
    }

    if (
      width <= 0 ||
      height <= 0 ||
      !data ||
      data.length != width * height * 4
    ) {
      this.#mResMgr.recycleTextureBufferGroup(texLayer);
      this.markRenderingStatePending();
      return;
    }

    // if (this.#hasWatermark == 1) {
    //   console.log('[updateSelfMaskImage] has watermark now!');
    //   return;
    // }

    if (!this.#hasZIndexTexLayer(RenderConst.TEX_LAYER_Z_IDX.VS_BASE)) {
      console.log('[updateSelfMaskImage] base layer is not ready.');
      this.#mResMgr.recycleTextureBufferGroup(texLayer);
      this.markRenderingStatePending();
      return;
    }

    try {
      // calculate the buffer size that will be used later first
      const bufferConfig = this.#evalRgbaTexBufferConfig(width, height);

      // use the cached texture buffer or request a new buffer
      let texBufferGroup = texLayer.getTextureBufferGroup();
      texBufferGroup = this.#updateTextureBufferGroup(
        texLayer,
        texBufferGroup,
        bufferConfig
      );

      if (!texBufferGroup || !texBufferGroup.buffer) {
        console.warn(
          `[updateSelfMaskImage()] texLayer(${texLayer.getIndex()}) cannot apply a GPU buffer!`
        );
        this.markRenderingStatePending();
        return;
      }

      // set label to buffer
      texBufferGroup.buffer.label = `SelfMaskImageTexBuffer(${texLayer.getIndex()})-${
        bufferConfig.size
      }`;

      texLayer.setTextureLayerType(RenderConst.TEX_LAYER_TYPE.BLEND_LAYER);
      texLayer.setTextureType(RenderConst.TEX_TYPE.GPU_TEX_RGBA);

      if (!texLayer.isLocked()) {
        texLayer.setWidth(width);
        texLayer.setHeight(height);
        texLayer.setIsNew(true);
        texLayer.lock();
      } else {
        const _w = texLayer.getWidth();
        const _h = texLayer.getHeight();
        if (_w != width || _h != height) {
          texLayer.setWidth(width);
          texLayer.setHeight(height);
          texLayer.setIsNew(true);
        }
      }

      const texBuffer = this.#renderer.writeToRgbaTextureBuffer(
        this.#index,
        width,
        height,
        data,
        texBufferGroup
      );
      texLayer.setTextureBufferGroup(texBuffer);

      // 2. set viewport for watermark texture layer
      const baseTexLayer = this.#getZIndexTexLayer(
        RenderConst.TEX_LAYER_Z_IDX.VS_BASE
      );
      const baseLayerViewport = baseTexLayer.getViewport();
      if (baseLayerViewport) {
        texLayer.setViewport(baseLayerViewport);
      }

      // 3. update uv coordinates for watermark texture layer
      // it is same as the base texture layer currently
      texLayer.setUVCoords(baseTexLayer.getUVCoords());

      if (
        this.isSetWatermark() &&
        this.#watermarkWidth &&
        this.#watermarkHeight
      ) {
        // 4. prepare render pipeline and samplers
        // this.#updateVertexCoords(this.#canvas, width, height, this.#rotation, this.croppingParams);
      }

      this.markRenderingStateReady();
    } catch (error) {
      console.error(
        `[WebGPURenderDisplay] updateSelfMaskImage() error:${error.message}`
      );

      add_monitor(
        `WGPU WebGPURenderDisplay_updateSelfMaskImage() error:${error.message}`
      );

      this.#mResMgr.recycleTextureBufferGroup(texLayer);
      this.markRenderingStatePending();
    }
  }

  readPixelsSyncRequest(x, y, w, h) {}

  isAvaiable() {
    return true;
  }

  markRenderingStateReady() {
    this.#mRenderingState = RenderConst.RENDERING_STATE.READY;
  }

  markRenderingStateRendering() {
    this.#mRenderingState = RenderConst.RENDERING_STATE.RENDERING;
  }

  markRenderingStatePending() {
    this.#mRenderingState = RenderConst.RENDERING_STATE.PENDING;
  }

  markRenderingStateIdle() {
    this.#mRenderingState = RenderConst.RENDERING_STATE.IDLE;
  }

  isRenderingStateReady() {
    return this.#mRenderingState === RenderConst.RENDERING_STATE.READY;
  }

  isInTargetRenderingState(renderingState) {
    return this.#mRenderingState === renderingState;
  }

  getWatermarkWidth() {
    return this.#watermarkWidth;
  }

  getWatermarkHeight() {
    return this.#watermarkHeight;
  }

  getIndex() {
    return this.#index;
  }

  getRenderingState() {
    return this.#mRenderingState;
  }

  recycle(needToRecycle = true) {
    for (const [key, value] of this.#textureLayersMap) {
      if (value) {
        this.#mResMgr.recycleTextureBufferGroup(value, needToRecycle);
        value.recycle(this.#mResMgr);
      }
    }

    this.#uvCoordinatesHolder = { top: 0, left: 0, bottom: 0, right: 0 };
    this.markRenderingStateIdle();
    this.#textureLayersMap.clear();
    this.#texLayerUniformsMap.clear();
    this.unbindSsrc();
  }

  /**
   * Cleanup all used resources in the render display.
   */
  cleanup(needToRecycle = true) {
    this.recycle(needToRecycle);
    this.removeRenderer();
    this.detachCanvas();
    this.removeGPUResMgr();
  }

  /**
   * Clear the attached canvas and some states.
   */
  clear() {
    console.log('WebGPURenderDisplay.clear');
    this.clearCanvas();
    this.#hasWholeFrame = 0;
    this.#hasCursor = 0;
    this.recycle();
  }

  /**
   * Clear the attached canvas.
   */
  clearDisplay() {
    console.log('WebGPURenderDisplay.clearDisplay');
    this.clearCanvas();
  }

  #evalUVCoordsForMultiView(
    w,
    h,
    croppingParams,
    rotation,
    isMirror,
    viewportW,
    viewportH
  ) {
    const isFillMode = this.isUseFillMode({
      w: croppingParams.width,
      h: croppingParams.height,
      rotation,
    });

    const viewport = {
      width: viewportW,
      height: viewportH,
    };

    const textureRect = { width: w, height: h };

    return calculateUVCoordsForMultiView(
      isFillMode,
      viewport,
      textureRect,
      croppingParams,
      rotation,
      isMirror
    );
  }

  #updateVertexCoords(
    canvas,
    picWidth,
    picHeight,
    rotation,
    croppingParams = null
  ) {
    let canvasWidth = canvas.width;
    let canvasHeight = canvas.height;
    if (croppingParams) {
      canvasWidth = croppingParams.width;
      canvasHeight = croppingParams.height;
    }

    let w =
      rotation == RenderConst.ROTATION_CLOCK_90 ||
      rotation == RenderConst.ROTATION_CLOCK_270
        ? picHeight
        : picWidth;
    let h =
      rotation == RenderConst.ROTATION_CLOCK_90 ||
      rotation == RenderConst.ROTATION_CLOCK_270
        ? picWidth
        : picHeight;
    let left, top, right, bottom;
    let dw = (w / h) * canvasHeight;
    let dh = (h / w) * canvasWidth;

    if (dw > canvasWidth) {
      left = 0;
      right = 1;
      top = (canvasHeight - dh) / 2 / canvasHeight;
      bottom = 1 - top;
    } else {
      top = 0;
      bottom = 1;
      left = (canvasWidth - dw) / 2 / canvasWidth;
      right = 1 - left;
    }

    left = left * 2 - 1;
    right = right * 2 - 1;
    top = 1 - top * 2;
    bottom = 1 - bottom * 2;

    // update the new vertex coords to wgpu render
    let vtxCoords = [
      { x: right, y: top },
      { x: right, y: bottom },
      { x: left, y: bottom },
      { x: right, y: top },
      { x: left, y: top },
      { x: left, y: bottom },
    ];

    if (this.#renderer) {
      this.#renderer.updateVertexCoords(vtxCoords);
    }
  }

  #evalRenderingRect(width, height, picW, picH, rotation) {
    var left, top, right, bottom;
    if (this.isUseFillMode({ w: picW, h: picH, rotation })) {
      left = 0;
      top = 0;
      right = 1;
      bottom = 1;
    } else {
      var w =
        rotation == RenderConst.ROTATION_CLOCK_90 ||
        rotation == RenderConst.ROTATION_CLOCK_270
          ? picH
          : picW;
      var h =
        rotation == RenderConst.ROTATION_CLOCK_90 ||
        rotation == RenderConst.ROTATION_CLOCK_270
          ? picW
          : picH;
      var dw = (w / h) * height;
      var dh = (h / w) * width;

      if (dw > width) {
        left = 0;
        right = 1;
        top = (height - dh) / 2 / height;
        bottom = 1 - top;
      } else {
        top = 0;
        bottom = 1;
        left = (width - dw) / 2 / width;
        right = 1 - left;
      }
    }

    left = left * 2 - 1;
    right = right * 2 - 1;
    top = 1 - top * 2;
    bottom = 1 - bottom * 2;

    // update the new vertex coords to wgpu render
    const renderingZoneRect = {
      top: top,
      left: left,
      right: right,
      bottom: bottom,
    };

    return renderingZoneRect;
  }

  #checkRendererAttached() {
    if (!this.#renderer) {
      globaltracing_error('[WebGPURenderDisplay] renderer is not attached!');
    }
  }

  #hasZIndexTexLayer(zIndex) {
    if (zIndex < 0) {
      throw new Error(`[hasZIndexTexLayer] ${zIndex} is an invalid parameter!`);
    }

    return this.#textureLayersMap.has(zIndex);
  }

  #getZIndexTexLayer(zIndex) {
    let texLayer = null;
    if (this.#hasZIndexTexLayer(zIndex)) {
      texLayer = this.#textureLayersMap.get(zIndex);
    } else {
      texLayer = new TextureLayer(this.#index, zIndex);
      this.#textureLayersMap.set(zIndex, texLayer);
    }

    return texLayer;
  }

  getTextureLayersMap() {
    return this.#textureLayersMap;
  }

  getTextureLayerByZIndex(zIndex) {
    return this.#getZIndexTexLayer(zIndex);
  }

  getUsedBuffersCount() {
    let count = 0;
    for (const [zIndex, texLayer] of this.#textureLayersMap) {
      if (
        texLayer &&
        texLayer.getTextureBufferGroup() &&
        texLayer.getTextureBufferGroup().buffer
      ) {
        count++;
      }
    }

    return count;
  }

  consumePendingGPUEvents() {
    if (this.#hasWatermark) {
      const texLayer = this.#getZIndexTexLayer(
        RenderConst.TEX_LAYER_Z_IDX.WATERMARK
      );

      const rawData = texLayer.getRawData();
      if (rawData) {
        this.updateWatermark(
          this.#watermarkWidth,
          this.#watermarkHeight,
          rawData.data
        );
      }
    }
  }

  #setUniformsFlag(rgbaFlag, cursorFlag, videoMode) {
    if (this.#canvas) {
      this.#onlyRGBA = rgbaFlag;
      this.#bgraModeFlag = rgbaFlag && videoMode === VIDEO_BGRA ? 1 : 0;
      this.#cursorFlag = cursorFlag;
      if (!rgbaFlag) {
        this.#yuvMode = videoMode;
      }
    }
  }

  #updateVideoFrameTexLayerUniforms() {
    const _uniform = {
      rotation: this.#rotation,
    };

    let buffer = null;
    let uniforms = this.#texLayerUniformsMap.get(
      RenderConst.TEX_LAYER_Z_IDX.VS_BASE
    );

    if (uniforms) {
      let cachedBuffer = uniforms.buffer;
      let cachedUniform = uniforms.uniform;
      if (cachedUniform) {
        if ('yuvMode' in cachedUniform) {
          // it means the cached uniform was used to be a uniform for yuv texture instead of video frame
          // under this case, we need to drop the cachedUniform and create a new instance
          // before that, to destroy the buffer if it is not a nullable GPUBuffer
          if (cachedBuffer) {
            cachedBuffer.destroy();
          }
          uniforms = null;
        } else {
          if (!('rotation' in cachedUniform)) {
            if (cachedBuffer) {
              cachedBuffer.destroy();
            }
            uniforms = null;
          } else {
            // if has rotation field in uniform and the uniform is used for video frame tex layer
            // we need to check whether the rotation is changed or not
            // if value of it is changed, we just need to update the uniform, no need to recreate a GPUBuffer
            if (cachedUniform.rotation != _uniform.rotation) {
              const uniformBufArray =
                this.#createVideoFrameTexLayerUniformBufferArray(_uniform);
              buffer = this.#renderer.writeUniformBuffer(
                `VideoFrameTexLayerUniformBuffer(idx=${this.#index})`,
                uniformBufArray,
                cachedBuffer
              );
            }
          }
        }
      }
    }

    if (!uniforms) {
      const uniformBufArray =
        this.#createVideoFrameTexLayerUniformBufferArray(_uniform);
      buffer = this.#renderer.writeUniformBuffer(
        `VideoFrameTexLayerUniformBuffer(idx=${this.#index})`,
        uniformBufArray
      );
    }

    if (!buffer) {
      return null;
    }

    if (!uniforms) {
      uniforms = {};
    }
    uniforms.uniform = _uniform;
    uniforms.buffer = buffer;
    this.#texLayerUniformsMap.set(
      RenderConst.TEX_LAYER_Z_IDX.VS_BASE,
      uniforms
    );

    return uniforms;
  }

  #updateYuvTexLayerUniforms(rotation) {
    // if any field in uniforms is invalid, don't update uniforms
    if (this.#colorRange == -1) {
      return null;
    }

    const _uniform = {
      yuvMode: VIDEO_I420,
      colorRange: this.#colorRange,
      rotation: rotation,
    };

    let buffer = null;
    let uniforms = this.#texLayerUniformsMap.get(
      RenderConst.TEX_LAYER_Z_IDX.VS_BASE
    );
    if (uniforms) {
      const uniform = uniforms.uniform;
      buffer = uniforms.buffer;
      if (
        uniform.yuvMode != _uniform.yuvMode ||
        uniform.colorRange != _uniform.colorRange ||
        uniform.rotation != _uniform.rotation
      ) {
        // uniform is changed, need to update the buffer
        const uniformBufArray =
          this.#createYuvTexLayerUniformBufferArray(_uniform);
        buffer = this.#renderer.writeUniformBuffer(
          `YuvTexLayerUniformBuffer(idx=${this.#index})`,
          uniformBufArray,
          buffer
        );
      }
    } else {
      uniforms = {};
      const uniformBufArray =
        this.#createYuvTexLayerUniformBufferArray(_uniform);
      buffer = this.#renderer.writeUniformBuffer(
        `YuvTexLayerUniformBuffer(idx=${this.#index})`,
        uniformBufArray
      );
    }

    if (!buffer) {
      return null;
    }

    uniforms.uniform = _uniform;
    uniforms.buffer = buffer;
    this.#texLayerUniformsMap.set(
      RenderConst.TEX_LAYER_Z_IDX.VS_BASE,
      uniforms
    );
    return uniforms;
  }

  #updateCursorTexLayerUniforms() {
    if (!this.#cursorInfo) {
      return null;
    }

    const _uniform = {
      cursorFlag: this.#cursorFlag,
      cursorInfo: this.#cursorInfo,
    };

    let buffer = null;
    let uniforms = this.#texLayerUniformsMap.get(
      RenderConst.TEX_LAYER_Z_IDX.CURSOR
    );
    if (uniforms) {
      const uniform = uniforms.uniform;
      buffer = uniforms.buffer;
      if (
        uniform.cursorFlag != _uniform.cursorFlag ||
        uniform.cursorInfo != _uniform.cursorInfo
      ) {
        // uniform is changed, need to update the buffer
        const uniformBufArray =
          this.#createCursorTexLayerUniformBufferArray(_uniform);
        buffer = this.#renderer.writeUniformBuffer(
          `CursorTexLayerUniformBuffer(idx=${this.#index})`,
          uniformBufArray,
          buffer
        );
      }
    } else {
      uniforms = {};
      const uniformBufArray =
        this.#createCursorTexLayerUniformBufferArray(_uniform);
      buffer = this.#renderer.writeUniformBuffer(
        `CursorTexLayerUniformBuffer(idx=${this.#index})`,
        uniformBufArray
      );
    }

    if (!buffer) {
      return null;
    }

    uniforms.uniform = _uniform;
    uniforms.buffer = buffer;
    this.#texLayerUniformsMap.set(RenderConst.TEX_LAYER_Z_IDX.CURSOR, uniforms);
    return uniforms;
  }

  #align(n, alignment) {
    return Math.ceil(n / alignment) * alignment;
  }

  #createVideoFrameTexLayerUniformBufferArray(uniform) {
    const size = this.#align(Float32Array.BYTES_PER_ELEMENT * 1, 16);
    const fsF32UniformsArray = new Float32Array(
      size / Float32Array.BYTES_PER_ELEMENT
    );
    fsF32UniformsArray[0] = uniform.rotation;
    return fsF32UniformsArray;
  }

  #createYuvTexLayerUniformBufferArray(uniform) {
    const size = this.#align(Float32Array.BYTES_PER_ELEMENT * 3, 16);
    const fsF32UniformsArray = new Float32Array(
      size / Float32Array.BYTES_PER_ELEMENT
    );
    fsF32UniformsArray[0] = uniform.yuvMode;
    fsF32UniformsArray[1] = uniform.colorRange;
    fsF32UniformsArray[2] = uniform.rotation;
    return fsF32UniformsArray;
  }

  #createCursorTexLayerUniformBufferArray(uniform) {
    const size = this.#align(Float32Array.BYTES_PER_ELEMENT * 5, 16);
    const fsF32UniformsArray = new Float32Array(
      size / Float32Array.BYTES_PER_ELEMENT
    );
    fsF32UniformsArray[0] = uniform.cursorFlag;
    fsF32UniformsArray[1] = uniform.cursorInfo.x;
    fsF32UniformsArray[2] = uniform.cursorInfo.y;
    fsF32UniformsArray[3] = uniform.cursorInfo.w;
    fsF32UniformsArray[4] = uniform.cursorInfo.h;
    return fsF32UniformsArray;
  }

  #updateClearColorUniformsBufferArray(clearColorArray) {
    if (!clearColorArray || clearColorArray.length == 0) {
      return null;
    }

    const size = this.#align(Float32Array.BYTES_PER_ELEMENT * 4, 16);
    const ccF32UniformsArray = new Float32Array(
      size / Float32Array.BYTES_PER_ELEMENT
    );

    ccF32UniformsArray[0] = clearColorArray[0];
    ccF32UniformsArray[1] = clearColorArray[1];
    ccF32UniformsArray[2] = clearColorArray[2];
    ccF32UniformsArray[3] = clearColorArray[3];
    return ccF32UniformsArray;
  }

  #getYuvTexBuffers(rawData, width, height, videoMode) {
    let yLen = width * height;
    let yBuf = rawData.subarray(0, yLen);

    // CrCb
    let crLen = 0;
    let cbLen = 0;
    if (videoMode == VIDEO_I420) {
      cbLen = ((width / 2) * height) / 2;
      crLen = cbLen;
    } else if (videoMode == VIDEO_NV12) {
      cbLen = (width * height) / 2;
      crLen = 0;
    }

    let cbBuf = rawData.subarray(yLen, yLen + cbLen);
    let crBuf =
      crLen != 0 ? rawData.subarray(yLen + cbLen, yLen + cbLen + crLen) : null;

    const yuvBuffers = {
      yPlane: {
        buffer: yBuf,
        width: width,
        height: height,
      },
      crPlane: {
        buffer: crBuf,
        width: width / 2,
        height: height / 2,
      },
      cbPlane: {
        buffer: cbBuf,
        width: width / 2,
        height: height / 2,
      },
    };

    return yuvBuffers;
  }

  #evalYuvTexBufferConfig(width, height, videoMode) {
    let colorFormat = '';
    let uLen = 0;
    let vLen = 0;
    let elBytes = 0;
    if (videoMode == VIDEO_I420) {
      uLen = ((width / 2) * height) / 2;
      vLen = uLen;
      colorFormat = 'i420';
      elBytes = Uint8Array.BYTES_PER_ELEMENT;
    } else if (videoMode == VIDEO_NV12) {
      uLen = (width * height) / 2;
      vLen = 0;
      colorFormat = 'nv12';
      elBytes = Uint16Array.BYTES_PER_ELEMENT;
    }

    const yPlaneBytesPerRow = this.#align(
      Uint8Array.BYTES_PER_ELEMENT * width,
      256
    );

    const uvPlaneBytesPerRow = this.#align((elBytes * width) / 2, 256);

    let bufferSize =
      yPlaneBytesPerRow * height + (uvPlaneBytesPerRow * height) / 2;
    if (vLen > 0) {
      bufferSize += (uvPlaneBytesPerRow * height) / 2;
    }

    const bufferConfig = {
      colorFormat: colorFormat,
      size: bufferSize,
      yPlane: {
        width: yPlaneBytesPerRow,
        height: height,
      },
      uvPlane: {
        width: uvPlaneBytesPerRow,
        height: height / 2,
      },
    };

    return bufferConfig;
  }

  #evalRgbaTexBufferConfig(width, height) {
    const bytesPerRow = this.#align(Uint32Array.BYTES_PER_ELEMENT * width, 256);
    return {
      colorFormat: 'rgba',
      width: bytesPerRow,
      height: height,
      size: bytesPerRow * height,
    };
  }

  #updateTextureBufferGroup(texLayer, texBufferGroup, bufferConfig) {
    if (!texBufferGroup) {
      texBufferGroup = {};
      texBufferGroup.buffer = this.#mResMgr.requestTextureBuffer(bufferConfig);
      texBufferGroup.bufferConfig = bufferConfig;
    } else {
      if (!texBufferGroup.buffer) {
        texBufferGroup.buffer =
          this.#mResMgr.requestTextureBuffer(bufferConfig);
        texBufferGroup.bufferConfig = bufferConfig;
      } else {
        if (bufferConfig.size > texBufferGroup.buffer.size) {
          // if required size of buffer is bigger than the current buffer size
          // we can only create a new buffer, the cached one can't be reused
          // recycle the cached one and create/acquire a new one
          this.#mResMgr.recycleTextureBufferGroup(texLayer);
          texBufferGroup.buffer =
            this.#mResMgr.requestTextureBuffer(bufferConfig);
          texBufferGroup.bufferConfig = bufferConfig;
        } else {
          // if required size is same or smaller than the current buffer size
          // we can reuse the current buffer as texture buffer
          // but we should check the buffer mapState is mapped or not,
          // if not mapped state, we should request a new buffer with mapped state nevertheless it may not happen
          const isMapped = texBufferGroup.buffer.mapState == 'mapped';
          if (!isMapped) {
            this.#mResMgr.recycleTextureBufferGroup(texLayer);
            texBufferGroup.buffer =
              this.#mResMgr.requestTextureBuffer(bufferConfig);
            texBufferGroup.bufferConfig = bufferConfig;
          } else {
            if (
              texBufferGroup.bufferArray &&
              texBufferGroup.bufferArray.byteLength < bufferConfig.size
            ) {
              // fix ZOOM-759591: writing data to GPUBuffer-ArrayBuffer will be out of range
              // what: writing texture data to the ArrayBuffer cached from GPUBuffer is out of range when the new size of data is larger than the size of cached ArrayBuffer.
              // how: drop the cached GPUBuffer and its ArrayBuffer when the new size exceeds the size of cached buffers
              // analysis: an ArrayBuffer created from GPUBuffer by calling getMappedRange(0, bufferSize) is cached and will be used if the data is updated but rendering is not executed.
              this.#mResMgr.recycleTextureBufferGroup(texLayer);
              texBufferGroup.buffer =
                this.#mResMgr.requestTextureBuffer(bufferConfig);
              texBufferGroup.bufferConfig = bufferConfig;
            }
          }
        }
      }
    }

    return texBufferGroup;
  }

  #evalWatermarkUVCoords() {
    const flattenUVCoords = [];
    for (let i = 0; i < RenderConst.BASIC_UV_COORD_ARRAY.length; ++i) {
      let uvCoord = RenderConst.BASIC_UV_COORD_ARRAY[i];
      flattenUVCoords.push(uvCoord.u);
      flattenUVCoords.push(uvCoord.v);
    }

    return flattenUVCoords;
  }

  #isValidViewportParameter(viewportParams) {
    return (
      viewportParams && viewportParams.width != 0 && viewportParams.height != 0
    );
  }

  resizeCanvasTo(width, height) {
    if (this.#canvas) {
      this.#canvas.width = width;
      this.#canvas.height = height;
    }
  }

  #clearTexLayerResources() {
    if (!this.#mResMgr) {
      globaltracing_error(
        `clearTexLayerResources() GPUResManager is not attached!`
      );
      return;
    }

    for (const [zIndex, texLayer] of this.#textureLayersMap) {
      if (texLayer) {
        this.#mResMgr.recycleTextureBufferGroup(texLayer, false);
        this.#mResMgr.destroyTextureGroup(texLayer, true);
      }
    }
  }
}

export default WebGPURenderDisplay;
