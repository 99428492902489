import * as consts from '../../worker/common/consts';
import {
  getExtensionWithKnownPrefixes,
  Log_Error,
  globaltracing_DT,
  create_webgl_context_failed_monitor,
} from '../../worker/common/common';
import { narrowUvCoords, expandUvCoords } from './GPURenderUtils';
import RenderConfig from './RenderConfig';

let canvasToDisplayMap = new Map();
let canvasListener = [];

function defaultContextLostCallback(event) {
  event.preventDefault();
}
/**
 * This class can be used to render output pictures from an H264bsdDecoder to a canvas element.
 * If available the content is rendered using WebGL.
 */
function WebGL2Canvas(
  canvas,
  canvasID,
  index,
  forceNoGL,
  contextOptions,
  WebGLResources,
  initmask,
  isEnableCanvasAlphaChannel = false
) {
  this.canvasElement = canvas;
  this.canvasID = canvasID;
  this.contextOptions = contextOptions;
  this.textureindex = index ? index : 0;
  this.texturestride = !this.textureindex ? (initmask ? 4 : 6) : 3;
  this.initmask = initmask ? initmask : false;
  this.reuse = false;

  this.isEnableCanvasAlphaChannel = isEnableCanvasAlphaChannel;

  WebGL2Canvas.prototype.ROTATION_CLOCK0 = 0;
  WebGL2Canvas.prototype.ROTATION_CLOCK90 = 1;
  WebGL2Canvas.prototype.ROTATION_CLOCK180 = 2;
  WebGL2Canvas.prototype.ROTATION_CLOCK270 = 3;

  this.webGLResources = WebGLResources;
  if (!WebGLResources) {
    this.initContextGL();
    if (this.contextGL) {
      this.webGLContextLostProtect();
      if (this.contextGL.isContextLost()) {
        this.restoreContext();
      }
    }
  }

  this.reinit(WebGLResources);

  var a = new ArrayBuffer(4);
  this.dummpyCursor = new Uint8Array(a);
  this.dummpyWaterMark = new Uint8Array(a);

  this.cursorWidth = 0;
  this.cursorHeight = 0;
  this.hasCursor = 0;
  this.hasWaterMark = 0;
  this.watermarkOpacity = 0.15;
  this.watermarkData = null;
  this.watermarkWidth = 0;
  this.watermarkHeight = 0;
  this.isMultiView = false;
  this.hasWholeFrame = 0;
  this.croppingParams = {};
  this.croppingParams.top = 0;
  this.croppingParams.left = 0;
  this.croppingParams.width = 0;
  this.croppingParams.height = 0;
  this.textureWidth = 0;
  this.textureHeight = 0;
  this.canvasWidth = 0;
  this.canvasHeight = 0;
  this.picRotation = -1;
  this.bgColor = [0, 0, 0];
  this.cx = 0;
  this.cy = 0;
  this.cw = 0;
  this.ch = 0;
  this.colorRange = -1; // 0: limited , 1 full
  this.videoMode = consts.VIDEO_INVALID; //default value is VIDEO_INVALID;
  this.rotation = this.ROTATION_CLOCK0;
  /** video render fill mode
   * @enum 0: contain mode, show the full video
   * @enum 1: cover mode, fill all the render area
   * @default 0
   */
  this.fillMode = 0;
  /**
   * @description fill mode only works in specific resolution if the value is not falsy
   * @types number | number[]
   */
  this.fillModeForResolution = 0;
}

WebGL2Canvas.prototype.reinit = function (webGLResources) {
  this.webGLResources = webGLResources;
  if (
    this.contextGL &&
    !this.contextGL.isContextLost() &&
    !this.contextGL.glInitSucceed &&
    !this.webGLResources
  ) {
    this.initProgram();
    if (this.initmask) {
      this.initTextures(false);
    } else {
      this.initTextures(true);
    }
    this.initBuffers();

    let error = this.contextGL.getError();
    this.contextGL.glInitSucceed =
      error != this.contextGL.NO_ERROR &&
      error != this.contextGL.CONTEXT_LOST_WEBGL
        ? 0
        : 1;
  } else if (
    this.webGLResources &&
    this.webGLResources.contextgl &&
    !this.webGLResources.contextgl.isContextLost()
  ) {
    this.contextGL = this.webGLResources.contextgl;
    this.shaderProgram = this.webGLResources.program;
    this.waterMarkTextureRef = this.webGLResources.waterMarkTextureRef;
    this.repeatedWaterMarkTextureRef =
      this.webGLResources.repeatedWaterMarkTextureRef;
    this.initTextures(false);
    this.vertexPosBuffer = this.webGLResources.vBuffer;
    this.texturePosBuffer = this.webGLResources.tBuffer;

    let error = this.contextGL.getError();
    this.contextGL.glInitSucceed =
      error != this.contextGL.NO_ERROR &&
      error != this.contextGL.CONTEXT_LOST_WEBGL
        ? 0
        : 1;
  }
};

WebGL2Canvas.prototype.webGLContextLostSimulate = function () {
  let globalScope = typeof window === 'undefined' ? self : window;
  globalScope.webGLEXTSimulate = globalScope.webGLEXTSimulate || [];

  globalScope.webGLEXTSimulate.push(
    getExtensionWithKnownPrefixes(this.contextGL, 'WEBGL_lose_context')
  );
  if (process.env.NODE_ENV === 'development') {
    console.log(`
    webGLEXTSimulate[0].loseContext()
    webGLEXTSimulate[0].restoreContext()
    `);
  }
};

WebGL2Canvas.prototype.restoreContext = function () {
  if (!this.contextGL) {
    return;
  }
  try {
    if (
      this.canvasElement?.loseContextExtension &&
      !this.canvasElement.restoreTimeoutId &&
      this.contextGL.isContextLost()
    ) {
      this.canvasElement.restoreTimeoutId = setTimeout(() => {
        create_webgl_context_failed_monitor('WebGL2RestoreTimeout');
      }, 1500);
      this.canvasElement.loseContextExtension.restoreContext();
      if (process.env.NODE_ENV === 'development') {
        console.log(`
      triggle webglcontext2 restored!
      `);
      }
    }
  } catch (e) {
    Log_Error('webgl restoreContext exception2', e);
  }
};

WebGL2Canvas.prototype.webgGLContextLostCallback = function (event) {
  globaltracing_DT(
    `webglcontextlost2 event: canvas listener size=${
      canvasListener.length
    },  canvas id: ${this.canvasID}, , ids:${canvasListener.join()}`
  );
  event.preventDefault();
  this.contextGL.glInitSucceed = 0;
  this.contextOptions &&
    this.contextOptions.webglcontextlostCallback &&
    this.contextOptions.webglcontextlostCallback(
      event,
      this.contextOptions.params
    );
};

WebGL2Canvas.prototype.removeEventListener = function (canvas, display) {
  if (canvas && display) {
    if (process.env.NODE_ENV === 'development') {
      console.log(
        `WebGL2Canvas.removeEventListener, canvas Id=${this.canvasID}`
      );
    }
    if (canvas.restoreTimeoutId) {
      clearTimeout(canvas.restoreTimeoutId);
      canvas.restoreTimeoutId = undefined;
    }
    canvas.removeEventListener('webglcontextlost', display.contextLostHandler);
    canvas.removeEventListener(
      'webglcontextrestored',
      display.contextRestoredHandler
    );
    const index = canvasListener.indexOf(this.canvasID);
    canvasListener.splice(index, 1);
    canvasToDisplayMap.delete(canvas);
  }
};
WebGL2Canvas.prototype.webGLContextRestoredCallback = function (event) {
  globaltracing_DT(
    `webglcontextrestored2 event from canvas id: ${this.canvasID}`
  );
  if (this.canvasElement.restoreTimeoutId) {
    clearTimeout(this.canvasElement.restoreTimeoutId);
    this.canvasElement.restoreTimeoutId = undefined;
  }
  this.reinit();
  this.contextOptions &&
    this.contextOptions.webglcontextrestoredCallback &&
    this.contextOptions.webglcontextrestoredCallback(
      event,
      this.contextOptions.params
    );
};

WebGL2Canvas.prototype.webGLContextLostProtect = function () {
  if (this.canvasElement && !this.canvasElement.loseContextExtension) {
    this.canvasElement.loseContextExtension = getExtensionWithKnownPrefixes(
      this.contextGL,
      'WEBGL_lose_context'
    );
  }
  let canvas = this.canvasElement;

  let oldDisplay = canvasToDisplayMap.get(canvas);
  if (oldDisplay) {
    this.removeEventListener(canvas, oldDisplay);
  }
  canvasToDisplayMap.set(canvas, this);

  if (process.env.NODE_ENV === 'development') {
    console.log(
      `WebGL2Canvas.webGLContextLostProtect, canvas Id=${this.canvasID}`
    );
  }

  this.contextLostHandler = this.webgGLContextLostCallback.bind(this);
  this.contextRestoredHandler = this.webGLContextRestoredCallback.bind(this);

  canvas.addEventListener('webglcontextlost', this.contextLostHandler, {
    capture: false,
  });

  canvas.addEventListener('webglcontextrestored', this.contextRestoredHandler, {
    capture: false,
  });

  if (canvasListener.indexOf(this.canvasID) !== -1) {
    return;
  }

  canvasListener.push(this.canvasID);
  if (canvasListener.length > 4) {
    globaltracing_DT(
      `webgl2canvas listener size=${
        canvasListener.length
      }, ids:${canvasListener.join()}`
    );
  }
};

/**
 * Returns true if the canvas supports WebGL
 */
WebGL2Canvas.prototype.isWebGL2 = function () {
  return this.contextGL;
};

WebGL2Canvas.prototype.isAvaiable = function () {
  return (
    this.contextGL &&
    !this.contextGL.isContextLost() &&
    this.contextGL.glInitSucceed
  );
};

/**
 * Create the GL context from the canvas element
 */
WebGL2Canvas.prototype.initContextGL = function () {
  var canvas = this.canvasElement;
  var gl = null;

  var validContextNames = ['webgl2'];
  var nameIndex = 0;

  while (!gl && nameIndex < validContextNames.length) {
    var contextName = validContextNames[nameIndex];

    try {
      if (RenderConfig.isEnableCanvasCtxOptionsOpt()) {
        let opt = {
          depth: false,
          stencil: false,
          antialias: false,
          alpha: this.isEnableCanvasAlphaChannel,
        };

        if (this.contextOptions) {
          let mergedOpts = { ...this.contextOptions, ...opt };
          gl = canvas.getContext(contextName, mergedOpts);
        } else {
          gl = canvas.getContext(contextName, opt);
        }
      } else {
        if (this.contextOptions) {
          gl = canvas.getContext(contextName, this.contextOptions);
        } else {
          gl = canvas.getContext(contextName);
        }
      }
    } catch (e) {
      gl = null;
    }

    if (!gl || typeof gl.getParameter !== 'function') {
      gl = null;
    }

    ++nameIndex;
  }

  this.contextGL = gl;
  if (!gl || gl.isContextLost()) {
    Log_Error(
      `Failed when trying to get WebGLContext2 on canvas(${
        this.canvasElement?.width
      },${this.canvasElement?.height}), gl=${gl}, lost=${gl?.isContextLost()}`
    );
    create_webgl_context_failed_monitor('initWebGL2Fail');
    return;
  }
  gl.glInitSucceed = 0;
};

/**
 * Initialize GL shader program
 */
WebGL2Canvas.prototype.initProgram = function () {
  var gl = this.contextGL;

  const vertexShaderScript = /* glsl */ `#version 300 es
    in vec4 vertexPos;
    in vec4 texturePos;
    in vec4 masktexturePos;
    out vec2 textureCoord;
    out vec2 masktextureCoord;

    void main() {
      gl_Position = vertexPos;
      textureCoord = texturePos.xy;
      masktextureCoord = masktexturePos.xy;
    }
  `;

  const fragmentShaderScript = /* glsl */ `#version 300 es
    precision highp float;
    in highp vec2 textureCoord;
    in highp vec2 masktextureCoord;
    uniform sampler2D ySampler;
    uniform sampler2D uSampler;
    uniform sampler2D vSampler;
    uniform sampler2D cursorSampler;
    uniform sampler2D waterMarkSampler;
    uniform sampler2D  previewVideoSampler;
    uniform sampler2D maskSampler;
    uniform vec4 cursorInfo;
    uniform int onlyRGBA;
    uniform int bgraMode;
    uniform int colorRange;
    uniform int waterMarkFlag;
    uniform int cursorFlag;
    uniform int maskFlag;
    uniform int yuvmode;

    const mat4 YUV2RGB_L = mat4(
      1.1643828125, 0, 1.59602734375, -.87078515625,
      1.1643828125, -.39176171875, -.81296875, .52959375,
      1.1643828125, 2.017234375, 0, -1.081390625,
      0, 0, 0, 1
    );
    
    const mat4 YUV2RGB_F = mat4(
      1.0, 0, 1.402, -.701,
      1.0, -.34413, -.71414, .529135,
      1.0, 1.772, 0, -.886,
      0, 0, 0, 1
    );

    out vec4 outputColor;
  
    void main(void) {
      vec4 c;
      if (waterMarkFlag != 1) {
        if (onlyRGBA == 0) {
          highp float y = texture(ySampler, textureCoord).r;
          highp float u;
          highp float v;
          if (yuvmode == 1) {
            u = texture(uSampler, textureCoord).r;
            v = texture(vSampler, textureCoord).r;
          } else {
            u = texture(uSampler, textureCoord).r;
            v = texture(uSampler, textureCoord).a;
          }
      
          if (colorRange == 0) {
            c = vec4(y, u, v, 1) * YUV2RGB_L;
          } else {
            c = vec4(y, u, v, 1) * YUV2RGB_F;
          }
  
          if (cursorFlag == 1) {
            if (cursorInfo.z > 0.0 && textureCoord.x >= cursorInfo.x && textureCoord.y >= cursorInfo.y && 
                textureCoord.x < cursorInfo.x+cursorInfo.z && textureCoord.y < cursorInfo.y+cursorInfo.w) {
              vec2 cursorCoord = textureCoord - cursorInfo.xy;
              cursorCoord /= cursorInfo.zw;
              vec4 cursor = texture(cursorSampler, cursorCoord);
              c = c*(1.0-cursor.a) + cursor*cursor.a;
            }
          }
        } else {
          c = texture(previewVideoSampler, textureCoord);
          if (bgraMode == 1) {
            c = vec4(c.b, c.g, c.r, c.a);
          }
        }
      }

      if (waterMarkFlag == 1) {
        c = texture(waterMarkSampler, textureCoord);
        if (c.r == 0.0 && c.g == 0.0 && c.b == 0.0) {
          c.a = 0.0;
        }
      }

      if (maskFlag == 1 && waterMarkFlag != 1) {
        vec4 mask = texture(maskSampler, masktextureCoord);
        if (mask.r != 0.0 || mask.g != 0.0 || mask.b != 0.0) {
          c = mask* mask.a+ c*(1.0-mask.a);
        }
      }

      if (waterMarkFlag!=1) {
        c.a = 1.0;
      }

      outputColor = c;
    }
  `;

  var vertexShader = gl.createShader(gl.VERTEX_SHADER);
  gl.shaderSource(vertexShader, vertexShaderScript);
  gl.compileShader(vertexShader);
  if (
    !gl.getShaderParameter(vertexShader, gl.COMPILE_STATUS) &&
    !gl.isContextLost()
  ) {
    globaltracing_DT(
      'webgl2 Vertex shader failed to compile: ' +
        gl.getShaderInfoLog(vertexShader)
    );
  }

  var fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
  gl.shaderSource(fragmentShader, fragmentShaderScript);
  gl.compileShader(fragmentShader);
  if (
    !gl.getShaderParameter(fragmentShader, gl.COMPILE_STATUS) &&
    !gl.isContextLost()
  ) {
    globaltracing_DT(
      'webgl2 Fragment shader failed to compile: ' +
        gl.getShaderInfoLog(fragmentShader)
    );
  }

  var program = gl.createProgram();
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);
  if (!gl.getProgramParameter(program, gl.LINK_STATUS) && !gl.isContextLost()) {
    globaltracing_DT(
      'webgl2 Program failed to compile: ' + gl.getProgramInfoLog(program)
    );
  }

  gl.useProgram(program);

  this.shaderProgram = program;
};

/**
 * Initialize vertex buffers and attach to shader program
 */
WebGL2Canvas.prototype.initBuffers = function () {
  var gl = this.contextGL;
  var program = this.shaderProgram;

  var vertexPosBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, vertexPosBuffer);
  gl.bufferData(
    gl.ARRAY_BUFFER,
    new Float32Array([
      // use for video
      1, 1, -1, 1, 1, -1, -1, -1,
      // use for waterMark
      1, 1, -1, 1, 1, -1, -1, -1,
    ]),
    gl.STATIC_DRAW
  );

  var vertexPosRef = gl.getAttribLocation(program, 'vertexPos');
  gl.enableVertexAttribArray(vertexPosRef);
  gl.vertexAttribPointer(vertexPosRef, 2, gl.FLOAT, false, 0, 0);

  this.vertexPosBuffer = vertexPosBuffer;

  var texturePosBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, texturePosBuffer);
  gl.bufferData(
    gl.ARRAY_BUFFER,
    new Float32Array([
      1, 0, 0, 0, 1, 1, 0, 1,
      // use for waterMark
      1, 0, 0, 0, 1, 1, 0, 1,
    ]),
    gl.STATIC_DRAW
  );

  var texturePosRef = gl.getAttribLocation(program, 'texturePos');
  gl.enableVertexAttribArray(texturePosRef);
  gl.vertexAttribPointer(texturePosRef, 2, gl.FLOAT, false, 0, 0);

  if (this.initmask && !this.masktexturePosBuffer) {
    var masktexturePosBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, masktexturePosBuffer);
    gl.bufferData(
      gl.ARRAY_BUFFER,
      new Float32Array([1, 0, 0, 0, 1, 1, 0, 1]),
      gl.STATIC_DRAW
    );

    var masktexturePosRef = gl.getAttribLocation(program, 'masktexturePos');
    gl.enableVertexAttribArray(masktexturePosRef);
    gl.vertexAttribPointer(masktexturePosRef, 2, gl.FLOAT, false, 0, 0);

    this.masktexturePosBuffer = masktexturePosBuffer;
  }

  this.texturePosBuffer = texturePosBuffer;
};

/**
 * Initialize GL textures and attach to shader program
 */
WebGL2Canvas.prototype.initTextures = function (
  createWaterMarkCursorTextureFlag
) {
  var gl = this.contextGL;
  var program = this.shaderProgram;
  gl.pixelStorei(gl.UNPACK_ALIGNMENT, 1);

  var yTextureRef = this.initTexture();
  this.yTextureRef = yTextureRef;
  this.oyTextureRef = yTextureRef;

  var uTextureRef = this.initTexture();
  this.uTextureRef = uTextureRef;
  this.ouTextureRef = uTextureRef;

  var vTextureRef = this.initTexture();
  this.vTextureRef = vTextureRef;
  this.ovTextureRef = vTextureRef;

  if (createWaterMarkCursorTextureFlag) {
    this.BindTextures(consts.VIDEO_I420);
    var cursorTextureRef = this.initTexture();
    var cursorSamplerRef = gl.getUniformLocation(program, 'cursorSampler');
    gl.uniform1i(cursorSamplerRef, this.textureindex * this.texturestride + 3);
    this.cursorTextureRef = cursorTextureRef;

    var waterMarkTextureRef = this.initTexture();
    var waterMarkSamplerRef = gl.getUniformLocation(
      program,
      'waterMarkSampler'
    );
    gl.uniform1i(waterMarkSamplerRef, 4);
    this.waterMarkTextureRef = waterMarkTextureRef;

    var repeatedWaterMarkTextureRef = this.initTexture();
    this.repeatedWaterMarkTextureRef = repeatedWaterMarkTextureRef;

    var previewVideoTextureRef = this.initTexture();
    var previewVideoSamplerRef = gl.getUniformLocation(
      program,
      'previewVideoSampler'
    );
    gl.uniform1i(
      previewVideoSamplerRef,
      this.textureindex * this.texturestride + 5
    );
    this.previewVideoTextureRef = previewVideoTextureRef;

    var cursorInfoRef = gl.getUniformLocation(program, 'cursorInfo');
    this.cursorInfoRef = cursorInfoRef;
  }
  if (this.initmask) {
    gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, 1);
    var maskTextureRef = this.initTexture();
    var maskSamplerRef = gl.getUniformLocation(program, 'maskSampler');
    gl.uniform1i(maskSamplerRef, this.textureindex * this.texturestride + 6);
    this.maskTextureRef = maskTextureRef;
  }

  var colorRangeRef = gl.getUniformLocation(program, 'colorRange');
  this.colorRangeRef = colorRangeRef;

  this.onlyRGBARef = gl.getUniformLocation(program, 'onlyRGBA');
  this.bgraModeRef = gl.getUniformLocation(program, 'bgraMode');
  this.waterMarkFlagRef = gl.getUniformLocation(program, 'waterMarkFlag');
  this.maskFlagRef = gl.getUniformLocation(program, 'maskFlag');
  this.cursorFlagRef = gl.getUniformLocation(program, 'cursorFlag');
  this.yuvmodeRef = gl.getUniformLocation(program, 'yuvmode');
};

/**
 * Initialize GL textures and attach to shader program
 */
WebGL2Canvas.prototype.BindTextures = function (mode) {
  var gl = this.contextGL;
  var program = this.shaderProgram;
  gl.pixelStorei(gl.UNPACK_ALIGNMENT, 1);
  gl.activeTexture(gl.TEXTURE0 + 0);
  gl.bindTexture(gl.TEXTURE_2D, this.yTextureRef);

  gl.activeTexture(gl.TEXTURE0 + 1);
  gl.bindTexture(gl.TEXTURE_2D, this.uTextureRef);

  gl.activeTexture(gl.TEXTURE0 + 2);
  gl.bindTexture(gl.TEXTURE_2D, this.vTextureRef);

  if (mode == consts.VIDEO_I420) {
    let ySamplerRef = gl.getUniformLocation(program, 'ySampler');
    gl.uniform1i(ySamplerRef, 0);

    let uSamplerRef = gl.getUniformLocation(program, 'uSampler');
    gl.uniform1i(uSamplerRef, 1);

    let vSamplerRef = gl.getUniformLocation(program, 'vSampler');
    gl.uniform1i(vSamplerRef, 2);
  } else if (this.isRGBAMode(mode)) {
    let previewVideoSamplerRef = gl.getUniformLocation(
      program,
      'previewVideoSampler'
    );
    gl.uniform1i(previewVideoSamplerRef, 0);

    let ySamplerRef = gl.getUniformLocation(program, 'ySampler');
    gl.uniform1i(ySamplerRef, 0);

    let uSamplerRef = gl.getUniformLocation(program, 'uSampler');
    gl.uniform1i(uSamplerRef, 0);

    let vSamplerRef = gl.getUniformLocation(program, 'vSampler');
    gl.uniform1i(vSamplerRef, 0);
  } else if (mode == consts.VIDEO_NV12) {
    let ySamplerRef = gl.getUniformLocation(program, 'ySampler');
    gl.uniform1i(ySamplerRef, 0);

    let uSamplerRef = gl.getUniformLocation(program, 'uSampler');
    gl.uniform1i(uSamplerRef, 1);

    let vSamplerRef = gl.getUniformLocation(program, 'vSampler');
    gl.uniform1i(vSamplerRef, 0);
  }

  //because of webgl security, please read https://www.khronos.org/webgl/security/

  let previewVideoSamplerRef = gl.getUniformLocation(
    program,
    'previewVideoSampler'
  );
  gl.uniform1i(previewVideoSamplerRef, 0);

  let maskSamplerRef = gl.getUniformLocation(program, 'maskSampler');
  if (!this.initmask) {
    gl.uniform1i(maskSamplerRef, 0);
  } else {
    gl.activeTexture(gl.TEXTURE0 + 6);
    gl.bindTexture(gl.TEXTURE_2D, this.maskTextureRef);
    gl.uniform1i(maskSamplerRef, 6);
  }

  let cursorSamplerRef = gl.getUniformLocation(program, 'cursorSampler');
  gl.uniform1i(cursorSamplerRef, 0);
  let waterMarkSamplerrRef = gl.getUniformLocation(
    this.shaderProgram,
    'waterMarkSampler'
  );

  gl.uniform1i(waterMarkSamplerrRef, 0);
};

/**
 * Create and configure a single texture
 */
WebGL2Canvas.prototype.initTexture = function () {
  var gl = this.contextGL;

  var textureRef = gl.createTexture();
  gl.bindTexture(gl.TEXTURE_2D, textureRef);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.bindTexture(gl.TEXTURE_2D, null);

  return textureRef;
};

WebGL2Canvas.prototype.clearDisplay = function () {
  var gl = this.contextGL;
  if (gl) {
    gl.enable(gl.BLEND);
    gl.blendFunc(gl.ZERO, gl.ZERO);
  }
  this.render();
};

WebGL2Canvas.prototype.cleanup = function () {
  if (process.env.NODE_ENV === 'development') {
    console.log(`WebGL2Canvas.cleanup`);
  }

  let canvas = this.canvasElement;
  let oldDisplay = canvasToDisplayMap.get(canvas);
  if (oldDisplay) {
    this.removeEventListener(canvas, oldDisplay);
  }

  if (!canvas.defaultContextLostHandler) {
    canvas.defaultContextLostHandler = defaultContextLostCallback;
    canvas.addEventListener('webglcontextlost', defaultContextLostCallback, {
      capture: false,
    });
  }

  if (!this.isAvaiable()) {
    return;
  }

  var gl = this.contextGL;

  gl.deleteProgram(this.program);

  gl.activeTexture(gl.TEXTURE0 + this.textureindex * this.texturestride);
  gl.bindTexture(gl.TEXTURE_2D, null);

  gl.activeTexture(gl.TEXTURE1 + this.textureindex * this.texturestride);
  gl.bindTexture(gl.TEXTURE_2D, null);

  gl.activeTexture(gl.TEXTURE2 + this.textureindex * this.texturestride);
  gl.bindTexture(gl.TEXTURE_2D, null);

  if (!this.textureindex && !this.initmask) {
    gl.activeTexture(gl.TEXTURE3 + this.textureindex * this.texturestride);
    gl.bindTexture(gl.TEXTURE_2D, null);

    gl.activeTexture(gl.TEXTURE4 + this.textureindex * this.texturestride);
    gl.bindTexture(gl.TEXTURE_2D, null);

    gl.activeTexture(this.getRepeatedWatermarkTextureValue(gl));
    gl.bindTexture(gl.TEXTURE_2D, null);

    gl.activeTexture(gl.TEXTURE5 + this.textureindex * this.texturestride);
    gl.bindTexture(gl.TEXTURE_2D, null);
  }

  gl.bindBuffer(gl.ARRAY_BUFFER, null);

  gl.deleteTexture(this.yTextureRef);
  gl.deleteTexture(this.uTextureRef);
  gl.deleteTexture(this.vTextureRef);

  if (!this.textureindex && !this.initmask) {
    gl.deleteTexture(this.cursorTextureRef);
    gl.deleteTexture(this.waterMarkTextureRef);
    gl.deleteTexture(this.repeatedWaterMarkTextureRef);
    gl.deleteTexture(this.previewVideoTextureRef);
    gl.deleteBuffer(this.vertexPosBuffer);
    gl.deleteBuffer(this.texturePosBuffer);
  }
  if (this.maskTextureRef) {
    gl.deleteTexture(this.maskTextureRef);
  }
  if (this.masktexturePosBuffer) {
    gl.deleteBuffer(this.masktexturePosBuffer);
  }
  gl.glInitSucceed = 0;
};

/**
 * Draw picture data to the canvas.
 * If this object is using WebGL, the data must be an I420 formatted ArrayBuffer,
 * Otherwise, data must be an RGBA formatted ArrayBuffer.
 */
WebGL2Canvas.prototype.drawNextOutputPicture = function (
  width,
  height,
  croppingParams,
  data,
  colorRange = 0
) {
  var gl = this.contextGL;

  if (gl) {
    this.drawNextOutputPictureFrame(
      width,
      height,
      croppingParams,
      data,
      colorRange
    );
  } else {
    this.drawNextOuptutPictureRGBA(width, height, croppingParams, data);
  }
};

function updateVertexInfo(
  glCanvas,
  picWidth,
  picHeight,
  rotation,
  cParam = null
) {
  var gl = glCanvas.contextGL;
  let cWidth = gl.canvas.width;
  let cHeight = gl.canvas.height;
  if (cParam) {
    cWidth = cParam.width;
    cHeight = cParam.height;
  }

  var w =
    rotation == glCanvas.ROTATION_CLOCK90 ||
    rotation == glCanvas.ROTATION_CLOCK270
      ? picHeight
      : picWidth;
  var h =
    rotation == glCanvas.ROTATION_CLOCK90 ||
    rotation == glCanvas.ROTATION_CLOCK270
      ? picWidth
      : picHeight;
  var left, top, right, bottom;
  var dw = (w / h) * cHeight;
  var dh = (h / w) * cWidth;

  if (dw > cWidth) {
    left = 0;
    right = 1;
    top = (cHeight - dh) / 2 / cHeight;
    bottom = 1 - top;
  } else {
    top = 0;
    bottom = 1;
    left = (cWidth - dw) / 2 / cWidth;
    right = 1 - left;
  }
  left = left * 2 - 1;
  right = right * 2 - 1;
  top = 1 - top * 2;
  bottom = 1 - bottom * 2;

  var vertexPosValues = new Float32Array([
    right,
    top,
    left,
    top,
    right,
    bottom,
    left,
    bottom,
    // use for watermark
    right,
    top,
    left,
    top,
    right,
    bottom,
    left,
    bottom,
  ]);

  gl.bindBuffer(gl.ARRAY_BUFFER, glCanvas.vertexPosBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, vertexPosValues, gl.DYNAMIC_DRAW);
}

WebGL2Canvas.prototype.updateVertexInfoForMultiView = function (
  glwidth,
  glheight,
  picWidth,
  picHeight,
  rotation
) {
  //for multi-view
  var gl = this.contextGL;
  var left, top, right, bottom;
  if (
    this.isUseFillMode({
      width: picWidth,
      height: picHeight,
      rotation,
    })
  ) {
    left = 0;
    top = 0;
    right = 1;
    bottom = 1;
  } else {
    var w =
      rotation == this.ROTATION_CLOCK90 || rotation == this.ROTATION_CLOCK270
        ? picHeight
        : picWidth;
    var h =
      rotation == this.ROTATION_CLOCK90 || rotation == this.ROTATION_CLOCK270
        ? picWidth
        : picHeight;
    var dw = (w / h) * glheight;
    var dh = (h / w) * glwidth;

    if (dw > glwidth) {
      left = 0;
      right = 1;
      top = (glheight - dh) / 2 / glheight;
      bottom = 1 - top;
    } else {
      top = 0;
      bottom = 1;
      left = (glwidth - dw) / 2 / glwidth;
      right = 1 - left;
    }
  }
  left = left * 2 - 1;
  right = right * 2 - 1;
  top = 1 - top * 2;
  bottom = 1 - bottom * 2;

  var vertexPosValues = new Float32Array([
    right,
    top,
    left,
    top,
    right,
    bottom,
    left,
    bottom,
    // use for waterMark
    1,
    1,
    -1,
    1,
    1,
    -1,
    -1,
    -1,
  ]);

  gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexPosBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, vertexPosValues, gl.DYNAMIC_DRAW);
};

function updateTextureInfo(glCanvas, width, height, croppingParams, rotation) {
  var gl = glCanvas.contextGL;
  var tTop = croppingParams.top / height;
  var tLeft = croppingParams.left / width;
  var tBottom = tTop + (croppingParams.height - 1) / height;
  var tRight = tLeft + croppingParams.width / width;
  var texInfo = [tLeft, tTop, tRight, tTop, tRight, tBottom, tLeft, tBottom];

  if (rotation == glCanvas.ROTATION_CLOCK90) {
    texInfo.unshift(texInfo[6], texInfo[7]);
    texInfo = texInfo.slice(0, 8);
  }
  if (rotation == glCanvas.ROTATION_CLOCK180) {
    texInfo.unshift(texInfo[4], texInfo[5], texInfo[6], texInfo[7]);
    texInfo = texInfo.slice(0, 8);
  }
  if (rotation == glCanvas.ROTATION_CLOCK270) {
    texInfo.push(texInfo[0], texInfo[1]);
    texInfo = texInfo.slice(2);
  }

  var a = texInfo[0];
  var b = texInfo[1];
  texInfo[0] = texInfo[2];
  texInfo[1] = texInfo[3];
  texInfo[2] = a;
  texInfo[3] = b;

  var texturePosValues = new Float32Array([
    ...texInfo,
    // use for waterMark
    1,
    0,
    0,
    0,
    1,
    1,
    0,
    1,
  ]);

  gl.bindBuffer(gl.ARRAY_BUFFER, glCanvas.texturePosBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, texturePosValues, gl.DYNAMIC_DRAW);
}

WebGL2Canvas.prototype.updateTextureInfoForMultiView = function (
  width,
  height,
  croppingParams,
  rotation,
  ismirror,
  glWidth,
  glHeight
) {
  var gl = this.contextGL;
  var tTop, tLeft, tBottom, tRight;
  if (
    this.isUseFillMode({
      width: croppingParams.width,
      height: croppingParams.height,
      rotation,
    })
  ) {
    const drawRatio =
      rotation == this.ROTATION_CLOCK90 || rotation == this.ROTATION_CLOCK270
        ? glHeight / glWidth
        : glWidth / glHeight;
    const originLeft = croppingParams.left || 0;
    const originTop = croppingParams.top || 0;
    if (croppingParams.width / croppingParams.height > drawRatio) {
      /** data is widther than render area */
      const realDrawDataWidth = croppingParams.height * drawRatio;
      tTop = originTop / height;
      tLeft =
        (Math.round((croppingParams.width - realDrawDataWidth) / 2) +
          originLeft) /
        width;
      tBottom = tTop + (croppingParams.height - 1) / height;
      tRight = tLeft + realDrawDataWidth / width;
    } else {
      /** data is heighter than render area */
      const realDrawDataHeight = croppingParams.width / drawRatio;
      tTop =
        (Math.round((croppingParams.height - realDrawDataHeight) / 2) +
          originTop) /
        height;
      tLeft = originLeft / width;
      tBottom = tTop + (realDrawDataHeight - 1) / height;
      tRight = tLeft + croppingParams.width / width;
    }
  } else {
    tTop = expandUvCoords(croppingParams.top / height, 2);
    tLeft = expandUvCoords(croppingParams.left / width, 2);
    tBottom = narrowUvCoords(
      (croppingParams.top + croppingParams.height - 1) / height,
      2
    );
    tRight = narrowUvCoords(
      (croppingParams.width - 1 + croppingParams.left) / width,
      2
    );
  }
  var texInfo = [tLeft, tTop, tRight, tTop, tRight, tBottom, tLeft, tBottom];

  if (rotation == this.ROTATION_CLOCK90) {
    texInfo.unshift(texInfo[6], texInfo[7]);
    texInfo = texInfo.slice(0, 8);
  }
  if (rotation == this.ROTATION_CLOCK180) {
    texInfo.unshift(texInfo[4], texInfo[5], texInfo[6], texInfo[7]);
    texInfo = texInfo.slice(0, 8);
  }
  if (rotation == this.ROTATION_CLOCK270) {
    texInfo.push(texInfo[0], texInfo[1]);
    texInfo = texInfo.slice(2, 10);
  }

  var a = texInfo[0];
  var b = texInfo[1];
  texInfo[0] = texInfo[2];
  texInfo[1] = texInfo[3];
  texInfo[2] = a;
  texInfo[3] = b;
  // original
  // texInfo = [tRight,tTop,tLeft,tTop,tRight, tBottom, tLeft, tBottom]
  //mirror
  //texInfo=[tLeft,tTop,tRight,tTop,tLeft, tBottom, tRight, tBottom]
  if (ismirror) {
    if (
      rotation == this.ROTATION_CLOCK90 ||
      rotation == this.ROTATION_CLOCK270
    ) {
      let temp = texInfo[1];
      texInfo[1] = texInfo[3];
      texInfo[3] = temp;
      temp = texInfo[5];
      texInfo[5] = texInfo[7];
      texInfo[7] = temp;
    } else {
      texInfo[0] = 1 - texInfo[0];
      texInfo[2] = 1 - texInfo[2];
      texInfo[4] = 1 - texInfo[4];
      texInfo[6] = 1 - texInfo[6];
    }
  }

  var texturePosValues = new Float32Array([
    ...texInfo,
    // use for waterMark
    1,
    0,
    0,
    0,
    1,
    1,
    0,
    1,
  ]);

  gl.bindBuffer(gl.ARRAY_BUFFER, this.texturePosBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, texturePosValues, gl.DYNAMIC_DRAW);
};

/**
 * Draw the next output picture using WebGL
 */

WebGL2Canvas.prototype.drawNextOutputPictureFrame = function (
  width,
  height,
  croppingParams,
  data,
  rotation,
  limitedColorRange = true,
  windowOnCanvasVaraible = null,
  bupdateTexture = true
) {
  if (!this.isAvaiable()) {
    return;
  }
  var gl = this.contextGL;
  var texturePosBuffer = this.texturePosBuffer;
  var yTextureRef = this.yTextureRef;
  var uTextureRef = this.uTextureRef;
  var vTextureRef = this.vTextureRef;
  gl.enable(gl.BLEND);
  gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

  rotation = rotation ? rotation : this.ROTATION_CLOCK0;
  croppingParams = croppingParams
    ? croppingParams
    : { top: 0, left: 0, width: width, height: height };

  var picSizeChange =
    croppingParams.width != this.croppingParams.width ||
    croppingParams.height != this.croppingParams.height;
  var picPosChange =
    croppingParams.top != this.croppingParams.top ||
    croppingParams.left != this.croppingParams.left;
  var canvasSizeChange =
    gl.canvas.width != this.canvasWidth ||
    gl.canvas.height != this.canvasHeight;
  var texSizeChange =
    width != this.textureWidth || height != this.textureHeight;
  var rotationChange = rotation != this.picRotation;

  if (picSizeChange || canvasSizeChange || rotationChange) {
    updateVertexInfo(
      this,
      croppingParams.width,
      croppingParams.height,
      rotation,
      windowOnCanvasVaraible
    );
  }
  if (picSizeChange || picPosChange || texSizeChange || rotationChange) {
    updateTextureInfo(this, width, height, croppingParams, rotation);
  }

  let colorRange = limitedColorRange ? 0 : 1;
  if (colorRange != this.colorRange) {
    gl.uniform1i(this.colorRangeRef, colorRange);
    this.colorRange = colorRange;
  }
  if (windowOnCanvasVaraible) {
    gl.viewport(
      windowOnCanvasVaraible.x,
      windowOnCanvasVaraible.y,
      windowOnCanvasVaraible.width,
      windowOnCanvasVaraible.height
    );
  } else {
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
  }

  gl.uniform1i(this.onlyRGBARef, 0);
  gl.uniform1i(this.yuvmodeRef, consts.VIDEO_I420);
  Object.assign(this.croppingParams, croppingParams);
  this.textureWidth = width;
  this.textureHeight = height;
  this.picRotation = rotation;
  this.canvasWidth = gl.canvas.width;
  this.canvasHeight = gl.canvas.height;

  gl.clearColor(this.bgColor[0], this.bgColor[1], this.bgColor[2], 255);
  gl.clear(gl.COLOR_BUFFER_BIT);

  var i420Data = data;

  var yDataLength = width * height;

  gl.activeTexture(gl.TEXTURE0);
  gl.bindTexture(gl.TEXTURE_2D, yTextureRef);
  if (bupdateTexture) {
    var yData = i420Data.subarray(0, yDataLength);
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.LUMINANCE,
      width,
      height,
      0,
      gl.LUMINANCE,
      gl.UNSIGNED_BYTE,
      yData
    );
  }

  var cbDataLength = ((width / 2) * height) / 2;

  gl.activeTexture(gl.TEXTURE1);
  gl.bindTexture(gl.TEXTURE_2D, uTextureRef);
  if (bupdateTexture) {
    var cbData = i420Data.subarray(yDataLength, yDataLength + cbDataLength);
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.LUMINANCE,
      width / 2,
      height / 2,
      0,
      gl.LUMINANCE,
      gl.UNSIGNED_BYTE,
      cbData
    );
  }

  var crDataLength = cbDataLength;

  gl.activeTexture(gl.TEXTURE2);
  gl.bindTexture(gl.TEXTURE_2D, vTextureRef);

  if (bupdateTexture) {
    var crData = i420Data.subarray(
      yDataLength + cbDataLength,
      yDataLength + cbDataLength + crDataLength
    );
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.LUMINANCE,
      width / 2,
      height / 2,
      0,
      gl.LUMINANCE,
      gl.UNSIGNED_BYTE,
      crData
    );
  }

  gl.activeTexture(gl.TEXTURE3);
  gl.bindTexture(gl.TEXTURE_2D, this.cursorTextureRef);
  if (!this.hasCursor) {
    bupdateTexture &&
      gl.texImage2D(
        gl.TEXTURE_2D,
        0,
        gl.RGBA,
        1,
        1,
        0,
        gl.RGBA,
        gl.UNSIGNED_BYTE,
        this.dummpyCursor
      );
  } else {
    gl.uniform1i(this.cursorFlagRef, 1);
  }

  gl.uniform4f(this.cursorInfoRef, this.cx, this.cy, this.cw, this.ch);

  gl.activeTexture(gl.TEXTURE5);
  gl.bindTexture(gl.TEXTURE_2D, this.previewVideoTextureRef);
  gl.texImage2D(
    gl.TEXTURE_2D,
    0,
    gl.RGBA,
    1,
    1,
    0,
    gl.RGBA,
    gl.UNSIGNED_BYTE,
    this.dummpyWaterMark
  );

  var maskSamplerRef = gl.getUniformLocation(this.shaderProgram, 'maskSampler');
  gl.uniform1i(maskSamplerRef, 5);
  this.render();
  this.hasWholeFrame = 1;
};

WebGL2Canvas.prototype.updateTextureBlock = function (
  width,
  height,
  x,
  y,
  data
) {
  if (!this.isAvaiable()) {
    return;
  }
  var gl = this.contextGL;

  var i420Data = data;

  if (
    !this.hasWholeFrame ||
    width <= 0 ||
    height <= 0 ||
    x < 0 ||
    y < 0 ||
    x + width > this.textureWidth ||
    y + height > this.textureHeight ||
    !data ||
    data.length != (width * height * 3) / 2
  ) {
    return;
  }

  var yTextureRef = this.yTextureRef;
  var uTextureRef = this.uTextureRef;
  var vTextureRef = this.vTextureRef;

  var yDataLength = width * height;
  var yData = i420Data.subarray(0, yDataLength);
  gl.activeTexture(gl.TEXTURE0);
  gl.bindTexture(gl.TEXTURE_2D, yTextureRef);
  gl.texSubImage2D(
    gl.TEXTURE_2D,
    0,
    x,
    y,
    width,
    height,
    gl.LUMINANCE,
    gl.UNSIGNED_BYTE,
    yData
  );

  var cbDataLength = ((width / 2) * height) / 2;
  var cbData = i420Data.subarray(yDataLength, yDataLength + cbDataLength);
  gl.activeTexture(gl.TEXTURE1);
  gl.bindTexture(gl.TEXTURE_2D, uTextureRef);
  gl.texSubImage2D(
    gl.TEXTURE_2D,
    0,
    x / 2,
    y / 2,
    width / 2,
    height / 2,
    gl.LUMINANCE,
    gl.UNSIGNED_BYTE,
    cbData
  );

  var crDataLength = cbDataLength;
  var crData = i420Data.subarray(
    yDataLength + cbDataLength,
    yDataLength + cbDataLength + crDataLength
  );
  gl.activeTexture(gl.TEXTURE2);
  gl.bindTexture(gl.TEXTURE_2D, vTextureRef);
  gl.texSubImage2D(
    gl.TEXTURE_2D,
    0,
    x / 2,
    y / 2,
    width / 2,
    height / 2,
    gl.LUMINANCE,
    gl.UNSIGNED_BYTE,
    crData
  );
};

WebGL2Canvas.prototype.updateCursor = function (width, height, data) {
  if (!this.isAvaiable()) {
    return;
  }
  var gl = this.contextGL;

  if (width <= 0 || height <= 0 || !data || data.length != width * height * 4) {
    return;
  }

  gl.activeTexture(gl.TEXTURE3);
  gl.bindTexture(gl.TEXTURE_2D, this.cursorTextureRef);
  gl.texImage2D(
    gl.TEXTURE_2D,
    0,
    gl.RGBA,
    width,
    height,
    0,
    gl.RGBA,
    gl.UNSIGNED_BYTE,
    data
  );

  this.cursorWidth = width;
  this.cursorHeight = height;
  this.hasCursor = 1;
};

WebGL2Canvas.prototype.updateWatermark = function (width, height, data) {
  if (!this.isAvaiable()) {
    return;
  }
  var gl = this.contextGL;

  if (width <= 0 || height <= 0 || !data || data.length != width * height * 4) {
    return;
  }
  this.watermarkData = data;
  this.watermarkWidth = width;
  this.watermarkHeight = height;
  this.hasWaterMark = 1;
};

WebGL2Canvas.prototype.drawWatermark = function () {
  if (!this.isAvaiable()) {
    return;
  }
  var gl = this.contextGL;
  if (
    this.isSetWatermark() &&
    this.watermarkData &&
    this.watermarkWidth &&
    this.watermarkHeight
  ) {
    gl.uniform1i(this.waterMarkFlagRef, 1);
    if (this.isWatermarkRepeated()) {
      gl.activeTexture(this.getRepeatedWatermarkTextureValue(gl));
      gl.bindTexture(gl.TEXTURE_2D, this.repeatedWaterMarkTextureRef);
    } else {
      gl.activeTexture(gl.TEXTURE4);
      gl.bindTexture(gl.TEXTURE_2D, this.waterMarkTextureRef);
    }
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      this.watermarkWidth,
      this.watermarkHeight,
      0,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      this.watermarkData
    );
    let waterMarkSamplerrRef = gl.getUniformLocation(
      this.shaderProgram,
      'waterMarkSampler'
    );

    gl.uniform1i(
      waterMarkSamplerrRef,
      this.isWatermarkRepeated() ? this.getRepeatedWatermarkUniformValue() : 4
    );
    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    gl.drawArrays(gl.TRIANGLE_STRIP, 4, 4);
  }
};

WebGL2Canvas.prototype.render = function () {
  if (!this.isAvaiable()) {
    return;
  }
  var gl = this.contextGL;
  gl.uniform1i(this.waterMarkFlagRef, 0);
  gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
  this.drawWatermark();
  if (process.env.NODE_ENV === 'development') {
    let error = gl.getError();
    if (error != gl.NO_ERROR) {
      globaltracing_DT(`Error while draw webgl2:${error}`);
    }
  }
};

WebGL2Canvas.prototype.drawCursor = function (
  withCursor,
  cursorX,
  cursorY,
  cursorLogicWidth,
  cursorLogicHeight
) {
  if (!this.isAvaiable()) {
    return;
  }
  var gl = this.contextGL;

  if (
    !this.hasWholeFrame ||
    (withCursor && (cursorLogicWidth < 0 || cursorLogicHeight < 0))
  ) {
    return;
  }

  gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

  var yTextureRef = this.yTextureRef;
  var uTextureRef = this.uTextureRef;
  var vTextureRef = this.vTextureRef;
  var cursorTextureRef = this.cursorTextureRef;

  gl.activeTexture(gl.TEXTURE0);
  gl.bindTexture(gl.TEXTURE_2D, yTextureRef);

  gl.activeTexture(gl.TEXTURE1);
  gl.bindTexture(gl.TEXTURE_2D, uTextureRef);

  gl.activeTexture(gl.TEXTURE2);
  gl.bindTexture(gl.TEXTURE_2D, vTextureRef);

  gl.activeTexture(gl.TEXTURE3);
  gl.bindTexture(gl.TEXTURE_2D, cursorTextureRef);

  if (withCursor && this.hasCursor) {
    let cx = cursorX / this.croppingParams.width;
    let cy = cursorY / this.croppingParams.height;
    let cw = cursorLogicWidth / this.croppingParams.width;
    let ch = cursorLogicHeight / this.croppingParams.height;
    this.cx = cx;
    this.cy = cy;
    this.cw = cw;
    this.ch = ch;

    gl.uniform4f(this.cursorInfoRef, cx, cy, cw, ch);
  } else {
    gl.uniform4f(this.cursorInfoRef, 0, 0, 0, 0);
  }
  this.render();
};

WebGL2Canvas.prototype.clear = function (clearCanvas = true) {
  this.hasWholeFrame = 0;
  this.hasCursor = 0;
  return;
};

WebGL2Canvas.prototype.clearCanvas = function (clearColor) {
  if (!this.isAvaiable()) {
    return;
  }
  var gl = this.contextGL;
  if (clearColor) {
    gl.clearColor(clearColor.R, clearColor.G, clearColor.B, clearColor.A);
  } else {
    gl.clearColor(this.bgColor[0], this.bgColor[1], this.bgColor[2], 255);
  }
  gl.clear(gl.COLOR_BUFFER_BIT);
};

/**
 * Draw next output picture using ARGB data on a 2d canvas.
 */
WebGL2Canvas.prototype.drawNextOuptutPictureRGBA = function (
  width,
  height,
  croppingParams,
  data
) {
  if (!this.isAvaiable()) {
    return;
  }
  var canvas = this.canvasElement;

  var croppingParams = null;
  var argbData = data;

  var ctx = canvas.getContext('2d');
  var imageData = ctx.getImageData(0, 0, width, height);
  imageData.data.set(argbData);

  if (croppingParams === null) {
    ctx.putImageData(imageData, 0, 0);
  } else {
    ctx.putImageData(
      imageData,
      -croppingParams.left,
      -croppingParams.top,
      0,
      0,
      croppingParams.width,
      croppingParams.height
    );
  }
};

WebGL2Canvas.prototype.isRGBAMode = function (mode) {
  return [consts.VIDEO_RGBA, consts.VIDEO_BGRA].indexOf(mode) !== -1;
};

WebGL2Canvas.prototype.updateRemoteVideoTextures = function (
  width,
  height,
  croppingParams,
  data,
  rotation,
  limitedColorRange = true,
  viewPortParams,
  isolddata,
  updateFlag = true
) {
  if (!this.isAvaiable()) {
    return;
  }
  var gl = this.contextGL;
  var yTextureRef = this.yTextureRef;
  var uTextureRef = this.uTextureRef;
  var vTextureRef = this.vTextureRef;

  gl.enable(gl.BLEND);
  gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

  const isRGBAMode = this.isRGBAMode(this.videoMode);

  if (
    width <= 0 ||
    height <= 0 ||
    !data ||
    !data.length ||
    (data.length != (width * height * 3) / 2 && !isRGBAMode) ||
    (croppingParams &&
      (croppingParams.top < 0 ||
        croppingParams.left < 0 ||
        croppingParams.left + croppingParams.width > width ||
        croppingParams.top + croppingParams.height > height))
  ) {
    return false;
  }

  let colorRange = limitedColorRange ? 0 : 1;
  this.colorRange = colorRange;
  this.rotation = rotation;
  Object.assign(this.croppingParams, croppingParams);
  this.textureWidth = width;
  this.textureHeight = height;

  this.canvasWidth = gl.canvas.width;
  this.canvasHeight = gl.canvas.height;

  if (!updateFlag) {
    return;
  }

  gl.bindTexture(gl.TEXTURE_2D, yTextureRef);
  if (isRGBAMode) {
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      width,
      height,
      0,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      data
    );
    return;
  }

  var i420Data = data;
  var yDataLength = width * height;
  var yData = i420Data.subarray(0, yDataLength);
  gl.texImage2D(
    gl.TEXTURE_2D,
    0,
    gl.LUMINANCE,
    width,
    height,
    0,
    gl.LUMINANCE,
    gl.UNSIGNED_BYTE,
    yData
  );

  let cbDataLength = 0;
  let crDataLength = 0;
  if (this.videoMode == consts.VIDEO_I420) {
    cbDataLength = ((width / 2) * height) / 2;
    crDataLength = cbDataLength;
  } else if (this.videoMode == consts.VIDEO_NV12) {
    cbDataLength = (width * height) / 2;
    crDataLength = 0;
  }

  var cbData = i420Data.subarray(yDataLength, yDataLength + cbDataLength);
  gl.bindTexture(gl.TEXTURE_2D, uTextureRef);

  if (crDataLength) {
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.LUMINANCE,
      width / 2,
      height / 2,
      0,
      gl.LUMINANCE,
      gl.UNSIGNED_BYTE,
      cbData
    );
    var crData = i420Data.subarray(
      yDataLength + cbDataLength,
      yDataLength + cbDataLength + crDataLength
    );
    gl.bindTexture(gl.TEXTURE_2D, vTextureRef);
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.LUMINANCE,
      width / 2,
      height / 2,
      0,
      gl.LUMINANCE,
      gl.UNSIGNED_BYTE,
      crData
    );
  } else {
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.LUMINANCE_ALPHA,
      width / 2,
      height / 2,
      0,
      gl.LUMINANCE_ALPHA,
      gl.UNSIGNED_BYTE,
      cbData
    );
  }

  return true;
};

WebGL2Canvas.prototype.updateRemoteVideoTexturesImageBitmap = function (
  width,
  height,
  data,
  croppingParams,
  rotation,
  updateFlag = true
) {
  if (width <= 0 || height <= 0 || !data) {
    return;
  }
  if (!this.isAvaiable()) {
    return;
  }
  var gl = this.contextGL;

  this.textureWidth = width;
  this.textureHeight = height;
  if (!Number.isNaN(rotation)) {
    this.rotation = rotation;
  }
  Object.assign(this.croppingParams, croppingParams);

  if (!updateFlag) {
    return;
  }

  gl.bindTexture(gl.TEXTURE_2D, this.yTextureRef);
  const level = 0;
  const internalFormat = gl.RGBA;
  const srcFormat = gl.RGBA;
  const srcType = gl.UNSIGNED_BYTE;
  gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, srcFormat, srcType, data);
};

WebGL2Canvas.prototype.updateSelfMaskImage = function (width, height, data) {
  if (width <= 0 || height <= 0 || !data || data.length != width * height * 4) {
    return;
  }
  if (!this.isAvaiable()) {
    return;
  }
  var gl = this.contextGL;
  gl.bindTexture(gl.TEXTURE_2D, this.maskTextureRef);
  gl.texImage2D(
    gl.TEXTURE_2D,
    0,
    gl.RGBA,
    width,
    height,
    0,
    gl.RGBA,
    gl.UNSIGNED_BYTE,
    data
  );
};

WebGL2Canvas.prototype.VideoFlip = function () {
  if (!this.isAvaiable()) {
    return;
  }
  var gl = this.contextGL;
  gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, 1);
};

WebGL2Canvas.prototype.drawRemoteVideo = function (
  viewPortParams,
  ismirror = false
) {
  if (!this.isAvaiable()) {
    return;
  }
  var gl = this.contextGL;
  let rgba = this.isRGBAMode(this.videoMode) ? 1 : 0;

  gl.uniform1i(this.colorRangeRef, this.colorRange);
  this.setUniformFlag(rgba, this.hasCursor, this.videoMode);
  if (this.initmask) {
    gl.uniform1i(this.maskFlagRef, 1);
  }
  this.updateTextureInfoForMultiView(
    this.textureWidth,
    this.textureHeight,
    this.croppingParams,
    this.rotation,
    ismirror,
    viewPortParams.width,
    viewPortParams.height
  );
  gl.viewport(
    viewPortParams.x,
    viewPortParams.y,
    viewPortParams.width,
    viewPortParams.height
  );
  this.updateVertexInfoForMultiView(
    viewPortParams.width,
    viewPortParams.height,
    this.croppingParams.width,
    this.croppingParams.height,
    this.rotation
  );
  this.BindTextures(this.videoMode);
  gl.enable(gl.BLEND);
  gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
  this.render();
};
WebGL2Canvas.prototype.readPixelsSyncRequest = function (x, y, w, h) {
  if (!this.isAvaiable()) {
    return;
  }
  var gl = this.contextGL;
  var dest;
  if (this.destination && this.destination.length == w * h * 4) {
    dest = this.destination;
  } else {
    this.destination = new Uint8Array(w * h * 4);
    dest = this.destination;
  }
  gl.flush();
  gl.readPixels(x, y, w, h, gl.RGBA, gl.UNSIGNED_BYTE, dest);
  return dest;
};

// update self rgba texture
WebGL2Canvas.prototype.updateSelfVideoTextures = function (
  width,
  height,
  data,
  croppingParams,
  updateFlag = true,
  ratotion = 0
) {
  if (width <= 0 || height <= 0 || !data || data.length % 4 != 0) {
    return;
  }
  if (!this.isAvaiable()) {
    return;
  }
  var gl = this.contextGL;
  this.textureWidth = width;
  this.textureHeight = height;
  this.rotation = ratotion;

  Object.assign(this.croppingParams, croppingParams);

  if (!updateFlag) {
    return;
  }

  gl.bindTexture(gl.TEXTURE_2D, this.yTextureRef);
  gl.texImage2D(
    gl.TEXTURE_2D,
    0,
    gl.RGBA,
    width,
    height,
    0,
    gl.RGBA,
    gl.UNSIGNED_BYTE,
    data
  );
};

WebGL2Canvas.prototype.drawSelfVideo = function (
  viewPortParams,
  isclearself = false,
  ismirror = false
) {
  if (!this.isAvaiable()) {
    return;
  }
  var gl = this.contextGL;
  this.setUniformFlag(1, this.hasCursor, this.videoMode);
  this.updateTextureInfoForMultiView(
    this.textureWidth,
    this.textureHeight,
    this.croppingParams,
    this.rotation,
    ismirror,
    viewPortParams.width,
    viewPortParams.height
  );
  gl.viewport(
    viewPortParams.x,
    viewPortParams.y,
    viewPortParams.width,
    viewPortParams.height
  );
  if (!isclearself) {
    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    this.updateVertexInfoForMultiView(
      viewPortParams.width,
      viewPortParams.height,
      this.croppingParams.width,
      this.croppingParams.height,
      this.rotation
    );
  } else {
    // when enable session branding, there is a bg img, need to clear with alpha 0
    gl.enable(gl.BLEND);
    gl.blendFunc(gl.ZERO, gl.ZERO);
    this.updateVertexInfoForMultiView(
      viewPortParams.width,
      viewPortParams.height,
      viewPortParams.width,
      viewPortParams.height,
      this.ROTATION_CLOCK0
    );
  }
  this.BindTextures(consts.VIDEO_RGBA);
  this.render();
};

WebGL2Canvas.prototype.isSetWatermark = function () {
  return this.hasWaterMark;
};

WebGL2Canvas.prototype.recoverTextures = function () {
  // this.yTextureRef = this.oyTextureRef;
  // this.uTextureRef = this.ouTextureRef;
  // this.vTextureRef = this.ovTextureRef;
  // this.videoMode = consts.VIDEO_INVALID;
  // this.reuse = false;
};

WebGL2Canvas.prototype.setWatermarkFlag = function (flag) {
  this.hasWaterMark = flag;
  if (!flag) {
    this.setWatermarkRepeated(false);
    this.setWatermarkOpacity();
    this.setWatermarkPosition(16);
  }
};

WebGL2Canvas.prototype.setUniformFlag = function (
  rgbaFlag,
  cursorFlag,
  videoMode
) {
  if (!this.isAvaiable()) {
    return;
  }
  var gl = this.contextGL;
  gl.uniform1i(this.onlyRGBARef, rgbaFlag);
  // bgra need this flag to change fragment color vec order
  gl.uniform1i(
    this.bgraModeRef,
    rgbaFlag && videoMode === consts.VIDEO_BGRA ? 1 : 0
  );
  gl.uniform1i(this.cursorFlagRef, cursorFlag);
  if (!rgbaFlag) {
    gl.uniform1i(this.yuvmodeRef, videoMode);
  }
};

WebGL2Canvas.prototype.setVideoMode = function (mode) {
  this.videoMode = mode;
};

WebGL2Canvas.prototype.getVideoMode = function (mode) {
  return this.videoMode;
};

WebGL2Canvas.prototype.setWatermarkRepeated = function (watermarkRepeated) {
  this.watermarkRepeated = watermarkRepeated;
};

WebGL2Canvas.prototype.isWatermarkRepeated = function () {
  return !!this.watermarkRepeated;
};

WebGL2Canvas.prototype.setWatermarkOpacity = function (watermarkOpacity) {
  this.watermarkOpacity = watermarkOpacity || 0.15;
};

WebGL2Canvas.prototype.getWatermarkOpacity = function () {
  return this.watermarkOpacity;
};

WebGL2Canvas.prototype.setWatermarkPosition = function (watermarkPosition) {
  this.watermarkPosition = watermarkPosition || 16;
};

WebGL2Canvas.prototype.getWatermarkPosition = function () {
  return this.watermarkPosition;
};

WebGL2Canvas.prototype.setMultiView = function (isMultiView) {
  return (this.isMultiView = isMultiView);
};

WebGL2Canvas.prototype.getRepeatedWatermarkUniformValue = function () {
  return this.isMultiView ? 30 : 7;
};

WebGL2Canvas.prototype.getRepeatedWatermarkTextureValue = function (gl) {
  return this.isMultiView ? gl.TEXTURE30 : gl.TEXTURE7;
};

/**
 * @description set video render fill mode
 * @param {*} fillMode video render fill mode
 * @param {*} fillModeForResolution fill mode only works in specific resolution if the value is not falsy
 */
WebGL2Canvas.prototype.setFillMode = function (
  fillMode = 0,
  fillModeForResolution = 0
) {
  this.fillMode = fillMode;
  this.fillModeForResolution = fillModeForResolution;
};

WebGL2Canvas.prototype.getFillMode = function () {
  return this.fillMode;
};

WebGL2Canvas.prototype.getFillModeForResolution = function () {
  return this.fillModeForResolution;
};

WebGL2Canvas.prototype.getTextureIndex = function () {
  return this.textureindex;
};

WebGL2Canvas.prototype.getIndex = function () {
  return this.textureindex;
};

WebGL2Canvas.prototype.getWatermarkWidth = function () {
  return this.watermarkWidth;
};

WebGL2Canvas.prototype.getWatermarkHeight = function () {
  return this.watermarkHeight;
};

WebGL2Canvas.prototype.getTextureWidth = function () {
  return this.textureWidth;
};

WebGL2Canvas.prototype.getTextureHeight = function () {
  return this.textureHeight;
};

WebGL2Canvas.prototype.getCroppingParams = function () {
  return this.croppingParams;
};

WebGL2Canvas.prototype.getWatermarkOpacity = function () {
  return this.watermarkOpacity;
};

WebGL2Canvas.prototype.getAttachedCanvas = function () {
  return this.canvasElement;
};

WebGL2Canvas.prototype.resizeCanvasTo = function (width, height) {
  this.contextGL.canvas.width = width;
  this.contextGL.canvas.height = height;
};

/**
 * @decription if current display is using fill mode to draw
 */
WebGL2Canvas.prototype.isUseFillMode = function ({ width, height, rotation }) {
  if (!this.fillMode) return false;
  if (!this.fillModeForResolution) return true;
  if (!width || !height) return false;
  const currentResoltion =
    rotation === this.ROTATION_CLOCK90 || rotation == this.ROTATION_CLOCK270
      ? height / width
      : width / height;
  return (
    Array.isArray(this.fillModeForResolution)
      ? this.fillModeForResolution
      : [this.fillModeForResolution]
  ).some((resolution) => Math.abs(currentResoltion - resolution) < 0.01);
};

export default WebGL2Canvas;
