import { ReportStatic } from '../../statistic/utils';
import { ConsumeRB, RingBuffer } from '../ZoomSharedArrayBufferUtils';
import * as SdkEvent from '../sdkEvent';
import { MediaDataChannel } from './mediadatachannel';
import { DataTransportManager } from './transportmgr';
export const TRANSPORT_TYPE = {
  AUDIO_DECODE: 1 << 0,
  AUDIO_ENCODE: 1 << 1,
  VIDEO_DECODE: 1 << 2,
  VIDEO_ENCODE: 1 << 3,
  SHARR_DECODE: 1 << 4,
  SHARR_ENCODE: 1 << 5,
};

const dumyFun = (data) => {
  if (process.env.NODE_ENV === 'development') {
    console.error(`<<<< not implemented ${data?.[0]} ,${data?.[1]}`);
  }
};

export class DumySocket {
  static OPEN = 1;
  static CLOSED = 2;
  constructor() {
    this.onmessage = dumyFun;
    this.status = DumySocket.CLOSED;
    this.onopen = dumyFun;
    this.onclose = dumyFun;
    this.onwer = null;
  }
  send(data) {}
  delete() {
    this.onmessage = dumyFun;
    this.onopen = dumyFun;
    this.onclose = dumyFun;
    this.close();
  }
  sendVideo(head, body) {}
  sendWasm(wasm) {}
  open() {
    this.status = DumySocket.OPEN;
    this.onopen();
  }
  close() {
    this.status = DumySocket.CLOSED;
    this.onclose();
  }
}

export class MsgQueueSocket extends DumySocket {
  constructor() {
    super({});
    this.sab = {};
    this.port = null;
    this.onmessage = dumyFun;
    this.sender = dumyFun;
    this.videoSender = dumyFun;
    this.reciver = dumyFun;
    this.wasmSender = dumyFun;
  }

  send(data) {
    this.sender(data);
  }

  sendVideo(head, body) {
    this.videoSender(head, body);
  }

  sendWasm(wasmbuffer) {
    this.wasmSender(wasmbuffer);
  }

  delete() {
    try {
      this.onmessage = dumyFun;
      this.sender = dumyFun;
      this.videoSender = dumyFun;
      this.reciver = dumyFun;
      this.wasmSender = dumyFun;
      let { consumer } = this.sab?.reciver || {};
      consumer?.setDataCallback(dumyFun);
      consumer?.cancelConsume();
      this.sab = {};
      if (this.port) this.port.onmessage = dumyFun;
      this.port?.close();
    } catch (e) {}
  }

  open() {
    if (this.status == DumySocket.OPEN) {
      this.onopen();
      return;
    }
  }
  close() {
    this.status = DumySocket.CLOSED;
    this.delete();
    this.onclose();
  }

  _onmessage(event) {
    let { cmd, data } = event.data;
    switch (cmd) {
      case SdkEvent.MSG_BUFFER_TICKT:
        this.reciver();
        break;
      case SdkEvent.MSG_QUEUE_DATA:
        this.onmessage(data, 0);
        break;
      case SdkEvent.MSG_QUEUE_STATUS:
        this.status = data;
        this.status == DumySocket.OPEN ? this.onopen() : this.onclose();
        break;
    }
  }
  createSendAndReceive() {
    if (!this.port) {
      return;
    }

    let { sender, reciver } = this.sab;
    let { sabqueue: sendqueue, interval: sendinterval } = sender || {};

    if (sendqueue) {
      if (sendinterval) {
        this.sender = (data) => {
          sendqueue.enqueue(data);
        };

        this.wasmSender = (data) => {
          sendqueue.enqueue(data);
        };

        this.videoSender = (head, body) => {
          if (!sendqueue.enqueueSafe([head, body], false)) {
            let uint8s = new Uint8Array(body.length + head.length);
            uint8s.set(head, 0);
            uint8s.set(body, head.length);
            sendqueue.enqueueSafe(uint8s);
          }
        };
      } else {
        this.sender = (data) => {
          sendqueue.enqueue(data);
          this.port.postMessage({ cmd: SdkEvent.MSG_BUFFER_TICKT });
        };

        this.wasmSender = (data) => {
          sendqueue.enqueue(data);
          this.port.postMessage({ cmd: SdkEvent.MSG_BUFFER_TICKT });
        };

        this.videoSender = (head, body) => {
          if (!sendqueue.enqueueSafe([head, body], false)) {
            let uint8s = new Uint8Array(body.length + head.length);
            uint8s.set(head, 0);
            uint8s.set(body, head.length);
            sendqueue.enqueueSafe(uint8s);
          }
          this.port.postMessage({ cmd: SdkEvent.MSG_BUFFER_TICKT });
        };
      }
    } else {
      this.sender = (data) => {
        this.port.postMessage({ cmd: SdkEvent.MSG_QUEUE_DATA, data: data }, [
          data.buffer,
        ]);
      };

      this.wasmSender = (data) => {
        let uint8s = new Uint8Array(data.length);
        uint8s.set(data, 0);
        this.port.postMessage({ cmd: SdkEvent.MSG_QUEUE_DATA, data: uint8s }, [
          uint8s.buffer,
        ]);
      };

      this.videoSender = (head, body) => {
        let uint8s = new Uint8Array(body.length + head.length);
        uint8s.set(head, 0);
        uint8s.set(body, head.length);
        this.port.postMessage({ cmd: SdkEvent.MSG_QUEUE_DATA, data: uint8s }, [
          uint8s.buffer,
        ]);
      };
    }

    let {
      sabqueue: recivequeue,
      consumer,
      useCopy,
      interval: recvinterval,
      offset: iswasmmem,
    } = reciver || {};

    if (consumer) {
      consumer.cancelConsume();
      consumer = null;
    }
    if (recivequeue) {
      const callback = useCopy
        ? (data) => {
            this.onmessage(data, 0);
          }
        : iswasmmem
        ? (data) => {
            this.onmessage(data.uint8s, data.begin);
          }
        : (data) => {
            this.onmessage(data.uint8s, 0);
          };

      let monitorfn = null;
      let monitorlogfn = DataTransport.dataTransportMgr.monitorlogfn;
      if (recvinterval && monitorlogfn) {
        let report = new ReportStatic({
          tag: 'WCL_M,VDRB' + this.onwer?.type,
          interval: 10000,
          reportcallback: _report_static_monitor,
        });

        monitorfn = report.timeoutReport.bind(report);
      }
      consumer = new ConsumeRB(recivequeue, callback, monitorfn);

      reciver.consumer = consumer;

      if (recvinterval) {
        consumer.consume(recvinterval, useCopy);
      } else {
        this.reciver = () => {
          consumer.consumeAll(useCopy);
        };
      }
    }
  }
  setMsgPort(port) {
    if (port == this.port) {
      return;
    }
    if (this.port) {
      this.port.onmessage = dumyFun;
      this.port.close();
      this.port = null;
    }

    this.port = port;
    if (this.port) {
      if (!this._listeners) this._listeners = this._onmessage.bind(this);
      this.port.onmessage = this._listeners;
      this.createSendAndReceive();
    }
  }
  setSabBuffer(sender, reciver) {
    if (sender?.sab) {
      let { sab, useCopy, interval, offset, length, useOneElement } = sender;

      let sabqueue = new RingBuffer(
        offset > 0 ? sab.buffer : sab,
        undefined,
        undefined,
        !!useOneElement,
        offset,
        length,
        offset > 0 ? sab : null
      );
      this.sab.sender = { sabqueue, interval, useCopy, offset };
    }
    if (reciver?.sab) {
      let { sab, useCopy, interval, offset, length, useOneElement } = reciver;

      let sabqueue = new RingBuffer(
        offset > 0 ? sab.buffer : sab,
        undefined,
        undefined,
        !!useOneElement,
        offset,
        length,
        offset > 0 ? sab : null
      );
      let { consumer } = this.sab?.reciver || {};
      if (consumer) {
        consumer.cancelConsume();
        this.sab.reciver.consumer = null;
        this.sab.reciver.sabqueue = null;
      }
      this.sab.reciver = {
        sabqueue,
        interval,
        useCopy,
        offset,
      };
    }

    this.createSendAndReceive();
  }

  setStatus(status) {
    if (!this.port) {
      console.error('MsgQueueSocket not initialized');
      return;
    }

    if (this.status == status) {
      return;
    }
    this.status = status;
    this.port.postMessage({
      cmd: SdkEvent.MSG_QUEUE_STATUS,
      data: status,
    });
  }
}

export class DataTransport {
  static UDP = 0;
  static TCP = 1;
  static RLB_UDP = 2;
  static dataTransportMgr = null;

  constructor(args) {
    this.onmessage = dumyFun;
    this.onopen = dumyFun;
    this.onclose = dumyFun;
    this.connect_type = args.connect_type || DataTransport.UDP;
    this.type = args.type;
    this.id = args.id || (Math.floor(performance.now()) << 10) | args.type;
    this.sock = args.sock || new DumySocket();

    this.mgr = args.mgr;
    this.sock.onmessage = this._onmessage.bind(this);
    this.sock.onclose = this._onclose.bind(this);
    this.sock.onopen = this._onopen.bind(this);
    this.sock.onwer = this;
    this.remote = args.remote;
    this.sabInfo = null;
    this.portInfo = null;
    this.target_thread = MediaDataChannel.NO_THREAD;
    this.local = !!args.local;
    this._create();
  }

  _create() {
    let mgr = DataTransport.dataTransportMgr;
    mgr.transportlists.push(this);
    if (process.env.NODE_ENV === 'development') {
      console.error('<<<<  mgr.transportlists', mgr.transportlists);
    }
    if (
      !this.local &&
      mgr &&
      mgr.mainThread &&
      mgr.type == DataTransportManager.THREAD_SUB
    ) {
      mgr.createRemoteTransport(this, mgr.mainThread);
    }
  }
  _close() {
    let mgr = DataTransport.dataTransportMgr;
    let idx = mgr.transportlists.indexOf(this);
    if (idx != -1) {
      mgr.transportlists.splice(idx, 1);
    }
    if (
      !this.local &&
      mgr &&
      mgr.mainThread &&
      mgr.type == DataTransportManager.THREAD_SUB
    ) {
      mgr.closeRemoteTransport(this, mgr.mainThread);
    }
  }

  _onmessage(data, offset) {
    this.onmessage(data, offset);
  }
  _onclose() {
    this.onclose();
  }
  _onopen() {
    this.onopen();
  }
  isReady() {
    return true;
  }

  send(data) {
    this.sock.send(data);
  }
  sendVideo(head, body) {
    this.sock.sendVideo(head, body);
  }
  sendWasmData(wasm) {
    this.sock.sendWasm(wasm);
  }

  setSocket(socket) {
    let temp = this.sock;
    this.sock = socket;
    if (this.sock) {
      this.sock.onwer = this;
      this.sock.onmessage = this._onmessage.bind(this);
      this.sock.onclose = this._onclose.bind(this);
      this.sock.onopen = this._onopen.bind(this);
    }
    if (temp) {
      temp.delete();
    }
  }

  open() {
    this.sock.open();
  }
  close() {
    this._close();
    this.sock.close();
  }

  setMsgPort(port) {
    if (!(this.sock instanceof MsgQueueSocket)) {
      throw new Error('tansport sock is not a MsgQueueSocket');
    }
    this.sock.setMsgPort(port);
  }

  setSabBuffer(sender, reciver) {
    if (!(this.sock instanceof MsgQueueSocket)) {
      throw new Error('tansport sock is not a MsgQueueSocket');
    }
    this.sock.setSabBuffer(sender, reciver);
  }

  setStatus(status) {
    if (!(this.sock instanceof MsgQueueSocket)) {
      return;
    }
    this.sock.setStatus(status);
  }
}

export class DumyTransport {
  constructor(options) {
    this.sock = null;
    this.onmessage = dumyFun;
  }
  isReady() {
    return false;
  }

  send() {
    dumyFun();
  }

  setStatus(status) {
    if (process.env.NODE_ENV === 'development') {
      console.error(
        `<<<< iswindows=${
          typeof window != 'undefined'
        }, DumyTransport  not implemented status=${status}`
      );
    }
  }
}

function _report_static_monitor(tag, max, min, count) {
  DataTransportManager.monitorlogfn?.(tag, `${max},${min},${count}`);
}
