import { ConnectionType } from '../enums/CommonEnums';
import {
  DataTransport,
  DumySocket,
  DumyTransport,
  MsgQueueSocket,
  TRANSPORT_TYPE,
} from './transport';
import { DataTransportManager } from './transportmgr';
export function createMsgSockTransport(transportType) {
  if (process.env.NODE_ENV === 'development') {
    console.error('createMsgSockTransport', transportType);
  }
  let transport = new DataTransport({
    sock: new MsgQueueSocket(),
    type: transportType,
    local: false,
  });
  return transport;
}
/**
 *
 * @param {*} monitorlogfn  (tag,info)=>{}
 * @returns
 */
export function InitDataTransportModule(monitorlogfn) {
  try {
    const IsWorker = typeof DedicatedWorkerGlobalScope != 'undefined';

    if (DataTransport.dataTransportMgr) {
      return;
    }

    let mgr = new DataTransportManager({
      type: IsWorker
        ? DataTransportManager.THREAD_SUB
        : DataTransportManager.THREAD_MAIN,
      remote: IsWorker ? self : null,
    });
    DataTransport.dataTransportMgr = mgr;
    mgr.monitorlogfn = monitorlogfn;
    if (IsWorker) {
      self.addEventListener('message', mgr._onrecvmainthreadlistener.bind(mgr));
    }
  } catch (e) {
    console.error('<<<< InitDataTransportModule', e);
  }
}

/**
 *
 * @param {*} worker
 * @param {*} type
 * @returns
 */
export function initTransportWorker(worker, type) {
  try {
    const IsWorker = typeof DedicatedWorkerGlobalScope != 'undefined';
    if (!DataTransport.dataTransportMgr && !IsWorker) {
      console.error('not InitDataTransportModule');
      return;
    }
    let mgr = DataTransport.dataTransportMgr;
    if (type in mgr.refs) {
      return;
    }
    let listener = mgr._onrecvsubthreadlistener.bind(mgr, worker);
    worker.addEventListener('message', listener);
    let ref = { worker, type, listener };
    mgr.refs[type] = ref;
  } catch (e) {
    console.error('<<<< initTransportWorker', e);
  }
}

export function uninitTransportWorker(type) {
  try {
    typeof DedicatedWorkerGlobalScope != 'undefined';
    if (!DataTransport.dataTransportMgr && !IsWorker) {
      throw new Error('not InitDataTransportModule');
    }

    let mgr = DataTransport.dataTransportMgr;
    if (!(type in mgr.refs)) {
      return;
    }

    let ref = mgr.refs[type];
    let worker = ref.worker;
    DataTransport.dataTransportMgr.removeTransportByRemote(worker);
    delete mgr.refs[type];
    ref.worker.removeEventListener('message', ref.listener);
  } catch (e) {
    console.error('<<<< uninitWorker ', e);
  }
}

export function reInitDataTransportModule() {
  let mgr = DataTransport.dataTransportMgr;
  let keys = Object.keys(mgr.refs);
  keys.forEach((type) => {
    let ref = mgr.refs[type];
    delete mgr.refs[type];
    ref.worker.removeEventListener('message', ref.listener);
  });
  mgr.reinit();
}

//used for channel
export function getTransportByType(type) {
  return DataTransport.dataTransportMgr.getTransportByType(type);
}
export function bindDatchannel(channel) {
  if (!DataTransport.dataTransportMgr) {
    throw new Error('not InitDataTransportModule');
  }
  DataTransport.dataTransportMgr.addDataChannel(channel);
}

export function unBandDatchannel(channel) {
  if (!DataTransport.dataTransportMgr) {
    throw new Error('not InitDataTransportModule');
  }
  DataTransport.dataTransportMgr.removeDataChannel(channel);
}

export function addTransportListChangeListener(listener) {
  DataTransport.dataTransportMgr.addTransportListChangeListener(listener);
}
export function removeTransportListChangeListener(listener) {
  DataTransport.dataTransportMgr.addTransportListChangeListener(listener);
}

export class DataChannelController {
  constructor() {
    this._listener = this._listenerfn.bind(this);
    this.isSupportVideoShare = false;
  }
  addTransportListiner() {
    addTransportListChangeListener(this._listener);
  }
  remoteTransportListener() {
    removeTransportListChangeListener(this._listener);
  }

  _listenerfn(transport, channel, isaadd) {
    if (process.env.NODE_ENV === 'development') {
      console.error(
        `<<<< TransportListChange id=${transport.id},type=${transport.type} channel type=${channel.type} ,isadd=${isaadd}`
      );
    }
    this.connectSession(channel);
  }
  setVideoShareModel(model) {
    this.isSupportVideoShare = model;
  }
  connectSession(channel) {
    const { type } = channel;
    if (channel.transfered || !channel.isReady()) {
      return;
    }

    if (type == ConnectionType.VIDEO) {
      this.connectVideoSession(channel);
    }
    if (type == ConnectionType.AUDIO) {
      this.connectAudioSession(channel);
    }
  }

  disconnectSession(channel) {
    const { type } = channel;
    if (channel.transfered) {
      return;
    }
    if (type == ConnectionType.VIDEO) {
      this.connectVideoSession(channel);
    }
    if (type == ConnectionType.AUDIO) {
      this.connectAudioSession(channel);
    }
  }
  connectVideoSession(channel) {
    let dumyport = new DumyTransport();
    let videoencode =
      getTransportByType(TRANSPORT_TYPE.VIDEO_ENCODE) || dumyport;
    let videodecode =
      getTransportByType(TRANSPORT_TYPE.VIDEO_DECODE) || dumyport;
    let sharedecode =
      getTransportByType(TRANSPORT_TYPE.SHARR_DECODE) || dumyport;
    let open = channel?.isReady();
    let status = open ? DumySocket.OPEN : DumySocket.CLOSED;

    videoencode.setStatus(status);
    videodecode.setStatus(status);
    this.isSupportVideoShare || sharedecode.setStatus(status);

    /**
     * a[0] == 104 video qos data o[1]==0x00 report data
     * a[0] == 103 video session data
     * a[0] == 132 share qos data o[1]==0x00 report data
     * a[0] == 133 share session data
     * a[0] == 20 video sequence recover
     * a[0] == 130 video share sequence recover
     */
    channel.onmessage((ev) => {
      var o = new Uint8Array(ev.data);
      if (
        ((o[0] == 104 || o[0] == 132) && o[1] == 0x00) ||
        o[0] == 20 ||
        o[0] == 130
      ) {
        videoencode.send(o);
      } else {
        if (!this.isSupportVideoShare && (o[0] == 133 || o[0] == 132)) {
          sharedecode.send(o);
          return;
        }
        videodecode.send(o);
      }
    });

    const onmessage = (data) => {
      channel.send(data);
    };
    videoencode.onmessage = onmessage;
    videodecode.onmessage = onmessage;
    sharedecode.onmessage = onmessage;
  }

  connectAudioSession(channel) {
    let dumyport = new DumyTransport();
    let audioencode =
      getTransportByType(TRANSPORT_TYPE.AUDIO_ENCODE) || dumyport;
    let audiodecode =
      getTransportByType(TRANSPORT_TYPE.AUDIO_DECODE) || dumyport;
    let open = channel.isReady();
    let status = open ? DumySocket.OPEN : DumySocket.CLOSED;

    audioencode.setStatus(status);
    audiodecode.setStatus(status);

    /**
     * a[0] == 108 audio qos data o[1]==0x00 report data
     */
    channel.onmessage((ev) => {
      var o = new Uint8Array(ev.data);
      if (o[0] == 108 && o[1] == 0x00) {
        audioencode.send(o);
      } else {
        audiodecode.send(o);
      }
    });

    const onmessage = (data) => {
      channel.send(data);
    };
    audioencode.onmessage = onmessage;
    audiodecode.onmessage = onmessage;
  }

  notifyTransportStatus(transport, status) {
    if (!transport) {
      return;
    }
  }
}
