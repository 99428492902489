import { NetStatistic } from '../../statistic/netStatistic';
import * as Command from '../../worker/common/command';
import { ConnectionType } from '../enums/CommonEnums';
import * as SdkEvent from '../sdkEvent';

export class DumyChannel {
  constructor(type, transfered) {
    this.type = type;
    this.transportlists = [];
    this.transfered = !!transfered;
    this.onmessage = () => {};
  }
  send() {}
  isReady() {
    return false;
  }
}

export class DataChannelWrapper {
  static UNINIT = 0;
  static CONNECTED = 1;
  static DISCONNECT = 2;

  constructor(id, type, datachannel, worker) {
    this.id = id;
    this.type = type;
    this.datachannel = datachannel;
    this._recv_statistic = null;
    this.onmessageFn = null;
    this.disconnectedFn = null;
    this.connectedFn = null;
    this._status = DataChannelWrapper.UNINIT;
    this.target_thread = worker;
    this.transfered = false;
    this._listener = null;
    this.transportlists = [];
    this._send_statistic = null;
    this.report_monitor_func = () => {};
  }
  isReady() {
    return this._status === DataChannelWrapper.CONNECTED;
  }
  send(data) {
    this.datachannel.send(data);
    this._send_statistic.sample(false);
  }
  open() {
    if (this.target_thread) {
      try {
        this.target_thread.postMessage(
          {
            command: Command.OPEN_DATACHANNEL,
            id: this.id,
            type: this.type,
            channel: this.datachannel,
            transportlists: this.transportlists,
          },
          [this.datachannel]
        );

        this.transfered = true;
        this.datachannel = null;
        this._listener = this._mesagelistener.bind(this);
        this.target_thread.addEventListener('message', this._listener);
        return;
      } catch (e) {
        this.target_thread = null;
      }
    }
    this._addEventListener();
  }

  close() {
    let closeFn = this.disconnectedFn;
    if (this.transfered && this.target_thread && this._listener) {
      this.target_thread.removeEventListener('message', this._listener);
      this._listener = null;
      this.target_thread.postMessage({
        command: Command.CLOSE_DATACHANNEL,
        id: this.id,
        type: this.type,
      });
    }
    if (this._status != DataChannelWrapper.DISCONNECT) this._clear();
    this._status = DataChannelWrapper.DISCONNECT;
    closeFn?.();
  }

  onmessage(fn) {
    this.onmessageFn = fn;
  }
  onopen(fn) {
    this.connectedFn = fn;
  }
  onclose(fn) {
    this.disconnectedFn = fn;
  }
  onerror(fn) {
    this.errorFn = fn;
  }

  _addEventListener() {
    this.datachannel.onmessage = this._onmessage.bind(this);
    this.datachannel.onopen = this._onopen.bind(this);
    this.datachannel.onclose = this._onclose.bind(this);
    this.datachannel.onclosing = this._onclose.bind(this);
    this.datachannel.onerror = this._onerror.bind(this);
    if (
      this.datachannel.readyState == 'open' &&
      this._status == DataChannelWrapper.UNINIT
    ) {
      this._onopen();
    }
  }

  _onmessage(ev) {
    this._recv_statistic.sample(false);
    this.onmessageFn(ev);
  }
  _onopen(ev) {
    let temp = this._status;
    this._status = DataChannelWrapper.CONNECTED;
    if (!this.transfered) {
      if (!this._send_statistic) {
        this._send_statistic = new NetStatistic({
          tag: this.type == ConnectionType.VIDEO ? 'VDCS' : 'ADCS',
          report_call: this.report_monitor_func,
        });
      }

      if (!this._recv_statistic) {
        this._recv_statistic = new NetStatistic({
          tag: this.type == ConnectionType.VIDEO ? 'VDCR' : 'ADCR',
          report_call: this.report_monitor_func,
        });
      }
      this._send_statistic.start();
      this._recv_statistic.start();
    }

    if (temp != DataChannelWrapper.CONNECTED) {
      this.connectedFn?.();
    }
  }
  _onerror(ev) {
    this.errorFn?.(ev);
    this._onclose(ev);
  }
  _onclose(ev) {
    let temp = this._status;
    this._status = DataChannelWrapper.DISCONNECT;
    let closeFn = this.disconnectedFn;
    this._clear();
    if (temp != DataChannelWrapper.DISCONNECT) {
      closeFn?.();
    }
  }
  _clear() {
    if (!this.transfered && this.datachannel) {
      this.datachannel.onmessage = null;
      this.datachannel.onopen = null;
      this.datachannel.onclose = null;
      this.datachannel.onclosing = null;
      this.datachannel.onerror = null;
    }
    this.onmessageFn = null;
    this.connectedFn = null;
    this.disconnectedFn = null;
    this.errorFn = null;
    let temp = this.datachannel;
    this.datachannel = null;
    this._send_statistic?.stop();
    this._recv_statistic?.stop();
    temp?.close();
  }
  _mesagelistener(ev) {
    let msg = ev.data;
    if (!msg || msg.id != this.id) {
      return;
    }

    switch (msg.cmd) {
      case SdkEvent.DATACHANNEL_CLOSE:
        {
          this._onclose();
        }
        break;
      case SdkEvent.DATACHANNEL_OPEN:
        {
          this._onopen();
        }
        break;
      case SdkEvent.DATACHANNEL_ERROR:
        {
          this._onerror(msg.ev);
        }
        break;
      case SdkEvent.MONITOR_MESSAGE:
        this.report_monitor_func(msg.tag, msg.data);
        break;
    }
  }
}
