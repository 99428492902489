import WebGPURenderer from './WebGPURenderer';
import * as RenderConst from './RenderConst';

/**
 * RendererProvider is responsible for providing backend renderers.
 *
 * It has 3 main features:
 * 1. evaluate a renderer type
 * 2. create backend renderers
 * 3. acquire backend renderers
 *
 * The created backend renderers are cached inside, it's easily to
 * get a backend renderer with an attached canvas.
 */
class RendererProvider {
  #mWebGPUHwCap = RenderConst.WEBGPU_CAP.AVAILABLE;
  #mRendererType = RenderConst.RENDERER_TYPE.WEBGL;
  #mAdapterInfo = null;
  #mCanvasRendererMap = new Map();

  constructor() {}

  /**
   * Query an option from web that be able to force to select a backend renderer.
   * @returns if true, force to select webgpu backend renderer, if false, webgl renderer
   */
  #queryForceWebGPUFromOp() {
    return true;
  }

  /**
   * Query adapter info from GPU.
   * An adapter info has 4 attributes:
   * - architecture: GPU architecture, like gen-12lp for intel
   * - description: more information about GPU, maybe empty
   * - device: a vendor-specific identifier for the adapter, maybe empty
   * - vendor: the name of the adapter vendor, like intel/apple/nvidia, etc...
   *
   * @returns an extra structure to describe adapter information, only needs architecture and vendor
   */
  #queryDeviceProfile() {
    if (this.#mAdapterInfo) {
      let adapterInfo = {};
      adapterInfo.architecture = this.#mAdapterInfo.architecture;
      adapterInfo.vendor = this.#mAdapterInfo.vendor;
      return adapterInfo;
    }

    return null;
  }

  /**
   * Check whether the WebGPU feature is supported by hardware capability.
   * @returns if true, support, otherwise false
   */
  async #isWebGPUSupportedByHwCap() {
    if (!navigator.gpu) {
      this.#mWebGPUHwCap = RenderConst.WEBGPU_CAP.NOT_SUPPORTED;
      return false;
    }

    const adapter = await navigator.gpu.requestAdapter();
    if (!adapter) {
      this.#mWebGPUHwCap = RenderConst.WEBGPU_CAP.CANNOT_REQ_ADAPTER;
      return false;
    }

    const gpuDevice = await adapter.requestDevice();
    if (!gpuDevice) {
      this.#mWebGPUHwCap = RenderConst.WEBGPU_CAP.CANNOT_REQ_DEVICE;
      return false;
    }

    if (typeof adapter.requestAdapterInfo === 'function') {
      this.#mAdapterInfo = await adapter.requestAdapterInfo();
      if (this.#mAdapterInfo) {
        console.log(
          `adapter info: ${this.#mAdapterInfo.architecture}, ${
            this.#mAdapterInfo.vendor
          }`
        );
      }
    } else {
      if ('info' in adapter) {
        this.#mAdapterInfo = adapter.info;
      }
    }

    this.#mWebGPUHwCap = RenderConst.WEBGPU_CAP.AVAILABLE;
    return true;
  }

  /**
   * Check whether a GPU profile is on the customized whitelist.
   *
   * A customized whitelist for enabling WebGPU feature is defined to filter which devices are allowed, which are not.
   * So the rule is: like the generation higher than gen-8 of an intel gpu, it should be added to the whitelist
   *
   * @param {*} gpuProfile a gpu profile that contains vendor and architecture information
   * @returns if true, the profile is allowed, if false, not allowed
   */
  #isGPUProfileOnWhitelist(gpuProfile) {
    if (!gpuProfile) {
      return false;
    }

    // TODO: here we only check the vendor, as for the generation, still need to figure out a better rule
    const vendor = gpuProfile.vendor;
    const index = RenderConst.GPU_VENDOR_WHITELIST.indexOf(vendor);
    return index !== -1;
  }

  /**
   * Evalute which backend renderer should be used.
   * One of two types will be selected, WebGL or WebGPU.
   *
   * @param {*} webgpuParams parameters of how WebGPU supports
   * @returns a Promise with the evaluated renderer type
   */
  async evaluate(webgpuParams) {
    this.#mRendererType = RenderConst.RENDERER_TYPE.WEBGL;

    const isForceWebGPUFromOp = this.#queryForceWebGPUFromOp();
    if (!isForceWebGPUFromOp) {
      return this.#mRendererType;
    }

    const isPlatformAllowed = webgpuParams.allowedOnTargetPlatforms;
    if (!isPlatformAllowed) {
      return this.#mRendererType;
    }

    const isBrowserAllowed = webgpuParams.allowedOnTargetBrowsers;
    if (!isBrowserAllowed) {
      return this.#mRendererType;
    }

    const isWebGPUSupportedByHwCap = await this.#isWebGPUSupportedByHwCap();
    if (!isWebGPUSupportedByHwCap) {
      return this.#mRendererType;
    }

    const gpuProfile = this.#queryDeviceProfile();
    const onWhitelist = this.#isGPUProfileOnWhitelist(gpuProfile);
    if (!onWhitelist) {
      return this.#mRendererType;
    }

    let canvas = new OffscreenCanvas(1, 1);
    const wgpuContext = canvas.getContext('webgpu');
    if (!wgpuContext) {
      canvas = null;
      return this.#mRendererType;
    }

    canvas = null;
    this.#mRendererType = RenderConst.RENDERER_TYPE.WEBGPU;
    return this.#mRendererType;
  }

  /**
   * Create a new backend renderer.
   *
   * One renderer will attach to a canvas. If multiple canvases on the screen, multiple renderers with same renderer type
   * will be created and attach to the target canvas.
   *
   * @param {*} rendererType the backend renderer type, WebGL or WebGPU
   * @param {*} canvas renderer attaches to
   * @param {*} resMgr a manager provides GPU resources
   * @returns a new backend renderer
   */
  #create(rendererType, canvas, resMgr) {
    return RendererFactory.produce(rendererType, canvas, resMgr);
  }

  /**
   * Acquire a backend renderer from renderer provider.
   * If no available backend renderer or no backend renderer attached to the canvas,
   * a new one will be created.
   *
   * @param {*} canvas renderer attaches to
   * @param {*} resMgr a pool provides GPU resources
   * @param {boolean} [clearCache=false] clear or not the entry of a canvas and renderer
   * @returns a backend renderer
   */
  acquireRenderer(canvas, resMgr, clearCache = false) {
    let renderer = null;

    // for the case of swapping sharing and video content, system will not trigger
    // rAF anymore if we cache multiple canvases in the cached map.
    // we need to clear the cache every time that acquires a renderer so that only
    // one canvas is cached in the map.
    if (clearCache) {
      this.#mCanvasRendererMap.clear();
    }

    if (this.#mCanvasRendererMap.has(canvas)) {
      renderer = this.#mCanvasRendererMap.get(canvas);
      if (renderer) {
        if (canvas) {
          renderer.setCanvas(canvas);
          renderer.initialize(canvas);
        }
        if (resMgr) {
          renderer.setDevice(resMgr.acquireGPUDevice());
        }
      }
    }

    if (renderer == null) {
      renderer = this.#create(this.#mRendererType, canvas, resMgr);
      if (renderer) {
        this.#mCanvasRendererMap.set(canvas, renderer);
      }
    }

    return renderer;
  }

  rendererReinitialize() {
    if (this.#mCanvasRendererMap) {
      for (const [key, val] of this.#mCanvasRendererMap) {
        if (val) {
          val.initialize(key);
        }
      }
    }
  }

  rendererUnconfigureGPUContext() {
    if (this.#mCanvasRendererMap) {
      for (const [key, val] of this.#mCanvasRendererMap) {
        if (val) {
          val.unconfigureGPUContext();
        }
      }
    }
  }

  getRendererType() {
    return this.#mRendererType;
  }

  setRendererType(rendererType) {
    this.#mRendererType = rendererType;
  }

  isWebGPURendererType() {
    return this.#mRendererType === RenderConst.RENDERER_TYPE.WEBGPU;
  }

  isWebGLRendererType() {
    return this.#mRendererType === RenderConst.RENDERER_TYPE.WEBGL;
  }

  isWebGL2RendererType() {
    return this.#mRendererType === RenderConst.RENDERER_TYPE.WEBGL_2;
  }

  /**
   * Cleanup the resources in renderer provider.
   */
  cleanup() {
    for (const [key, val] of this.#mCanvasRendererMap) {
      if (val) {
        val.cleanup();
      }
    }
    this.#mCanvasRendererMap.clear();
  }
}

/**
 * A static factory for producing a backend renderer.
 */
class RendererFactory {
  static produce(rendererType, canvas, resMgr) {
    let renderer = null;
    if (rendererType === RenderConst.RENDERER_TYPE.WEBGPU) {
      renderer = new WebGPURenderer(canvas, resMgr);
    }

    // else if (rendererType === RenderConst.RENDERER_TYPE.WEBGL) {
    //     renderer = new WebGLRenderer(canvas, initMask, contextOptions, webglResources);
    // }

    return renderer;
  }
}

export default RendererProvider;
